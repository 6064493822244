import React, { Component } from "react";
import { connect } from "react-redux";
import EditMinipackage from "../component/EditMinipackage";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAllChapter,
  viewAllLesson,
  viewAllVideo,
  viewAllPdf,
  viewAllquiz,
  setChapter,
  CheckedChapter,
  Checkedlesson,
  Checkedvideo,
  Checkedpdf,
  Checkedquiz,
  UnCheckedchapter,
  UnCheckedlesson,
  UnCheckedpdf,
  UnCheckedquiz,
  UnCheckedvideo,
  SelectChapter,
  SelectChapterLesson,
  StoreVideo,
  NullArray,
  StorePdf,
  NullPdf,
  StoreQuiz,
  NullQuiz,
  NullLesson,
  updateMiniPackage,
  searchchapter,
  searchlesson,
  searchvideo,
  searchpdf,
  searchquiz,
  searchLanguage,
  viewAllPreviousYearQuestion,
  setPriviousYear,
  UnCheckedPriviousYear,
  searchPriviousYear,
  SelectPriviousYear,
  CheckedpriviousYear,
  setMinipackageId,
  view_minipackage_by_id,
  // removeItems,
  viewAllLanguage,
  searchlanguage,



} from "../action";
export class Controller extends Component {
  render() {
    return (
      <EditMinipackage {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // createChapter: (chapter_name) => {
    //   dispatch(createChapter(chapter_name))
    // },
    // updateChapter: (chapter_id,chapter_name) => {
    //   dispatch(updateChapter(chapter_id,chapter_name))
    // },
    // removeItems:(payload,id)=>{
      
    //   dispatch(removeItems(payload,id))
    // },
    setChapter: (payload) => {
      dispatch(setChapter(payload))
    },
    setPriviousYear: (payload) => {
      dispatch(setPriviousYear(payload))
    },
    viewAllChapter: () => {
      dispatch(viewAllChapter())
    },
    viewAllLesson: () => {
      dispatch(viewAllLesson())
    },
    viewAllVideo: () => {
      dispatch(viewAllVideo())
    },
    viewAllPdf: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllPdf(page_number, rowPerPage, searchQuery))
    },
    viewAllquiz: () => {
      dispatch(viewAllquiz())
    },
    CheckedChapter: (index, checked, quantity, all_chapter) => {
      dispatch(CheckedChapter(index, checked, quantity, all_chapter))
    },
    CheckedpriviousYear: (index, checked, quantity, all_privous_year) => {
      dispatch(CheckedpriviousYear(index, checked, quantity, all_privous_year))
    },
    Checkedlesson: (index, checked, quantity, all_lesson) => {
      dispatch(Checkedlesson(index, checked, quantity, all_lesson))
    },
    Checkedvideo: (index, checked, quantity, all_video) => {
      dispatch(Checkedvideo(index, checked, quantity, all_video))
    },
    Checkedpdf: (index, checked, quantity, all_pdf) => {
      dispatch(Checkedpdf(index, checked, quantity, all_pdf))
    },
    Checkedquiz: (index, checked, quantity, all_quiz) => {
      dispatch(Checkedquiz(index, checked, quantity, all_quiz))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    UnCheckedchapter: (all_chapter) => {
      dispatch(UnCheckedchapter(all_chapter))
    },
    UnCheckedPriviousYear: (all_privous_year) => {
      dispatch(UnCheckedPriviousYear(all_privous_year))
    },
    UnCheckedlesson: (all_lesson) => {
      dispatch(UnCheckedlesson(all_lesson))
    },
    UnCheckedpdf: (all_pdf) => {
      dispatch(UnCheckedpdf(all_pdf))
    },
    UnCheckedquiz: (all_quiz) => {
      dispatch(UnCheckedquiz(all_quiz))
    },
    UnCheckedvideo: (all_video) => {
      dispatch(UnCheckedvideo(all_video))
    },
    SelectChapter: (chapter) => {
      dispatch(SelectChapter(chapter))
    },
    SelectPriviousYear: (privious_year) => {
      dispatch(SelectPriviousYear(privious_year))
    },
    SelectChapterLesson: (chapter, count, lesson) => {
      dispatch(SelectChapterLesson(chapter, count, lesson))
    },
    StoreVideo:(lesson,videocount,video,video_count)=>{
      dispatch(StoreVideo(lesson,videocount,video,video_count))
    },
    NullArray:()=>{
      dispatch(NullArray())
    },
    StorePdf:(lesson,pdfcount,pdf,pdf_count)=>{
      dispatch(StorePdf(lesson,pdfcount,pdf,pdf_count))
    },
    NullPdf:()=>{
      dispatch(NullPdf())
    },
    NullQuiz:()=>{
      dispatch(NullQuiz())
    },
    StoreQuiz:(lesson,pdfcount,quiz,quiz_count)=>{
      dispatch(StoreQuiz(lesson,pdfcount,quiz,quiz_count))
    },
    NullLesson:()=>{
      dispatch(NullLesson())
    },
    updateMiniPackage: (minipackage_id,minipackage_name, minipackage_heading,mini_hint,mini_des,mini_content,language_id) => {
      dispatch(updateMiniPackage(minipackage_id,minipackage_name,minipackage_heading, mini_hint,mini_des,mini_content,language_id))
    },
    searchchapter: (chapter,e) => {
      dispatch(searchchapter(chapter,e))
    },
    searchPriviousYear: (all_privous_year,e) => {
      dispatch(searchPriviousYear(all_privous_year,e))
    },
    searchlesson: (lesson,e) => {
      dispatch(searchlesson(lesson,e))
    },
    searchvideo: (video,e) => {
      dispatch(searchvideo(video,e))
    },
    searchpdf: (pdf,e) => {
      dispatch(searchpdf(pdf,e))
    },
    searchquiz: (quiz,e) => {
      dispatch(searchquiz(quiz,e))
    },
    searchLanguage:(pdf, e) => {
      dispatch(searchLanguage(pdf, e));
    },
    
    setMinipackageId:(payload) => {
        dispatch(setMinipackageId(payload));
      },

    viewAllPreviousYearQuestion: () => {
      dispatch(viewAllPreviousYearQuestion())
    },
   
    view_minipackage_by_id: (minipackage_id) => {
      dispatch( view_minipackage_by_id(minipackage_id))
    },
    viewAllLanguage: () => {
      dispatch( viewAllLanguage())
    },
   
    searchlanguage: (all_language, e) => {
      dispatch(searchlanguage(all_language, e))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);