import React, { Component } from "react";
import { connect } from "react-redux";
import Subject from "../component/TestsSubject";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createTSubject,
    updateTSubject,
    deleteTSubject,
    viewAllTSubject
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Subject {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createTSubject: (stage_name) => {
      dispatch(createTSubject(stage_name))
    },
    updateTSubject: (subject_id,stage_name) => {
      dispatch(updateTSubject(subject_id,stage_name))
    },
    deleteTSubject: (subject_id) => {
      dispatch(deleteTSubject(subject_id))
    },
    viewAllTSubject: () => {
      dispatch(viewAllTSubject())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);