import React, { Component } from "react";
import { connect } from "react-redux";
import Tests from "../component/Tests";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createTest,
    updateTest,
    deleteTest,
    viewAllTests
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Tests {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createTest: (test_name) => {
      dispatch(createTest(test_name))
    },
    updateTest: (test_id, test_name) => {
      dispatch(updateTest(test_id, test_name))
    },
    deleteTest: (test_id) => {
      dispatch(deleteTest(test_id))
    },
    viewAllTests: () => {
      dispatch(viewAllTests())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);