import React, { Component } from "react";
import { connect } from "react-redux";
import AddAPackage from "../component/addpackage";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAllCategory,
  viewAllExams,
  viewAllStages,
  viewAllSubject,
  CheckedCategory,
  Checkedexam,
  Checkedstage,
  Checkedsubject,
  viewAllPackageCategory,
  Checkedminipackage,
  UnCheckedExam,
  UnCheckedStage,
  UnCheckedSubject,
  UnCheckedMinipackage,
  SelectCategory,
  SelectExam,
  NullExam,
  SelectStage,
  NullStage,
  SelectSubject,
  NullSubject,
  SelectMini,
  NullMini,
  viewAllMiniPackager,
  Addpackage,
  searchcategory,
  searchexam,
  searchstage,
  searchsubject,
  searchminipackage,
  viewAllTestSeries,
  searchtestseries,
  SelectTestSeries,
  CheckedTestseries,
  viewexamNotification,
  viewAllPreviousYearQuestion,
  searchPriviousYear,
  Checkedexamai,
  Checkedpreviousyear,
  viewAllExamAi,
  searchAllExamAi,
  add_upload_package_video,
  add_upload_package_pdf
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <AddAPackage {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    viewAllCategory: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllCategory(page_number, rowPerPage, searchQuery))
    },
    viewAllExams: () => {
      dispatch(viewAllExams())
    },
    viewexamNotification: (examNotification_id, not_name, not_desc, not_issued_on, application_start_dt, last_dt_to_apply, ld_online_application, ld_online_application_time, ld_offline_chalan, ld_offline_chalan_time, ld_pay_through_chalan, application_correction_dt, application_correction_time, computer_exam_dt, conducting_body, exam_name, post_name, appliction_mode, vacency, salary, official_not_link, official_link, form_link) => {
      dispatchEvent(viewexamNotification(examNotification_id, not_name, not_desc, not_issued_on, application_start_dt, last_dt_to_apply, ld_online_application, ld_online_application_time, ld_offline_chalan, ld_offline_chalan_time, ld_pay_through_chalan, application_correction_dt, application_correction_time, computer_exam_dt, conducting_body, exam_name, post_name, appliction_mode, vacency, salary, official_not_link, official_link, form_link))
    },
    viewAllStages: () => {
      dispatch(viewAllStages())
    },
    viewAllSubject: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllSubject(page_number, rowPerPage, searchQuery))
    },
    viewAllTestSeries: () => {
      dispatch(viewAllTestSeries())
    },
    CheckedCategory: (index, checked, quantity, all_category) => {
      dispatch(CheckedCategory(index, checked, quantity, all_category))
    },
    Checkedexam: (index, checked, quantity, all_exam) => {
      dispatch(Checkedexam(index, checked, quantity, all_exam))
    },
    Checkedstage: (index, checked, quantity, all_stages) => {
      dispatch(Checkedstage(index, checked, quantity, all_stages))
    },

    Checkedpreviousyear: (index, checked, quantity, all_previous_year) => {
      dispatch(Checkedpreviousyear(index, checked, quantity, all_previous_year))
    },

    Checkedsubject: (index, checked, quantity, all_subject) => {
      dispatch(Checkedsubject(index, checked, quantity, all_subject))
    },
    Checkedminipackage: (index, checked, quantity, all_minipackages) => {
      dispatch(Checkedminipackage(index, checked, quantity, all_minipackages))
    },
    viewAllPackageCategory: () => {
      dispatch(viewAllPackageCategory())
    },
    viewAllMiniPackager: () => {
      dispatch(viewAllMiniPackager())
    },
    UnCheckedExam: (all_exam) => {
      dispatch(UnCheckedExam(all_exam))
    },
    UnCheckedStage: (all_stages) => {
      dispatch(UnCheckedStage(all_stages))
    },
    UnCheckedSubject: (all_subject) => {
      dispatch(UnCheckedSubject(all_subject))
    },
    UnCheckedMinipackage: (all_minipackages) => {
      dispatch(UnCheckedMinipackage(all_minipackages))
    },
    SelectCategory: (category) => {
      dispatch(SelectCategory(category))
    },
    SelectExam: (category, count, exam) => {
      dispatch(SelectExam(category, count, exam))
    },
    NullExam: () => {
      dispatch(NullExam())
    },
    SelectStage: (exam, count, stage) => {
      dispatch(SelectStage(exam, count, stage))
    },
    NullStage: () => {
      dispatch(NullStage())
    },
    SelectSubject: (stage, count, sub) => {
      dispatch(SelectSubject(stage, count, sub))
    },
    NullSubject: () => {
      dispatch(NullSubject())
    },
    SelectMini: (sub, count, mini) => {
      dispatch(SelectMini(sub, count, mini))
    },
    NullMini: () => {
      dispatch(NullMini())
    },

    Addpackage: (package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) => {
      dispatch(Addpackage(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
    },
    add_upload_package_video: (package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) => {
      dispatch(add_upload_package_video(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
    },
    add_upload_package_pdf: (package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) => {
      dispatch(add_upload_package_pdf(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
    },
    searchcategory: (category, e) => {
      dispatch(searchcategory(category, e))
    },
    searchexam: (exam, e) => {
      dispatch(searchexam(exam, e))
    },
    searchstage: (stage, e) => {
      dispatch(searchstage(stage, e))
    },
    searchsubject: (subject, e) => {
      dispatch(searchsubject(subject, e))
    },
    searchminipackage: (minipackage, e) => {
      dispatch(searchminipackage(minipackage, e))
    },
    searchtestseries: (all_testseries, e) => {
      dispatch(searchtestseries(all_testseries, e))
    },
    SelectTestSeries: (testseries) => {
      dispatch(SelectTestSeries(testseries))
    },
    CheckedTestseries: (index, checked, quantity, all_testseries) => {
      dispatch(CheckedTestseries(index, checked, quantity, all_testseries))
    },

    viewAllPreviousYearQuestion: () => {
      dispatch(viewAllPreviousYearQuestion())
    },

    searchPriviousYear: (all_previous_year, e) => {
      dispatch(searchPriviousYear(all_previous_year, e))
    },

    Checkedexamai: (index, checked, quantity, all_exam_ai) => {
      dispatch(Checkedexamai(index, checked, quantity, all_exam_ai))
    },
    viewAllExamAi: () => {
      dispatch(viewAllExamAi())
    },

    searchAllExamAi: (all_exam_ai, e) => {
      dispatch(searchAllExamAi(all_exam_ai, e))
    },


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);