import React, { Component } from "react";
import { connect } from "react-redux";
import Notifications from "../component/Notifications";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createNotification,
  updateNotification,
  deleteNotification,
  viewexamNotification,
  setNotificationId,
  setnot_name,
  setnot_desc,
  setnot_issued_on,
  setapplication_start_dt,
  setlast_dt_to_apply,
  setld_online_application_time,
  setld_offline_chalan,
  setld_pay_through_chalan,
  setld_offline_chalan_time,
  setapplication_correction_dt,
  setapplication_correction_time,
  setcomputer_exam_dt,
  setconducting_body,
  setExamId,
  setexam_name,
  setconducting_for,
  setpost_name,
  setappliction_date,
  setappliction_mode,
  setvacency,
  setsalary,
  setofficial_not_link,
  setofficial_link,
  setform_link
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Notifications {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createNotification: (not_name, not_desc, not_issued_on, application_start_dt, last_dt_to_apply, ld_online_application, ld_online_application_time, ld_offline_chalan, ld_offline_chalan_time, ld_pay_through_chalan, application_correction_dt, application_correction_time, computer_exam_dt, conducting_body, exam_name, post_name, appliction_mode, vacency, salary, official_not_link, official_link, form_link) => {
      dispatch(createNotification(not_name, not_desc, not_issued_on, application_start_dt, last_dt_to_apply, ld_online_application, ld_online_application_time, ld_offline_chalan, ld_offline_chalan_time, ld_pay_through_chalan, application_correction_dt, application_correction_time, computer_exam_dt, conducting_body, exam_name, post_name, appliction_mode, vacency, salary, official_not_link, official_link, form_link))
    },
    updateNotification: (examNotification_id, all_notification) => {
      dispatch(updateNotification(examNotification_id, all_notification))
    },
    deleteNotification: (examNotification_id) => {
      dispatch(deleteNotification(examNotification_id))
    },
    viewexamNotification: () => {
      dispatch(viewexamNotification())
    },
    setNotificationId: (payload) => {
      dispatch(setNotificationId(payload))
    },
    setExamId: (payload) => {
      dispatch(setExamId(payload))
    },
    setnot_name: (payload) => {
      dispatch(setnot_name(payload))
    },
    setnot_desc: (payload) => {
      dispatch(setnot_desc(payload))
    },
    setnot_issued_on: (payload) => {
      dispatch(setnot_issued_on(payload))
    },
    setapplication_start_dt: (payload) => {
      dispatch(setapplication_start_dt(payload))
    },
    setlast_dt_to_apply: (payload) => {
      dispatch(setlast_dt_to_apply(payload))
    },
    setld_online_application_time: (payload) => {
      dispatch(setld_online_application_time(payload))
    },
    setld_offline_chalan: (payload) => {
      dispatch(setld_offline_chalan(payload))
    },
    setld_pay_through_chalan: (payload) => {
      dispatch(setld_pay_through_chalan(payload))
    },
    setld_offline_chalan_time: (payload) => {
      dispatch(setld_offline_chalan_time(payload))
    },
    setapplication_correction_dt: (payload) => {
      dispatch(setapplication_correction_dt(payload))
    },
    setapplication_correction_time: (payload) => {
      dispatch(setapplication_correction_time(payload))
    },
    setcomputer_exam_dt: (payload) => {
      dispatch(setcomputer_exam_dt(payload))
    },
    setconducting_body: (payload) => {
      dispatch(setconducting_body(payload))
    },
    setexam_name: (payload) => {
      dispatch(setexam_name(payload))
    },
    setconducting_for: (payload) => {
      dispatch(setconducting_for(payload))
    },
    setpost_name: (payload) => {
      dispatch(setpost_name(payload))
    },
    setappliction_mode: (payload) => {
      dispatch(setappliction_mode(payload))
    },
    setappliction_date: (payload) => {
      dispatch(setappliction_date(payload))
    },
    setvacency: (payload) => {
      dispatch(setvacency(payload))
    },
    setsalary: (payload) => {
      dispatch(setsalary(payload))
    },
    setofficial_not_link: (payload) => {
      dispatch(setofficial_not_link(payload))
    },
    setofficial_link: (payload) => {
      dispatch(setofficial_link(payload))
    },
    setform_link: (payload) => {
      dispatch(setform_link(payload))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);

