import React, { Component } from "react";
import { connect } from "react-redux";
import Banner from "../component/Banner";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
add_upload_Banner,
  deleteBanner,
  viewAllBanner
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Banner {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_upload_Banner: (banner_url, banner_name) => {
      dispatch(add_upload_Banner(banner_url, banner_name))
    },
   
    deleteBanner: (banner_id) => {
      dispatch(deleteBanner(banner_id))
    },
    viewAllBanner: () => {
      dispatch(viewAllBanner())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);