import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Tooltip,
    Typography,
    MenuItem

} from "@mui/material";
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import CheckIcon from '@mui/icons-material/Check';
import NorthIcon from '@mui/icons-material/North';
import ReactQuill from 'react-quill';
import Pagination from '@mui/material/Pagination';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            image: "",
            noti_name: "",
            description: "",
            link: "",
            date: "",
            old_image: "",
            notification_id: "",
            stage: "",
            exam: "",
            category: "",
            notify_details: "",
            youTubelink: "",
            page: 0,
            setPage: 0,
            rowsPerPage: 30,
            searchTerm: ''

        }
        this.handleEditorChange0 = this.handleEditorChange0.bind(this);
        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
    }

    debouncedSearch(value) {
        this.props.viewAllNotification(this.props.page, this.props.rowsPerPage, value);
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };
    handleEditorChange0(html) {
        this.setState({ notify_details: html });
    }

    componentDidMount() {
        this.props.viewAllCategory();
        this.props.viewAllStages()
        // this.props.viewAllNotification();
        this.props.viewAllNotification(this.state.page, this.state.rowsPerPage, "");

    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        const handleChangePage = (event, newPage) => {
            this.props.viewAllNotification(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Notification</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                    image: "",
                                    noti_name: "",
                                    description: "",
                                    link: "",
                                    date: "",
                                    notify_details: "",
                                    youTubelink: "",
                                    stage: "",
                                    category: "",
                                    exam: "",
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Notification
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft: "4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                        <SearchIcon style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className="search-text"
                        placeholder="Search"
                        inputProps={{ "aria-label": "Search" }}
                        value={this.state.searchTerm}
                        onChange={this.handleSearchChange}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Subject Name</TableCell>
                                            <TableCell style={{ paddingLeft: "22%" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.view_all_notification !== undefined && admin.view_all_notification) && admin.view_all_notification.sort((a, b) => a.not_name?.localeCompare(b.not_name)).map((c) => {
                                            console.log("eeeeeee", c);
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{c.not_name}</TableCell>
                                                    <TableCell style={{ paddingLeft: "20%" }} >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.props.viewAllCategoryExam(c.category)
                                                                    this.setState({
                                                                        edit: true,
                                                                        notification_id: c._id,
                                                                        noti_name: c.not_name,
                                                                        not_name: c.not_name,
                                                                        old_image: c.image,
                                                                        description: c.description,
                                                                        link: c.form_link,
                                                                        date: c.date,
                                                                        youTubelink: c.youTubelink,
                                                                        category: c.category,
                                                                        exam: c.exam,
                                                                        stage: c.stage,
                                                                        notify_details: c.notify_details

                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                <img src={Edit} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        noti_name: c.not_name,
                                                                        notification_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                            <Pagination
                                count={parseInt(admin.notification_length / this.state.rowsPerPage) + 1}
                                siblingCount={0}
                                size="small"
                                onChange={handleChangePage}
                            />
                        </div>
                    </Box>



                    <Dialog
                        open={this.state.add}
                    // style={{ overflowY: 'scroll' }}
                    >
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Add Notification</Typography>
                        <Grid style={{ backgroundColor: "#fff", marginLeft: "5%", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "scroll" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    {this.state.image === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "450px", marginTop: "5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                        startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                        Upload
                                        <input hidden type="file" multiple
                                            accept="image/*"
                                            onChange={(e) => { this.setState({ image: e.target.files[0] }) }}

                                        />
                                    </Button>}
                                    {this.state.image !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "450px", marginTop: "5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                        startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                        Successfully uploaded
                                    </Button>}
                                </div>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ noti_name: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification date'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ date: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notifiaction description'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ description: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification Link'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ link: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter You Tube Link'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ youTubelink: e.target.value }) }}
                                    />
                                    <Grid className='mainsecation'
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: '1%' }}
                                    >

                                        <Box className='addmininamess'>
                                            <center>
                                                <Typography style={{ fontWeight: "bold", width: 100 }}>Category
                                                </Typography>
                                            </center>
                                        </Box>
                                        <Grid style={{ marginTop: "2%", width: 400 }}>
                                            <div class="input-group mb-3" style={{ marginRight: "30%", }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Hint'
                                                    select
                                                    rows={1}
                                                    // defaultValue="Default Value"
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "40px", width: "113%" }}
                                                    onChange={(e) => {
                                                        this.setState({ category: e.target.value }, () => {
                                                            this.props.viewAllCategoryExam(e.target.value)
                                                        })

                                                    }}
                                                >{Array.isArray(admin?.all_category) && admin?.all_category?.map((e) => {
                                                    return (
                                                        <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                                    )
                                                })
                                                    }
                                                </TextField>

                                            </div>

                                        </Grid>

                                    </Grid>
                                    {/* ----------------------------------------------------------------------category------------------------------------------ */}



                                    {/* --------------------------------------------------------------------------Exam------------------------------------------- */}
                                    <Grid className='mainsecation'
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "1%", marginTop: '1%' }}
                                    >

                                        <Box className='addmininamess'>
                                            <center>
                                                <Typography style={{ fontWeight: "bold", width: 100 }}>Exam
                                                </Typography>
                                            </center>
                                        </Box>
                                        <Grid style={{ marginTop: "2%", width: 400 }}>
                                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Exam'
                                                    select
                                                    rows={1}
                                                    // defaultValue="Default Value"
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "40px", width: "100%" }}
                                                    onChange={(e) => { this.setState({ exam: e.target.value }) }}
                                                >
                                                    {Array.isArray(admin?.all_exams) && admin?.all_exams?.map((e) => {
                                                        return (
                                                            <MenuItem value={e._id}>{e.exam_name}</MenuItem>
                                                        )
                                                    })
                                                    }
                                                </TextField>

                                            </div>

                                        </Grid>

                                    </Grid>
                                    {/* --------------------------------------------------------------------------Exam------------------------------------------- */}



                                    {/* --------------------------------------------------------------------------Stage------------------------------------------- */}
                                    <Grid className='mainsecation'
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "1%", marginTop: '1%' }}
                                    >

                                        <Box className='addmininamess'>
                                            <center>
                                                <Typography style={{ fontWeight: "bold", width: 100 }}>Stage
                                                </Typography>
                                            </center>
                                        </Box>
                                        <Grid style={{ marginTop: "2%", width: 400 }}>
                                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Hint'
                                                    select
                                                    rows={1}
                                                    // defaultValue="Default Value"
                                                    value={this.state.stage}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "40px", width: "100%" }}
                                                    onChange={(e) => { this.setState({ stage: e.target.value }) }}
                                                >
                                                    {Array.isArray(admin?.all_stages) && admin?.all_stages?.map((e) => {
                                                        return (
                                                            <MenuItem value={e._id}>{e.stage_name}</MenuItem>
                                                        )
                                                    })
                                                    }
                                                </TextField>

                                            </div>

                                        </Grid>



                                    </Grid>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                        Notifiaction Details
                                    </Typography>
                                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>
                                        <div>
                                            <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                                value={this.state.notify_details}
                                                onChange={this.handleEditorChange0}
                                                modules={color.modules}
                                                formats={color.formats}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        disabled={this.state.noti_name === "" && this.state.date === "" && this.state.description === ""}
                                        onClick={() => {
                                            this.props.uploadNotifications(this.state.image, this.state.noti_name, this.state.date, this.state.description, this.state.link, this.state.youTubelink, this.state.category, this.state.exam, this.state.stage, this.state.notify_details);
                                            this.setState({ add: false, notify_details: "", youTubelink: "", stage: "", });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.edit}
                    // style={{ height: "600px" }}
                    >
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Notification</Typography>
                        <Grid style={{ backgroundColor: "#fff", marginLeft: "5%", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "scroll" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    {(this.state.old_image !== "" || this.state.image === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "450px", marginTop: "5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                        startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                        Uploaded
                                        <input hidden type="file" multiple
                                            accept="image/*"
                                            onChange={(e) => { this.setState({ image: e.target.files[0] }) }}

                                        />
                                    </Button>}
                                    {(this.state.old_image !== "" || this.state.image !== "") && <Button variant="outlined" component="label" className='upload' style={{ width: "450px", marginTop: "5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                        startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                        Successfully uploaded
                                    </Button>}
                                </div>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification Name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.noti_name}
                                        onChange={(e) => { this.setState({ noti_name: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification Date'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.date}
                                        onChange={(e) => { this.setState({ date: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification Description'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.description}
                                        onChange={(e) => { this.setState({ description: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Notification Link'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.link}
                                        onChange={(e) => { this.setState({ link: e.target.value }) }}
                                    />

                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter You Tube Link'
                                        value={this.state.youTubelink}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ youTubelink: e.target.value }) }}
                                    />

                                    <Grid className='mainsecation'
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: '1%' }}
                                    >

                                        <Box className='addmininamess'>
                                            <center>
                                                <Typography style={{ fontWeight: "bold", width: 100 }}>Category
                                                </Typography>
                                            </center>
                                        </Box>
                                        <Grid style={{ marginTop: "2%", width: 400 }}>
                                            <div class="input-group mb-3" style={{ marginRight: "30%", }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Hint'
                                                    select
                                                    rows={1}
                                                    // defaultValue="Default Value"
                                                    value={this.state.category}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "40px", width: "113%" }}
                                                    onChange={(e) => {
                                                        this.setState({ category: e.target.value })
                                                        this.props.viewAllCategoryExam(e.target.value)
                                                    }}
                                                >{Array.isArray(admin?.all_category) && admin?.all_category?.map((e) => {
                                                    return (
                                                        <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                                    )
                                                })
                                                    }
                                                </TextField>

                                            </div>

                                        </Grid>

                                    </Grid>
                                    {/* ----------------------------------------------------------------------category------------------------------------------ */}



                                    {/* --------------------------------------------------------------------------Exam------------------------------------------- */}
                                    <Grid className='mainsecation'
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "1%", marginTop: '1%' }}
                                    >

                                        <Box className='addmininamess'>
                                            <center>
                                                <Typography style={{ fontWeight: "bold", width: 100 }}>Exam
                                                </Typography>
                                            </center>
                                        </Box>
                                        <Grid style={{ marginTop: "2%", width: 400 }}>
                                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Exam'
                                                    select
                                                    rows={1}
                                                    // defaultValue="Default Value"
                                                    value={this.state.exam}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "40px", width: "100%" }}
                                                    onChange={(e) => { this.setState({ exam: e.target.value }) }}
                                                >
                                                    {Array.isArray(admin?.all_exams) && admin?.all_exams?.map((e) => {
                                                        return (
                                                            <MenuItem value={e._id}>{e.exam_name}</MenuItem>
                                                        )
                                                    })
                                                    }
                                                </TextField>

                                            </div>

                                        </Grid>

                                    </Grid>
                                    {/* --------------------------------------------------------------------------Exam------------------------------------------- */}



                                    {/* --------------------------------------------------------------------------Stage------------------------------------------- */}
                                    <Grid className='mainsecation'
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "1%", marginTop: '1%' }}
                                    >

                                        <Box className='addmininamess'>
                                            <center>
                                                <Typography style={{ fontWeight: "bold", width: 100 }}>Stage
                                                </Typography>
                                            </center>
                                        </Box>
                                        <Grid style={{ marginTop: "2%", width: 400 }}>
                                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Hint'
                                                    select
                                                    rows={1}
                                                    // defaultValue="Default Value"
                                                    value={this.state.stage}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "40px", width: "100%" }}
                                                    onChange={(e) => { this.setState({ stage: e.target.value }) }}
                                                >
                                                    {Array.isArray(admin?.all_stages) && admin?.all_stages?.map((e) => {
                                                        return (
                                                            <MenuItem value={e._id}>{e.stage_name}</MenuItem>
                                                        )
                                                    })
                                                    }
                                                </TextField>

                                            </div>

                                        </Grid>



                                    </Grid>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                        Notifiaction Details
                                    </Typography>
                                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>
                                        <div>
                                            <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                                value={this.state.notify_details}
                                                onChange={this.handleEditorChange0}
                                                modules={color.modules}
                                                formats={color.formats}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'


                                        onClick={() => {
                                            this.props.uploadUpdateNotification(this.state.notification_id, this.state.old_image, this.state.image, this.state.noti_name, this.state.date, this.state.description, this.state.link, this.state.youTubelink, this.state.category, this.state.exam, this.state.stage, this.state.notify_details);
                                            this.setState({ edit: false, });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.subject_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => { this.props.deleteNotifications(this.state.notification_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}
color.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
    ],
};

color.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
];
