import React, { Component } from "react";
import { connect } from "react-redux";
import Quiz from "../component/addTQuiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createTquiz,
    createTQuestion,
  updateTquiz,
  deleteTquiz,
  viewAllTQuestion,
  DeleteAllQuestionrefersh,
  UploadTQuestion,
  UploadOpatin1,
  UploadOpatin2,
  UploadOpatin3,
  UploadOpatin4,
  UploadOpatin5,
  UploadAnswer,
  resetValue,
  resetValueopation1,
  resetValueopation2,
  resetValueopation3,
  resetValueopation4,
  resetValueopation5,
  resetValueanswer,
  setRes,
  setID,
  subjects_id,
  searchsubject,
  createTQuestionSubmit,
  viewAllTSubject,
  viewAllChapter,
  section_id,
  searchchapter,
  viewAllSection,
  searchSection,
  viewAllLanguage,
  searchlanguage

} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Quiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createTQuestion: (qustion_title, question_img, option1, opation1_img, option1_desc, option2,opation2_img,option2_desc, option3,opation3_img,option3_desc, option4, opation4_img, option4_desc, answer, answer_img,editorHtml,id,subject_id,section_id,question_marks,cut_marks) => {
      dispatch(createTQuestion(qustion_title, question_img, option1, opation1_img, option1_desc, option2,opation2_img,option2_desc, option3,opation3_img,option3_desc, option4, opation4_img, option4_desc, answer, answer_img,editorHtml,id,subject_id,section_id,question_marks,cut_marks))
    },
    createTQuestionSubmit: (qustion_title, question_img, option1, opation1_img, option1_desc, option2,opation2_img,option2_desc, option3,opation3_img,option3_desc, option4, opation4_img, option4_desc, answer, answer_img,editorHtml,id,subject_id,quiz_name,quiz_desc,time,marks,Markss,section,section_id,question_marks,cut_marks,setSection) => {
      dispatch(createTQuestionSubmit(qustion_title, question_img, option1, opation1_img, option1_desc, option2,opation2_img,option2_desc, option3,opation3_img,option3_desc, option4, opation4_img, option4_desc, answer, answer_img,editorHtml,id,subject_id,quiz_name,quiz_desc,time,marks,Markss,section,section_id,question_marks,cut_marks,setSection))
    },
   
    updateTquiz: (quiz_id,quiz_name,set,questions) => {
      dispatch(updateTquiz(quiz_id,quiz_name,set,questions))
    },
    createTquiz: (quiz_name,quiz_desc,Uid,time,marks,section,setSection,total_question,access) => {
      dispatch(createTquiz(quiz_name,quiz_desc,Uid,time,marks,section,setSection,total_question,access))
    },

    deleteTquiz: (quiz_id) => {
      dispatch(deleteTquiz(quiz_id))
    },
    UploadTQuestion: (add_qustion_url) => {
      dispatch(UploadTQuestion(add_qustion_url))
    },
    UploadOpatin1: (option1_url) => {
      dispatch(UploadOpatin1(option1_url))
    },
    UploadAnswer: (answer_url) => {
      dispatch(UploadAnswer(answer_url))
    },
    resetValue: () => {
      dispatch(resetValue())
    },
    setRes: (payload) => {
      dispatch(setRes(payload))
    },
    viewAllTSubject: () => {
      dispatch(viewAllTSubject())
    },
    resetValueopation1: () => {
      dispatch(resetValueopation1())
    },
    resetValueopation2: () => {
      dispatch(resetValueopation2())
    },
    resetValueopation3: () => {
      dispatch(resetValueopation3())
    },
    resetValueopation4: () => {
      dispatch(resetValueopation4())
    },
    resetValueopation5: () => {
      dispatch(resetValueopation5())
    },
    resetValueanswer: () => {
      dispatch(resetValueanswer())
    },
    UploadOpatin2: (option2_url) => {
      dispatch(UploadOpatin2(option2_url))
    },
    UploadOpatin3: (option3_url) => {
      dispatch(UploadOpatin3(option3_url))
    },
    UploadOpatin4: (option4_url) => {
      dispatch(UploadOpatin4(option4_url))
    },
    UploadOpatin5: (option5_url) => {
      dispatch(UploadOpatin5(option5_url))
    },
    viewAllTQuestion: () => {
      dispatch(viewAllTQuestion())
    },
    DeleteAllQuestionrefersh: () => {
      dispatch(DeleteAllQuestionrefersh())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    setID: (payload) => {
      dispatch(setID(payload))
    },
    searchsubject: (all_subject, e) => {
      dispatch(searchsubject(all_subject, e))
    },
    searchchapter: (all_chapter, e) => {
      dispatch(searchchapter(all_chapter, e))
    },
    subjects_id: (payload) => {
      dispatch(subjects_id(payload))

    },
    section_id: (payload) => {
      dispatch(section_id(payload))
    },
    viewAllChapter: () => {
      dispatch(viewAllChapter())
    },
    viewAllSection: () => {
      dispatch(viewAllSection())
    },
    searchSection: (all_section,e) => {
      dispatch(searchSection(all_section,e))
    },
    viewAllLanguage: () => {
      dispatch(viewAllLanguage())
    },
    searchlanguage: (all_language, e) => {
      dispatch(searchlanguage(all_language, e))
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);