import React, { Component } from "react";
import { connect } from "react-redux";
import Pdf from "../component/pdf";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  add_eng_upload_pdf,
  updatePdf,
  deletePdf,
  viewAllPdf,
  edit_eng_upload_pdf,
  searchpdfs
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Pdf {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {

    add_eng_upload_pdf: (pdf_name, pdf_hint, english_url, access,page_number, rowPerPage,) => {
      dispatch(add_eng_upload_pdf(pdf_name, pdf_hint, english_url, access,page_number, rowPerPage,))
    },
    edit_eng_upload_pdf: (pdf_id, pdf_name, pdf_hint, old_english_url, english_url, access,page_number, rowPerPage,) => {
      dispatch(edit_eng_upload_pdf(pdf_id, pdf_name, pdf_hint, old_english_url, english_url, access,page_number, rowPerPage,))
    },
    updatePdf: (pdf_id, pdf_name,page_number, rowPerPage,) => {
      dispatch(updatePdf(pdf_id, pdf_name,page_number, rowPerPage,))
    },
    deletePdf: (pdf_id) => {
      dispatch(deletePdf(pdf_id))
    },
    viewAllPdf: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllPdf(page_number, rowPerPage, searchQuery))
    },
    searchpdfs: (all_pdf, e) => {
      dispatch(searchpdfs(all_pdf, e))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);