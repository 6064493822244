import React, { Component } from "react";
import { connect } from "react-redux";
import Editquiz from "../component/editquiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {

  viewalldatabyQuizId,
  viewAllQuestionByID,
  setQuestionID,
  updatequiz,
  updateQuestion,
  EditUploadQuestion,
  EditUploadoption1,
  EditUploadoption2,
  EditUploadoption3,
  EditUploadoption4,
  EditUploadANSimg,
  resetEditedquestionimage,
  resetEditedValueopation1,
  resetEditedValueopation2,
  resetEditedValueopation3,
  resetEditedValueopation4,
  resetEditedValueopation5,
  resetEditedValueanswer,
  setID,
  addUpdateQuizQuestion,
  delete_question_update_quiz,
  viewallSubjectQuizId,
  viewAllSubject,
  updateQuizSubject,
  subject_id,
  EditUploadoption5
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Editquiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {

    viewalldatabyQuizId: (id) => {
      dispatch(viewalldatabyQuizId(id))
    },
    viewallSubjectQuizId: (id) => {
      dispatch(viewallSubjectQuizId(id))
    },
    delete_question_update_quiz: (id, Qid) => {
      dispatch(delete_question_update_quiz(id, Qid))
    },
    viewAllQuestionByID: (id) => {
      dispatch(viewAllQuestionByID(id))
    },
    setQuestionID: (id) => {
      dispatch(setQuestionID(id))
    },
    updatequiz: (quiz_id, quiz_name, quiz_desc, quiz_time, quiz_marks, quiz_cut_marks, subject) => {
      dispatch(updatequiz(quiz_id, quiz_name, quiz_desc, quiz_time, quiz_marks, quiz_cut_marks, subject))
    },
    viewAllSubject: () => {
      dispatch(viewAllSubject())
    },
    setID: (payload) => {
      dispatch(setID(payload))
    },
    updateQuestion: (question_id, question_text,
      question_desc,
      question_img,
      option1,
      option1_img,
      option1_desc,
      option2,
      option2_img,
      option2_desc,
      option3,
      option3_img,
      option3_desc,
      option4,
      option4_img,
      option4_desc,
      option5,
      option5_img,
      option5_desc,
      correct_ans,
      correct_ans_img,
      correct_ans_desc, id) => {
      dispatch(updateQuestion(question_id, question_text,
        question_desc,
        question_img,
        option1,
        option1_img,
        option1_desc,
        option2,
        option2_img,
        option2_desc,
        option3,
        option3_img,
        option3_desc,
        option4,
        option4_img,
        option4_desc,
        option5,
        option5_img,
        option5_desc,
        correct_ans,
        correct_ans_img,
        correct_ans_desc, id))
    },
    addUpdateQuizQuestion: (question_text,
      question_desc,
      question_img,
      option1,
      option1_img,
      option1_desc,
      option2,
      option2_img,
      option2_desc,
      option3,
      option3_img,
      option3_desc,
      option4,
      option4_img,
      option4_desc,
      option5,
      option5_img,
      option5_desc,
      correct_ans,
      correct_ans_img,
      correct_ans_desc, id, subject_id) => {
      dispatch(addUpdateQuizQuestion(question_text,
        question_desc,
        question_img,
        option1,
        option1_img,
        option1_desc,
        option2,
        option2_img,
        option2_desc,
        option3,
        option3_img,
        option3_desc,
        option4,
        option4_img,
        option4_desc,
        option5,
        option5_img,
        option5_desc,
        correct_ans,
        correct_ans_img,
        correct_ans_desc, id, subject_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    EditUploadQuestion: (question_url) => {
      dispatch(EditUploadQuestion(question_url));
    },
    EditUploadoption1: (new_option1_url) => {
      dispatch(EditUploadoption1(new_option1_url));
    },
    EditUploadoption2: (new_option2_url) => {
      dispatch(EditUploadoption2(new_option2_url));
    },
    EditUploadoption3: (new_option3_url) => {
      dispatch(EditUploadoption3(new_option3_url));
    },
    EditUploadoption4: (new_option4_url) => {
      dispatch(EditUploadoption4(new_option4_url));
    },
    EditUploadoption5: (new_option5_url) => {
      dispatch(EditUploadoption5(new_option5_url));
    },
    EditUploadANSimg: (new_ans_url) => {
      dispatch(EditUploadANSimg(new_ans_url));
    },
    resetEditedquestionimage: () => {
      dispatch(resetEditedquestionimage())
    },
    resetEditedValueopation1: () => {
      dispatch(resetEditedValueopation1())
    },
    resetEditedValueopation2: () => {
      dispatch(resetEditedValueopation2())
    },
    resetEditedValueopation3: () => {
      dispatch(resetEditedValueopation3())
    },
    resetEditedValueopation4: () => {
      dispatch(resetEditedValueopation4())
    },
    resetEditedValueopation5: () => {
      dispatch(resetEditedValueopation5())
    },
    resetEditedValueanswer: () => {
      dispatch(resetEditedValueanswer())
    },
    updateQuizSubject: (quiz_id, subject) => {
      dispatch(updateQuizSubject(quiz_id, subject))
    },
    subject_id: (payload) => {
      dispatch(subject_id(payload))
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);