/* eslint-disable no-duplicate-case */
import {
  SET_RESSET,
  SET_CATEGORY,
  SET_EXAM,
  SET_EXAMS,
  SET_STUDENT,
  SET_STAGE,
  SET_SUBJECT,
  SET_SECTION,
  SET_CHAPTER,
  SET_LESSON,
  SET_VIDEO,
  SET_PACKAGECATEGORY,
  SET_PDF,
  SET_PRIVIOUS_YEAR,
  SET_QUIZ,
  SET_BANNER,
  SET_QUESTION,
  SET_MINIPACKAGE,
  SET_SELECT_CHAPTER,
  SET_QUESTION_IMAGE,
  SET_OPATION1_IMAGE,
  SET_OPATION2_IMAGE,
  SET_OPATION3_IMAGE,
  SET_OPATION4_IMAGE,
  SET_OPATION5_IMAGE,
  SET_ANSWER_IMAGE,
  SET_QUESTION_IMAGE_REMOVE,
  SET_OPATION1_IMAGE_REMOVE,
  SET_OPATION2_IMAGE_REMOVE,
  SET_OPATION3_IMAGE_REMOVE,
  SET_OPATION4_IMAGE_REMOVE,
  SET_OPATION5_IMAGE_REMOVE,
  SET_ANSWER_IMAGE_REMOVE,
  SET_QUIZ_ID,
  SET_TQUIZ_ID,
  SET_REVIEW_BY_ID,
  SET_REVIEW_BY_TQUIZID,
  SET_REVIEW_QUASTION_ID,
  SET_QUESTION_ID,
  SET_QUESTION_BY_ID,
  SET_CHAPTER_SELECT,
  SET_CHAPTER_SELECT_LESSON,
  SET_VIDEO_SELECT,
  SET_PDF_SELECT,
  SET_QUIZ_SELECT,
  SET_CATEGORY_SELECT,
  SET_EXAM_SELECT,
  SET_STAGE_SELECT,
  SET_SUBJECT_SELECT,
  NOTIFICATION,
  SET_MINI_SELECT,
  SET_TEST_SELECT,
  SET_TEST_TYPES_SELECT,
  SET_QUIZZ_SELECT,
  SET_PACKAGE,
  SET_NEW_QUESTION_IMAGE,
  SET_NEW_OPTION1_IMAGE,
  SET_NEW_OPTION2_IMAGE,
  SET_NEW_OPTION3_IMAGE,
  SET_NEW_OPTION4_IMAGE,
  SET_NEW_OPTION5_IMAGE,
  SET_NEW_ANSWER_IMAGE,
  SET_EDITED_QUESTION_IMAGE_REMOVE,
  SET_EDITED_OPATION1_IMAGE_REMOVE,
  SET_EDITED_OPATION2_IMAGE_REMOVE,
  SET_EDITED_OPATION3_IMAGE_REMOVE,
  SET_EDITED_OPATION4_IMAGE_REMOVE,
  SET_EDITED_OPATION5_IMAGE_REMOVE,
  SET_EDITED_ANSWER_IMAGE_REMOVE,
  SET_CHAPTER_FILTERED_VALUE,
  SET_LESSON_FILTERED_VALUE,
  SET_VIDEO_FILTERED_VALUE,
  SET_PDF_FILTERED_VALUE,
  SET_QUIZ_FILTERED_VALUE,
  SET_CATEGORY_FILTERED_VALUE,
  SET_EXAM_FILTERED_VALUE,
  SET_STAGE_FILTERED_VALUE,
  SET_SUBJECT_FILTERED_VALUE,
  SET_MINIPACKAGE_FILTERED_VALUE,
  SET_TESTSERIES,
  SEARCH_LANGUAGE,
  SET_PRIVIOUS_YEAR_FILTERED_VALUE,
  SET_PRIVIOUS_YEAR_SELECT,
  SET_SELECT_PRIVIOUS_YEAR,
  SET_TESTS,
  SET_TEST_TYPES,
  SET_EXAMM_SELECT,
  SET_TESTS_FILTERED_VALUE,
  SET_TESTTYPE_FILTERED_VALUE,
  SET_TESTSERIES_FILTERED_VALUE,
  SET_TESTSERIES_SELECT,

  SET_TRENDS,
  SET_EXAM_AI,
  SET_TREND_SELECT,
  SET_TREND_FILTERED_VALUE,
  SEARCH_PREVIOUS_YEAR,
  SET_EXAMAI_SELECT,
  SET_ALL_EXAM_AI,
  SET_NOTIFICATION_ID,

  SET_EXAM_ID,
  SET_NOT_NAME,
  SET_NOT_DESC,
  SET_NOT_ISSUED_ON,
  SET_APPLICATION_START_DT,
  SET_LAST_DT_TO_APPLY,
  SET_ONLINE_APP_TIME,
  SET_OFFLINE_CHALAN,
  SET_PAY_THROUGH_CHALAN,
  SET_OFFLINE_CHALAN_TIME,
  SET_APP_CORRECT_DT,
  SET_APP_CORRECT_TIME,
  SET_COM_EXAM_DT,
  SET_CON_BODY,
  SET_EXAM_NAME,
  SET_CON_FOR,
  SET_POST_NAME,
  SET_APP_MODE,
  SET_APP_DATE,
  SET_VACANCY,
  SET_SALARY,
  SET_OFFICIAL_NOT_LINK,
  SET_OFFICIAL_LINK,
  SET_FORM_LINK,
  VIEW_ALL_COUPON,
  SET_MINIPACKAGE_ID,
  REMOVE_ITEM,
  SET_MINIPACKAGE_BY_ID,
  SET_TESTSERIES_ID,
  SET_PREVIOUSYEAR_ID,
  SET_EXAMAI_ID,
  SET_TESTSERIES_IDS,
  ADD_QUIZ_QUESTION,
  SEARCH_PREVIOUS,
  SEARCH_PACKAGES,
  SEARCH_MINIPACKAGE,
  SEATCH_TESTSERIES,
  SEATCH_EXAMAI,
  SET_PACKAGE_BY_ID,
  SEARCH_QUIZES,
  TEST_SEARCH_QUIZES,
  SET_UUID,
  SEARCH_PDFS,
  SET_QUIZ_R_ID,
  SEARCH_VIDEOES,
  SET_SUBJECT_ID,
  SET_SUBJECTS_ID,
  SET_ALL_LESSON,

  VIEW_SUBJECT_BY_QUIZ,
  VIEW_ALL_LANGUAGE,
  SET_LANGUAGE_FILTERED_VALUE,
  VIEW_ALL_NOTIFICATION,
  VIEW_ALL_TESTPASS,
  SET_SECTION_ID,
  SET_SECTION_FILTERED_VALUE,
  SET_SUBJECT_IDS,
  SET_LANGUAGE_ID,



} from "./constant";
const initial_state = {
  all_category: [],
  set_section_id: "",
  set_subject_id: "",
  set_language_id: "",

  all_testseries: [],
  search_testSeries: [],
  all_exam: [],
  all_student: [],
  all_stages: [],
  all_subject: [],
  privous_year_filtered: [],
  all_section: [],
  all_chapter: [],
  all_lesson: [],
  all_video: [],
  search_videoes: [],
  all_packagecategory: [],
  all_pdf: [],
  search_language: [],
  all_privous_year: [],
  all_quiz: [],
  all_banner: [],
  all_question: [],
  all_minipackages: [],
  search_minipackage: [],
  select_chapter: [],
  select_privious_year: [],
  all_quiz_by_id: [],
  all_quiz_by_R_id: null,
  all_quastion_by_id: [],
  question_img: '',
  opation1_img: '',
  opation2_img: '',
  opation3_img: '',
  opation4_img: '',
  opation5_img: '',
  answer_img: '',
  refres_status: true,
  quiz_id: '',
  tquiz_id: "",
  question_id: '',
  all_questions_by_id: [],
  section_filter: [],
  all_previous_year: [],
  search_previous: [],
  chapter_selected: [],
  privous_selected: [],
  chapter_selected_lesson: [],
  video_select: [],
  pdf_select: [],
  quiz_select: [],
  category_selct: [],
  exam_select: [],
  stage_select: [],
  subject_select: [],
  all_notification: [],
  mini_select: [],
  test_select: [],
  quizz_select: [],
  test_type_select: [],
  all_packages: [],
  search_packages: [],
  new_question_image: '',
  new_opation1_img: '',
  new_opation2_img: '',
  new_opation3_img: '',
  new_opation4_img: '',
  new_opation5_img: '',
  new_answer_img: '',
  chapter_filtered: [],
  lesson_filtered: [],
  video_filtered: [],
  pdf_filtered: [],
  quiz_filtered: [],
  category_filtered: [],
  exam_filtered: [],
  stage_filtered: [],
  subject_filtered: [],
  minipackage_filtered: [],
  tests: [],
  test_types: [],
  examm_select: [],
  tests_filtered: [],
  testtype_filtered: [],
  testseries_filtered: [],
  testseries_select: [],
  examNotifications: [],
  all_exam_ai: [],
  search_examAi: [],
  trend_select: [],
  trend_filtered: [],
  search_previous_year: [],
  examai_select: [],
  search_examai: [],
  notify_id: '',

  form_link: '',
  official_link: '',
  official_not_link: '',
  salary: '',
  vacancy: '',
  app_mode: '',
  app_date: "",
  post_name: '',
  con_for: '',
  exam_name: '',
  con_body: '',
  con_exam_dt: '',
  app_correct_time: '',
  app_correct_dt: '',
  official_chalan_time: '',
  pay_through_chalan: '',
  officail_chalan: '',
  online_app_time: '',
  last_dt_to_apply: '',
  app_start_dt: '',
  not_issued_on: '',
  not_desc: '',
  not_name: '',
  all_coupon: [],
  email_id: '',
  minipackage_id: [],
  package_id: [],
  textseries_id: [],
  view_minipackage_by_id: [],
  previousYears: [],
  examAiDetails: [],
  testSeriesIds: [],
  uuid: null,
  search_pdfs: [],
  // subject_id: "",
  // subjects_id: "",
  add_mini: {},
  every_lesson: {},
  mini_content: {},
  search_pdfs: [],
  subject_id: [],
  subjects_id: [],
  view_subject_by_quiz: "",
  all_language: [],
  view_all_notification: [],
  all_tquiz_by_tid: [],
  pdf_length: 0,
  subject_length: 0,
  notification_length: 0,
  category_length:0,

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_UUID:


      return {
        ...state,
        uuid: action.payload,
      };
    case SET_CATEGORY:
      return state = { ...state, all_category: action.payload.category, category_filtered: action.payload.category,category_length:action.payload.count };
    case SET_REVIEW_BY_ID:
      return state = { ...state, all_quiz_by_id: action.payload };

    case SET_REVIEW_BY_TQUIZID:
      return state = { ...state, all_tquiz_by_tid: action.payload };

    case SET_REVIEW_QUASTION_ID:
      // 
      return state = { ...state, all_quastion_by_id: action.payload };

    case SET_QUIZ_ID:
      return state = { ...state, quiz_id: action.payload };

    case SET_TQUIZ_ID:

      return state = { ...state, tquiz_id: action.payload };

    case SET_QUIZ_R_ID:
      return state = { ...state, all_quiz_by_R_id: action.payload };

    case SET_QUESTION_IMAGE:
      return state = { ...state, question_img: action.payload };
    case SET_ANSWER_IMAGE:
      return state = { ...state, answer_img: action.payload };
    case SET_OPATION1_IMAGE:
      return state = { ...state, opation1_img: action.payload };
    case SET_OPATION2_IMAGE:
      return state = { ...state, opation2_img: action.payload };
    case SET_OPATION3_IMAGE:
      return state = { ...state, opation3_img: action.payload };
    case SET_OPATION4_IMAGE:
      return state = { ...state, opation4_img: action.payload };
    case SET_OPATION5_IMAGE:
      return state = { ...state, opation5_img: action.payload };
    case SET_SELECT_CHAPTER:
      return state = { ...state, select_chapter: action.payload };
    case SET_SELECT_PRIVIOUS_YEAR:
      return state = { ...state, select_privious_year: action.payload };
    case SET_MINIPACKAGE:
      return state = { ...state, all_minipackages: action.payload.minipackage, minipackage_filtered: action.payload.minipackage, search_minipackage: action.payload.minipackage };


    case SET_TESTSERIES:
      // 
      return state = { ...state, all_testseries: action.payload.testseries, testseries_filtered: action.payload.testseries, search_testSeries: action.payload.testseries };

    case SET_TESTSERIES_ID:
      // 
      return state = { ...state, textseries_id: action.payload };
    case SET_PACKAGE_BY_ID:
      // 
      return state = { ...state, package_id: action.payload };




    case SET_QUESTION:
      return state = { ...state, all_question: action.payload };



    // case ADD_QUIZ_QUESTION:
    //   
    //   return {
    //     ...state,
    //     all_quiz_by_id: {
    //       ...state.all_quiz_by_id,
    //       questions: [...state.all_quiz_by_id.questions, action.payload],
    //     },
    //   };




    case SET_EXAM:
      return state = { ...state, all_exam: action.payload.exam, exam_filtered: action.payload.exam };

    case SET_EXAMS:
      console.log(action.payload.exam);
      return state = { ...state, all_exams: action.payload.exam };

    case SET_STUDENT:
      return state = { ...state, all_student: action.payload.exam };

    case SET_STAGE:
      return state = { ...state, all_stages: action.payload.stage, stage_filtered: action.payload.stage };
    case SET_SUBJECT:
      return state = { ...state, all_subject: action.payload.subject, subject_filtered: action.payload.subject, subject_length: action.payload.count };
    case SET_SECTION:
      return state = { ...state, all_section: action.payload.section, section_filter: action.payload.section };
    case SET_SECTION_FILTERED_VALUE:
      return state = { ...state, section_filter: action.payload };
    case SET_CHAPTER:
      return state = { ...state, all_chapter: action.payload.chapter, chapter_filtered: action.payload.chapter };
    case SET_LESSON:
      return state = { ...state, all_lesson: action.payload.lesson, lesson_filtered: action.payload.lesson };
    case SET_ALL_LESSON:
      return state = { ...state, every_lesson: action.payload, };
    case SET_VIDEO:
      return state = { ...state, all_video: action.payload.video, video_filtered: action.payload.video, search_videoes: action.payload.video };
    case SET_PACKAGECATEGORY:
      return state = { ...state, all_packagecategory: action.payload };
    case SET_PDF:
      return state = { ...state, all_pdf: action.payload.pdf, pdf_filtered: action.payload.pdf, search_language: action.payload.data, search_pdfs: action.payload.pdf, pdf_length: action.payload.count, };
    case SET_QUIZ:
      // 
      return state = { ...state, all_quiz: action.payload.quiz, quiz_filtered: action.payload.quiz, search_previous: action.payload.quiz, search_test: action.payload.quiz };
    case SET_BANNER:
      return state = { ...state, all_banner: action.payload };
    case SET_QUESTION_ID:
      return state = { ...state, question_id: action.payload };
    case SET_QUESTION_BY_ID:
      return state = { ...state, all_questions_by_id: action.payload };

    case SET_PRIVIOUS_YEAR:
      // 
      return state = { ...state, all_previous_year: action.payload.previousYear, privous_year_filtered: action.payload.previousYear, search_previous: action.payload.previousYear };

    case SEARCH_LANGUAGE:
      return {
        ...state,
        search_language: action.payload
      };
    case SET_QUESTION_IMAGE_REMOVE:
      return {
        ...state,
        question_img: "", // Reset the value to null
      };
    case SET_OPATION1_IMAGE_REMOVE:
      return {
        ...state,
        opation1_img: "", // Reset the value to null
      };
    case SET_OPATION2_IMAGE_REMOVE:
      return {
        ...state,
        opation2_img: "", // Reset the value to null
      };
    case SET_OPATION3_IMAGE_REMOVE:
      return {
        ...state,
        opation3_img: "", // Reset the value to null
      };
    case SET_OPATION4_IMAGE_REMOVE:
      return {
        ...state,
        opation4_img: "", // Reset the value to null
      };
    case SET_OPATION5_IMAGE_REMOVE:
      return {
        ...state,
        opation5_img: "", // Reset the value to null
      };
    case SET_ANSWER_IMAGE_REMOVE:
      return {
        ...state,
        answer_img: "", // Reset the value to null
      };
    case SET_RESSET:
      return {
        ...state,
        refres_status: false, // Reset the value to null
      };

    case REMOVE_ITEM:

      return state = { ...state, chapter_selected: action.payload };
    case SET_CHAPTER_SELECT:
      return state = { ...state, chapter_selected: action.payload };

    case SET_PRIVIOUS_YEAR_SELECT:
      return state = { ...state, privous_selected: action.payload };
    case SET_CHAPTER_SELECT_LESSON:
      return state = { ...state, chapter_selected_lesson: action.payload };
    case SET_VIDEO_SELECT:
      return state = { ...state, video_select: action.payload };
    case SET_PDF_SELECT:
      return state = { ...state, pdf_select: action.payload };
    case SET_QUIZ_SELECT:
      return state = { ...state, quiz_select: action.payload };
    case SET_CATEGORY_SELECT:
      return state = { ...state, category_selct: action.payload };
    case SET_TESTSERIES_SELECT:
      // 
      return state = { ...state, testseries_select: action.payload };

    case SET_EXAMAI_SELECT:
      // 
      return state = { ...state, examai_select: action.payload };

    case SET_EXAM_SELECT:
      return state = { ...state, exam_select: action.payload };
    case SET_EXAMM_SELECT:
      return state = { ...state, examm_select: action.payload };
    case SET_TREND_SELECT:
      return state = { ...state, trend_select: action.payload };
    case SET_STAGE_SELECT:
      return state = { ...state, stage_select: action.payload };
    case SET_SUBJECT_SELECT:
      return state = { ...state, subject_select: action.payload };
    case NOTIFICATION:
      return state = { ...state, all_notification: action.payload };
    case SET_MINI_SELECT:
      return state = { ...state, mini_select: action.payload };
    case SET_TEST_SELECT:
      return state = { ...state, test_select: action.payload };
    case SET_TEST_TYPES_SELECT:
      return state = { ...state, test_type_select: action.payload };
    case SET_QUIZZ_SELECT:
      return state = { ...state, quizz_select: action.payload };
    case SET_PACKAGE:
      return state = { ...state, all_packages: action.payload, search_packages: action.payload };
    case SET_NEW_QUESTION_IMAGE:
      return state = { ...state, new_question_image: action.payload };
    case SET_NEW_OPTION1_IMAGE:
      return state = { ...state, new_opation1_img: action.payload };
    case SET_NEW_OPTION2_IMAGE:
      return state = { ...state, new_opation2_img: action.payload };
    case SET_NEW_OPTION3_IMAGE:
      return state = { ...state, new_opation3_img: action.payload };
    case SET_NEW_OPTION4_IMAGE:
      return state = { ...state, new_opation4_img: action.payload };
    case SET_NEW_OPTION5_IMAGE:
      return state = { ...state, new_opation5_img: action.payload };
    case SET_NEW_ANSWER_IMAGE:
      return state = { ...state, new_answer_img: action.payload };
    case SET_EDITED_QUESTION_IMAGE_REMOVE:
      return {
        ...state,
        new_question_image: "", // Reset the value to null
      };
    case SET_EDITED_OPATION1_IMAGE_REMOVE:
      return {
        ...state,
        new_opation1_img: "", // Reset the value to null
      };
    case SET_EDITED_OPATION2_IMAGE_REMOVE:
      return {
        ...state,
        new_opation2_img: "", // Reset the value to null
      };
    case SET_EDITED_OPATION3_IMAGE_REMOVE:
      return {
        ...state,
        new_opation3_img: "", // Reset the value to null
      };
    case SET_EDITED_OPATION4_IMAGE_REMOVE:
      return {
        ...state,
        new_opation4_img: "", // Reset the value to null
      };
    case SET_EDITED_OPATION5_IMAGE_REMOVE:
      return {
        ...state,
        new_opation5_img: "", // Reset the value to null
      };

    case SET_EDITED_ANSWER_IMAGE_REMOVE:
      return {
        ...state,
        new_answer_img: "", // Reset the value to null
      };
    case SET_RESSET:
      return {
        ...state,
        refres_status: false, // Reset the value to null
      };
    case SET_CHAPTER_FILTERED_VALUE:
      return state = { ...state, chapter_filtered: action.payload };

    case SET_PRIVIOUS_YEAR_FILTERED_VALUE:
      return state = { ...state, privous_year_filtered: action.payload };

    case SET_ALL_EXAM_AI:
      return state = { ...state, search_examai: action.payload };

    case SET_LESSON_FILTERED_VALUE:
      return state = { ...state, lesson_filtered: action.payload };
    case SET_VIDEO_FILTERED_VALUE:
      return state = { ...state, video_filtered: action.payload };
    case SET_PDF_FILTERED_VALUE:
      return state = { ...state, pdf_filtered: action.payload };
    case SET_QUIZ_FILTERED_VALUE:
      return state = { ...state, quiz_filtered: action.payload };
    case SET_CATEGORY_FILTERED_VALUE:
      return state = { ...state, category_filtered: action.payload };
    case SET_EXAM_FILTERED_VALUE:
      return state = { ...state, exam_filtered: action.payload };
    case SET_STAGE_FILTERED_VALUE:
      return state = { ...state, stage_filtered: action.payload };
    case SET_SUBJECT_FILTERED_VALUE:
      return state = { ...state, subject_filtered: action.payload };
    case SET_MINIPACKAGE_FILTERED_VALUE:
      return state = { ...state, minipackage_filtered: action.payload };
    case SET_TESTSERIES_FILTERED_VALUE:
      return state = { ...state, testseries_filtered: action.payload };
    case SET_TESTS:
      // 
      return state = { ...state, tests: action.payload.tests, tests_filtered: action.payload.tests };
    case SET_TESTS_FILTERED_VALUE:
      // 
      return state = { ...state, tests_filtered: action.payload };
    case SET_TEST_TYPES:
      // 
      return state = { ...state, test_types: action.payload.testtypes, testtype_filtered: action.payload.testtypes };
    case SET_TESTTYPE_FILTERED_VALUE:
      // 
      return state = { ...state, testtype_filtered: action.payload };
    //----------------------------------Trends------------------------------------------------------//
    case SET_TRENDS:
      // 
      return state = { ...state, view_all_trends: action.payload.trends, trend_filtered: action.payload.trends };

    case SET_TREND_FILTERED_VALUE:
      return state = { ...state, trend_filtered: action.payload };
    //-----------------------Exam Ai---------------------------------------------//

    // case SET_EXAM_AI:
    //   return state = { ...state, all_exam_ai: action.payload };

    case SET_EXAM_AI:
      return state = { ...state, all_exam_ai: action.payload.examai, search_examai: action.payload.examai, search_examAi: action.payload.examai };

    case SEARCH_PREVIOUS_YEAR:
      return state = { ...state, search_previous_year: action.payload };
    case SET_EXAM_ID:
      return state = { ...state, email_id: action.payload };
    case SET_NOTIFICATION_ID:
      return state = { ...state, notify_id: action.payload };
    case SET_NOT_NAME:
      return state = { ...state, not_name: action.payload };
    case SET_NOT_DESC:
      return state = { ...state, not_desc: action.payload };
    case SET_NOT_ISSUED_ON:
      return state = { ...state, not_issued_on: action.payload };
    case SET_APPLICATION_START_DT:
      return state = { ...state, app_start_dt: action.payload };
    case SET_LAST_DT_TO_APPLY:
      return state = { ...state, last_dt_to_apply: action.payload };
    case SET_ONLINE_APP_TIME:
      return state = { ...state, online_app_time: action.payload };
    case SET_OFFLINE_CHALAN:
      return state = { ...state, officail_chalan: action.payload };
    case SET_PAY_THROUGH_CHALAN:
      return state = { ...state, pay_through_chalan: action.payload };
    case SET_OFFLINE_CHALAN_TIME:
      return state = { ...state, official_chalan_time: action.payload };
    case SET_APP_CORRECT_DT:
      return state = { ...state, app_correct_dt: action.payload };
    case SET_APP_CORRECT_TIME:
      return state = { ...state, app_correct_time: action.payload };
    case SET_COM_EXAM_DT:
      return state = { ...state, con_exam_dt: action.payload };
    case SET_CON_BODY:
      return state = { ...state, con_body: action.payload };
    case SET_EXAM_NAME:
      return state = { ...state, exam_name: action.payload };
    case SET_CON_FOR:
      return state = { ...state, con_for: action.payload };
    case SET_POST_NAME:
      return state = { ...state, post_name: action.payload };
    case SET_APP_MODE:
      return state = { ...state, app_mode: action.payload };
    case SET_APP_DATE:
      return state = { ...state, app_date: action.payload };
    case SET_VACANCY:
      return state = { ...state, vacancy: action.payload };
    case SET_SALARY:
      return state = { ...state, salary: action.payload };
    case SET_OFFICIAL_NOT_LINK:
      return state = { ...state, official_not_link: action.payload };
    case SET_OFFICIAL_LINK:
      return state = { ...state, official_link: action.payload };
    case SET_FORM_LINK:
      return state = { ...state, form_link: action.payload };
    case VIEW_SUBJECT_BY_QUIZ:

      return state = { ...state, view_subject_by_quiz: action.payload };

    // ------------COUPON-----------------
    case VIEW_ALL_COUPON:
      // 
      return state = { ...state, all_coupon: action.payload };
    //----------------------------minipackage--------------------------------
    case SET_MINIPACKAGE_ID:
      // 
      return state = { ...state, minipackage_id: action.payload };

    case SET_MINIPACKAGE_BY_ID:
      // 
      return state = { ...state, view_minipackage_by_id: action.payload };


    //------------------------previoys year --------------------------------//
    case SET_PREVIOUSYEAR_ID:
      // 
      return state = { ...state, previousYears: action.payload };
    //------------------------Exam Ai  --------------------------------//
    case SET_EXAMAI_ID:
      // 
      return state = { ...state, examAiDetails: action.payload };
    //----------------------------Teset series--------------------------------//
    case SET_TESTSERIES_IDS:

      return state = { ...state, testSeriesIds: action.payload };
    //--------------------------------------------------SEARCH------------------------------------------//


    case SEARCH_PREVIOUS:
      return {
        ...state,
        search_previous: action.payload
      };
    case SEARCH_PACKAGES:
      return {
        ...state,
        search_packages: action.payload
      };
    case SEARCH_MINIPACKAGE:
      return {
        ...state,
        search_minipackage: action.payload
      };
    case SEATCH_TESTSERIES
      :
      return {
        ...state,
        search_testSeries: action.payload
      };

    case SEATCH_EXAMAI
      :
      return {
        ...state,
        search_examAi: action.payload
      };




    case SEARCH_QUIZES:
      return {
        ...state,
        search_previous: action.payload
      };

    case SEARCH_PDFS:
      return {
        ...state,
        search_pdfs: action.payload
      };

    case TEST_SEARCH_QUIZES:
      return {
        ...state,
        search_test: action.payload
      };

    case SEARCH_VIDEOES:
      return {
        ...state,
        search_videoes: action.payload
      };
    case SET_SUBJECT_ID:
      return {
        ...state,
        subject_id: action.payload
      };
    case SET_SUBJECTS_ID:
      return {
        ...state,
        subjects_id: action.payload
      };

    case VIEW_ALL_LANGUAGE:
      return {
        ...state,
        all_language: action.payload.language, filter_language: action.payload.language
      }
    case SET_LANGUAGE_FILTERED_VALUE:
      return {
        ...state,
        filter_language: action.payload
      }
    //----------------------------------------NOTIFICATION------------------------------------//
    case VIEW_ALL_NOTIFICATION:
      return {
        ...state,
        view_all_notification: action.payload.notification, notification_length: action.payload.count
      }

    //--------------------------------Testpass Pro--------------------------------------------------//
    case VIEW_ALL_TESTPASS:
      return {
        ...state,
        view_all_testpass: action.payload.testpass
      }
    case SET_SECTION_ID:
      return {
        ...state,
        set_section_id: action.payload
      }

    case SET_SUBJECT_IDS:
      return {
        ...state,
        set_subject_id: action.payload
      }
    case SET_LANGUAGE_ID:
      return {
        ...state,
        set_language_id: action.payload
      }
    default:
      return state;
  }

}



