import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import graduate from '../images/graduated (1).png';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";




export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            package_name: "",
            package_id: "",
            studentm: false,
            student_special: false,

        }
    }

    componentDidMount() {
        this.props.viewAllPackage();

    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Package</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1%" }}>
                        <center style={{ borderRadius: 0 }}>
                            <Link to="/addpackage" style={{ textDecoration: "none" }}>
                                <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                    this.setState({
                                        add: true,
                                    })
                                }}>
                                    <IconButton>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    Add Package
                                </Button></Link>
                        </center>
                    </Box>
                </Grid>

                <Paper
                    component="form"
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft: "4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                        <img src={Search} style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className='serach-text'
                        placeholder="Search "
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => {
                            this.props.searchPackages(admin.all_packages, e.target.value)
                        }}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Package Name</TableCell>
                                            <TableCell style={{ paddingLeft: "9%" }}>Action</TableCell>
                                            <TableCell style={{ paddingLeft: "12%" }}>Student Special</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.search_packages) && admin.search_packages !== undefined && admin.search_packages.sort((a, b) => a.package_name?.localeCompare(b.package_name)).map((c) => {
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{c.package_name}</TableCell>
                                                    <TableCell style={{ paddingLeft: "8%" }} >

                                                        <Tooltip title="Edit">
                                                            <Link to="/editpackage" style={{ textDecoration: "none" }}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        
                                                                        this.props.setpackageId(c);
                                                                        // this.props.setMinipackage(c);
                                                                        // this.props.setMinipackageHint(c.mini_hint);
                                                                        // this.props.setMinipackageDesc(c.mini_des);

                                                                    }}
                                                                    style={{ marginLeft: "-10px" }}
                                                                >
                                                                    {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                    <img src={Edit} alt='' height={24} width={24} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        package_name: c.package_name,
                                                                        package_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>

                                                        </Tooltip>

                                                    </TableCell>
                                                    <TableCell style={{ paddingLeft: "13%" }} >

                                                        <Tooltip title="Student Special">
                                                            <IconButton

                                                                onClick={() => {

                                                                    this.setState({
                                                                        studentm: true,
                                                                        package_name: c.package_name,
                                                                        package_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={c.student_special}
                                                                    onClick={() => { this.setState({ student_special: !this.state.student_special }) }} />
                                                                <img src={graduate} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>





                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.package_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        onClick={() => { this.props.deletepackage(this.state.package_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>

                    <Dialog
                        open={this.state.studentm}
                        maxWidth="md"
                    >
                        <center>
                            <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Are You Sure ....</Typography>
                            <Grid style={{ alignItems: "center", paddingRight: 10 }}>
                                <Grid>
                                    <center>
                                        <Button
                                            // className='btns'
                                            variant="outlined"
                                            onClick={() => {
                                                this.setState({ studentm: false });
                                            }}
                                            style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                        <Button
                                            // className='btns'

                                            onClick={() => { this.props.updatePackage(this.state.package_id, this.state.student_special); this.setState({ studentm: false }) }}
                                            style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes</Button>
                                    </center>
                                </Grid>

                            </Grid>
                        </center>


                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}