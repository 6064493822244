import React, { Component } from "react";
import { connect } from "react-redux";
import ExamAi from "../component/ExamAi";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    updateExamAi,
    deleteExamAi,
    viewAllExamAi,
    viewAllPdf,
    setExamAi,
    searchExamAi
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <ExamAi {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
   
    updateExamAi: (examAi_id, exam_ai_name) => {
      dispatch(updateExamAi(examAi_id, exam_ai_name))
    },
    deleteExamAi: (examAi_id) => {
      dispatch(deleteExamAi(examAi_id))
    },
    viewAllExamAi: () => {
      dispatch(viewAllExamAi())
    },
    viewAllPdf:() =>{
      dispatch(viewAllPdf())
    },
    setExamAi:(payload)  =>{
      dispatch(setExamAi(payload) )
    },
    searchExamAi:(all_exam_ai, e)  =>{
      dispatch(searchExamAi(all_exam_ai, e) )
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    

    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);