import React, { Component } from "react";
import { connect } from "react-redux";
import PreviousQ from "../component/AddPreviousyear";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createPreviousYearQuestion,
  updatePreviousYearQuestion,
  deletePreviousYearQuestion,
  viewAllPreviousYearQuestion,
  viewAllPdf,
  viewAllCategory,
  viewAllSubject,
  Checkedpdf,
  StorePdf,
  searchpdf,
  UnCheckedpdf,
  NullPdf,
  searchLanguage,
  viewAllExams,
  viewAllCategoryExam,
  SelectTPreSubject,
  Checkedsubject,
  SelectPdf,
  searchsubject,
  viewAllLanguage,
  searchlanguage,
  viewAllStages
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <PreviousQ {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createPreviousYearQuestion: (package_name, package_desc, year, access, subject_select, category, exam, stage, language) => {
      dispatch(createPreviousYearQuestion(package_name, package_desc, year, access, subject_select, category, exam, stage, language))
    },
    updatePreviousYearQuestion: (pdf_id, pdf_name) => {
      dispatch(updatePreviousYearQuestion(pdf_id, pdf_name))
    },
    deletePreviousYearQuestion: (pdf_id) => {
      dispatch(deletePreviousYearQuestion(pdf_id))
    },
    viewAllPreviousYearQuestion: () => {
      dispatch(viewAllPreviousYearQuestion())
    },
    Checkedpdf: (index, checked, quantity, all_pdf) => {
      dispatch(Checkedpdf(index, checked, quantity, all_pdf))
    },
    UnCheckedpdf: (all_pdf) => {
      dispatch(UnCheckedpdf(all_pdf))
    },
    viewAllPdf: (page, rowPerPage, searchQuery) => {
      dispatch(viewAllPdf(page, rowPerPage, searchQuery))
    },
    searchpdf: (pdf, e) => {
      dispatch(searchpdf(pdf, e))
    },
    StorePdf: (lesson, pdfcount, pdf, pdf_count) => {
      dispatch(StorePdf(lesson, pdfcount, pdf, pdf_count))
    },
    NullPdf: () => {
      dispatch(NullPdf())
    },
    searchsubject: (all_subject, e) => {
      dispatch(searchsubject(all_subject, e))
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    viewAllSubject: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllSubject(page_number, rowPerPage, searchQuery))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    searchLanguage: (pdf, e) => {
      dispatch(searchLanguage(pdf, e));
    },

    // viewAllExams:() => {
    //   dispatch(viewAllExams());
    // }, 
    viewAllStages: () => {
      dispatch(viewAllStages());
    },
    viewAllCategoryExam: (category_id) => {
      dispatch(viewAllCategoryExam(category_id))
    },
    SelectTPreSubject: (subject) => {
      dispatch(SelectTPreSubject(subject))
    },
    Checkedsubject: (index, checked, quantity, all_subject) => {
      dispatch(Checkedsubject(index, checked, quantity, all_subject))
    },
    SelectPdf: (trends, count, pdf) => {
      dispatch(SelectPdf(trends, count, pdf))
    },
    viewAllLanguage: () => {
      dispatch(viewAllLanguage())
    },
    searchlanguage: (all_language, e) => {
      dispatch(searchlanguage(all_language, e))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);