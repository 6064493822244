import { Grid, Box, Button, Typography, MenuItem, TextField, Dialog, FormControl, IconButton } from '@mui/material'
import React, { Component } from 'react'
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from "@material-ui/core/styles";
import One from "../images/count.svg"
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from "../../admin/component/Quiil";
import Loader from '../../../common/loader/containers/loader_cont';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
// import Quill from "../../admin/component/Quiil";
import Checkbox from '@mui/material/Checkbox';

// import Loader from '../../../common/loader/containers/loader_cont';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import Quiz from '../images/quiz.svg';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase"
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00f" // Set the hover border color
        }
    }
});

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));
export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class addquiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueId: uuidv4(),
            editorHtml: '',
            value: '',
            quiz_title: "",
            quiz_desc: "",
            question_url: "",
            qustion_title: "",
            quastion_desc: "",
            opation1: "",
            option1_url: "",
            option1_desc: "",
            opation2: "",
            option2_url: "",
            option2_desc: "",
            opation3: "",
            option3_url: "",
            option3_desc: "",
            opation4: "",
            option4_url: "",
            option4_desc: "",
            opation5: "",
            option5_url: "",
            option5_desc: "",
            answer: "",
            answer_url: "",
            answer_desc: "",
            queslength: 0,
            questionLength: 0,
            showWarning: false,
            editorHtml: "",
            addsubmit: false,
            subjectmodal: false,
            chaptermodal: false,
            addQuizModal: false,
            // subject: [],
            languagemodal: false,
            language: [],
            section: [],
            questions: [],
            setSection: false,
            subject_id: "",
            section_id: "",
            Vid: 0,
            Qid: 0,
            Lang: 0,
            Markss: "",
            marks: "",
            time: "",
            question_marks: "",
            cut_marks: "",
            subjectChecked: [],
            subject_filtered: [],
            chapter_filtered: [],
            chapterChecked: [],
            timemodal: false,
            section_time: "",
            displayedSectionTime: "",
            languageChecked: [],
            langugae_filtered: [],
            questionCreated: false,
            submitTest: false,
            total_question: "",
            access: "Paid",
            questionDataArray: [],
            rightAnswers: [
                {
                    option: "1"
                },
                {
                    option: "2"
                },
                {
                    option: "3"
                },
                {
                    option: "4"
                },
                {
                    option: "5"
                },
            ],
            question_time: 0,

        }

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorChange0 = this.handleEditorChange0.bind(this);
        this.handleEditorChange1 = this.handleEditorChange1.bind(this);
        this.handleEditorChange2 = this.handleEditorChange2.bind(this);
        this.handleEditorChange3 = this.handleEditorChange3.bind(this);
        this.handleEditorChange4 = this.handleEditorChange4.bind(this);
        this.handleEditorChange5 = this.handleEditorChange5.bind(this);
    }
    handleInputChange = (event) => {
        // Get the input value
        const inputValue = event.target.value;
        // Update the state with the input value as an array
        this.setState({ QustationoneArray: [inputValue] });
    }
    componentDidMount() {
        this.props.viewAllTQuestion();
        this.props.viewAllTSubject();
        this.props.viewAllChapter();
        this.props.viewAllSection()
        this.props.viewAllLanguage()


        // this.props.resetValue();

        window.addEventListener('beforeunload', this.handlePageRefresh);
        // 
        // 
        if (this.props.admin.refres_status === false) {
            this.props.DeleteAllQuestionrefersh();
            this.props.setRes(true);
            this.props.resetValue();
            this.props.resetValueopation1();
            this.props.resetValueopation2();
            this.props.resetValueopation3();
            this.props.resetValueopation4();
            this.props.resetValueopation5();
            this.props.resetValueanswer();
        }
        // if(this.state.showWarning === false){

        //     this.setState({ showWarning: true });
        // }
        // else{
        //     this.setState({ showWarning: false });

        // }

    }
    createTQuestion = (questionData, Qid, Vid, Lang) => {


        this.setState((prevState) => {
            const updatedSections = [...prevState.section];

            if (Qid >= 0 && Qid < updatedSections.length) {
                const updatedSection = { ...updatedSections[Qid] };

                if (
                    updatedSection.subject &&
                    updatedSection.subject[Vid] &&
                    updatedSection.subject[Vid].language &&
                    updatedSection.subject[Vid].language[Lang]
                ) {
                    const updatedLanguage = { ...updatedSection.subject[Vid].language[Lang] };

                    updatedLanguage.questions = [
                        ...(updatedLanguage.questions || []),
                        questionData,
                    ];

                    updatedSection.subject[Vid].language[Lang] = updatedLanguage;

                } else {
                    console.error(`Invalid indices for Vid and Lang in section at index ${Qid}.`);
                }

                updatedSections[Qid] = updatedSection;
            } else {
                console.error(`Section at index ${Qid} does not exist.`);
            }

            return {
                section: updatedSections,
                questionCreated: true,
            };
        });
    };

    handleCreateQuestionAndQuiz = async () => {
        const { Qid, Vid, Lang } = this.state;
        const uniqueId = uuidv4();
        const questionData = {
            uniqueId: uniqueId,
            qustion_title: this.state.qustion_title,
            question_img: this.props.admin.question_img,
            option1: "1",
            opation1_img: this.props.admin.opation1_img,
            option1_desc: this.state.option1_desc,
            option2: "2",
            opation2_img: this.props.admin.opation2_img,
            option2_desc: this.state.option2_desc,
            option3: "3",
            opation3_img: this.props.admin.opation3_img,
            option3_desc: this.state.option3_desc,
            option4: "4",
            opation4_img: this.props.admin.opation4_img,
            option4_desc: this.state.option4_desc,
            option5: "5",
            opation5_img: this.props.admin.opation5_img,
            option5_desc: this.state.option5_desc,
            answer: this.state.answer,
            answer_img: this.props.admin.answer_img,
            editorHtml: this.state.editorHtml,
            quiz_id: this.props.admin.uuid,
            subject_id: this.props.admin.subjects_id,
            section_id: this.props.admin.set_section_id,
            question_marks: parseFloat(this.state.question_marks),
            cut_marks: parseFloat(this.state.cut_marks),
            question_time: this.state.question_time
        };


        // Execute createTQuestion
        await this.createTQuestion(questionData, Qid, Vid, Lang);

        // Check if createTquiz should be executed

        if (this.state.questionCreated) {
            this.props.createTquiz(
                this.state.quiz_title,
                this.state.quiz_desc,
                this.props.admin.uuid,
                this.state.time,
                parseFloat(this.state.marks),
                this.state.section,
                this.state.setSection,
                parseInt(this.state.total_question),
                this.state.access
            );
            this.setState({
                quiz_title: "",
                quiz_desc: "",
                time: "",
                marks: "",
                section: [],
                setSection: "",
                total_question: "",
                // access: "",
                questionCreated: false,
                // submitTest: true
            });
            this.props.navigate('/reviewTquiz')
        }

        // Reset values to null or empty strings

        this.props.admin.opation2_img = "";
        this.props.admin.opation1_img = "";
        this.props.admin.opation3_img = "";
        this.props.admin.opation4_img = "";
        this.props.admin.opation5_img = "";
        this.props.admin.answer_img = "";
        this.props.admin.question_img = "";
        // ... reset other properties

        this.setState({
            // ... reset other state properties
            qustion_title: "",
            quastion_desc: "",
            question_img: "",
            opation1: "",
            opation1_img: "",
            option1_desc: "",
            opation2: "",
            opation2_img: "",
            option2_desc: "",
            opation3: "",
            opation3_img: "",
            option3_desc: "",
            opation4: "",
            opation4_img: "",
            option4_desc: "",
            opation5: "",
            opation5_img: "",
            option5_desc: "",
            answer: "",
            answer_img: "",
            answer_desc: "",
            editorHtml: "",
            question_url: "",
            answer_url: "",
            option1_url: "",
            option2_url: "",
            option3_url: "",
            option4_url: "",
            option5_url: "",
            quiz_title: "",
            quiz_desc: "",
            language: "",
            time: "",
            marks: "",
            Markss: "",
            question_marks: "",
            cut_marks: ""
            // questionCreated: false,
        });

        this.handleButtonClick();
        this.handleButtonClickLenght();

    };


    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handlePageRefresh);
    }
    handlePageRefresh = (event) => {
        event.preventDefault();
        event.returnValue = '';
        this.props.setRes(false);
        // this.state.refres_status = 1;
        // 
        // // if(event.returnValue===''){
        // //     this.props.DeleteAllQuestionrefersh();
        // // }
        // // 
    }

    handleButtonClick = () => {
        // Increment 'queslength' by 1 when the button is clicked
        this.setState(prevState => ({
            queslength: prevState.queslength + 1
        }));
    }

    handleButtonClickLenght = () => {
        // Increment 'queslength' by 1 when the button is clicked
        this.setState(prevState => ({
            questionLength: prevState.section?.[this.state.Qid]?.subject?.[this.state.Vid]?.language?.[this.state.Lang].questions?.length + 1
        }));
    }
    // handleDeleteSub = (id) => {
    //     this.setState(prevState => {
    //         const newState = { ...prevState };
    //         const newArr = [...newState.subject];
    //         newArr.splice(id, 1);
    //         newState.subject = newArr;
    //         return newState;
    //     });
    // }

    handleDeleteSec = (id) => {

        let updated = this.state.section.filter(i => i._id !== id)

        this.setState(() => this.state.section = updated)

    }

    handleDeleteSub = (Qid, id) => {
        this.setState(prevState => {
            const updatedSection = [...prevState.section];
            const updatedSubjects = [...updatedSection[Qid]?.subject];

            if (updatedSubjects) {
                updatedSubjects.splice(id, 1);
                updatedSection[Qid].subject = updatedSubjects;
            }

            return { section: updatedSection };
        });
    };

    handleDeleteL = (Qid, Vid, id) => {
        this.setState(prevState => {
            const updatedSection = [...prevState.section];
            const updatedSubjects = [...updatedSection[Qid]?.subject[Vid].language];

            if (updatedSubjects) {
                updatedSubjects.splice(id, 1);
                updatedSection[Qid].subject[Vid].language = updatedSubjects;
            }

            return { section: updatedSection };
        });
    };

    // handleDeleteL = (id) => {

    //     let updated = this.state.language.filter(i => i._id !== id)

    //     this.setState(() => this.state.language = updated)

    // }
    UnCheckedLanguage = (all_language, itemToUncheck) => {
        this.setState((prevState) => ({
            languageChecked: prevState.languageChecked.filter((item) => item !== itemToUncheck),
            langugae_filtered: all_language,
            // ... other state updates
        }));
    };

    handleChange = (value) => {
        this.setState({ value });
    }

    handleEditorChange4(html) {
        this.setState({ option4_desc: html });
    }
    handleEditorChange5(html) {
        this.setState({ option5_desc: html });
    }
    handleEditorChange3(html) {
        this.setState({ option3_desc: html });
    }
    handleEditorChange2(html) {
        this.setState({ option2_desc: html });
    }
    handleEditorChange0(html) {
        this.setState({ qustion_title: html });
    }
    handleEditorChange1(html) {
        this.setState({ option1_desc: html });
    }
    handleEditorChange0(html) {
        this.setState({ qustion_title: html });

    }
    handleEditorChange(html) {
        this.setState({ editorHtml: html });
    }


    UnCheckedSection = (all_section, itemToUncheck) => {
        this.setState((prevState) => ({
            chapterChecked: prevState.chapterChecked.filter((item) => item !== itemToUncheck),
            chapter_filtered: all_section,
            // ... other state updates
        }));
    };
    UnCheckedSubject = (all_subject, itemToUncheck) => {
        this.setState((prevState) => ({
            subjectChecked: prevState.subjectChecked.filter((item) => item !== itemToUncheck),
            subject_filtered: all_subject,
            // ... other state updates
        }));
    };

    handleDescriptionChange = (e) => {
        this.setState({ section_time: e.target.value });
    };

    handleSave = () => {
        try {
            const { section, Qid, section_time } = this.state;

            // Ensure section[Qid] is initialized as an object
            if (!section[Qid]) {
                section[Qid] = {};
            }

            // Ensure section[Qid].section_time is initialized as an empty string
            if (!section[Qid].section_time) {
                section[Qid].section_time = "";
            }

            // Update section[Qid].section_time with the new value
            section[Qid].section_time = section_time;

            // Log the updated state for debugging (optional)


            // Set the state for the 'section' property
            this.setState({ section });
        } catch (error) {
            console.error("Error saving description:", error);
        }
    }













    render() {
        const { classes, admin } = this.props;
        const { Vid, section, Lid, Qid, language, Lang } = this.state;

        return (
            <Grid>
                <Box className='categoryname'>
                    <center style={{ display: "flex" }}>
                        <Link to="/testquiz" style={{ textDecoration: "none" }}>
                            <IconButton
                            >
                                <ArrowBackIcon style={{ color: "white" }} />
                            </IconButton>
                        </Link>
                        <Typography style={{ color: "white ", fontSize: 16, marginTop: "8px" }}>Create Quiz</Typography>


                        <Button style={{ color: "white ", textAlign: 'center', }}></Button>
                    </center>
                </Box>



                <Grid className='boxdesign' style={{ border: "1px solid #888", height: 530, width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Add Quiz title & Hint
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Title
                    </Typography>
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            size="small"
                            placeholder='Enter quiz title'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                            onChange={(e) => { this.setState({ quiz_title: e.target.value }) }}
                            className={classes.root}
                        />
                    </div>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Hint
                    </Typography>
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Quiz Description '
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            onChange={(e) => { this.setState({ quiz_desc: e.target.value }) }}
                        />
                    </div>

                    <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Time In Minutes:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Time '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "22%", }}
                            onChange={(e) => { this.setState({ time: e.target.value }) }}
                        />

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Total Marks:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Marks '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "20%", }}
                            onChange={(e) => { this.setState({ marks: e.target.value }) }}
                        />


                        <div style={{ marginLeft: "15px", marginTop: "8px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.setSection}
                                        onChange={(e) => { this.setState({ setSection: e.target.checked }) }}
                                        color="primary"
                                    />
                                }
                                label="Set Section Time"
                            />
                        </div>



                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Total Questions:
                        </Typography>

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Total Question '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "22%", marginLeft: "10px", marginTop: "10px" }}
                            onChange={(e) => { this.setState({ total_question: e.target.value }) }}
                        />
                    </div>

                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "-10px" }}>
                        Access
                    </Typography>
                    {/* <Grid style={{ marginTop: "2%", width: 600 }}> */}
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>

                        <TextField
                            required
                            id="outlined-multiline-static"
                            placeholder='Enter Access'
                            select
                            className={classes.root}
                            rows={1}
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            value={this.state.access}
                            onChange={(e) => {
                                this.setState({ access: e.target.value })
                            }}
                        >
                            <MenuItem value={"Free"}>{"Free"}</MenuItem>
                            <MenuItem value={"Paid"}>{"Paid"}</MenuItem>

                        </TextField>

                    </div>

                </Grid>
                <Grid style={{ display: "flex", marginTop: "22px", }}>

                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", marginLeft: "57px" }}>
                        Question Added:-
                    </Typography>

                    {/* <img src={One} height={50} width={50} style={{ marginLeft: "10px", marginTop: "10px" }} />
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", marginTop: "22px", marginLeft: "-30px" }}>
                        {this.state.queslength}
                      10
                    </Typography> */}
                    <div style={{ "display": "flex", "flexDirection": "row", "gap": "11px", "width": "47%", "flexWrap": "wrap" }}>

                        {
                            Array(this.state.queslength).fill("err").map((i, index) => (<>
                                {/* <img src={One} alt='' height={50} width={50} style={{ marginLeft: "10px", marginTop: "10px" }} /> */}
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold" }}>

                                    {index + 1}
                                    {/* jsnddnnd */}
                                </Typography>
                            </>
                            ))
                        }
                    </div>
                </Grid>


                <Grid className='boxdesign' style={{ border: "1px solid #888", height: "100%", width: "70%", borderRadius: 10, borderColor: "#c4c4c4", marginBottom: "5%" }}>
                    <Box style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', marginTop: '4%' }}>
                        <center style={{ height: '44px', color: '#6257CD', textAlign: 'center', textTransform: 'capitalize', backgroundColor: '#fff', width: '100%', borderRadius: 5, display: 'flex', flexDirection: 'row' }}>
                            <Button variant="outlined" style={{ border: '2px solid #6257cd' }}>
                                Select Section
                            </Button>
                        </center>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: 'flex', border: '2px solid #6257cd', flexDirection: 'row', marginLeft: '6%', marginRight: '12%', alignItems: 'center' }}>
                            {Array.isArray(section) && section?.map((e, index) => {
                                return (
                                    <div style={{ borderColor: 'black', display: 'flex', flexDirection: 'row', borderWidth: '10px', margin: 5 }} key={index}>
                                        <Chip
                                            label={e.section_name}
                                            style={{
                                                backgroundColor: this.state.Qid === index ? "#6257CD" : '',
                                                color: this.state.Qid === index ? "white" : ''
                                            }}
                                            onClick={() => {
                                                this.setState({ Qid: index });
                                                this.props.section_id(e._id);
                                            }}
                                            onDelete={() => {
                                                this.handleDeleteSec(e._id);
                                                this.UnCheckedSection(admin.all_section, e.section_name);
                                            }}
                                        />
                                    </div>
                                )
                            })}
                            <AddIcon onClick={() => {
                                this.setState({ chaptermodal: true });
                            }} />
                        </Grid>
                    </Box>
                    {this.state.setSection === true && (
                        <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                            <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}>
                                <Button variant="outlined" style={{ border: "2px solid #6257cd" }}>
                                    Section Time In Minute
                                </Button>
                            </center>
                            <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12%", alignItems: "center" }}>

                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>
                                    {section[Qid]?.section_time}
                                </div>

                                <AddIcon onClick={() => {
                                    this.setState({
                                        timemodal: true,
                                    })
                                }} />
                            </Grid>
                        </Box>
                    )}

                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}>
                            <Button variant="outlined" style={{ border: "2px solid #6257cd" }}>
                                Select Subject
                            </Button>
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12%", alignItems: "center" }}>
                            {(section?.[Qid]?.subject ?? [])?.map((e, index) => {
                                return (
                                    <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }} key={index}>
                                        <Chip
                                            label={e.subject_name}
                                            style={{
                                                backgroundColor: this.state.Vid === index ? "#6257CD" : '',
                                                color: this.state.Vid === index ? "white" : ''
                                            }}
                                            onClick={() => {
                                                this.setState({ Vid: index })
                                                this.props.subjects_id(e._id);
                                            }}
                                            onDelete={() => {
                                                this.handleDeleteSub(Qid, index)
                                                this.UnCheckedSubject(admin.all_subject, e.subject_name);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            <AddIcon onClick={() => {
                                this.setState({
                                    subjectmodal: true,
                                })
                            }} />
                        </Grid>
                    </Box>
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}>
                            <Button
                                variant="outlined"
                                style={{ border: "2px solid #6257cd" }}
                            // onClick={() => { this.setState({ Trendmodal: true }) }}
                            >
                                Add Language
                            </Button>
                        </center>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "12%", marginRight: "12%", alignItems: "center" }}>
                            {(section?.[Qid]?.subject?.[Vid]?.language ?? [])?.map((e, index) => {
                                return (
                                    <div key={index} style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: "0px 7px " }}>
                                        <Chip
                                            style={{
                                                backgroundColor: this.state.Lang === index ? "#6257CD" : '',
                                                color: this.state.Lang === index ? "white" : ''
                                            }}
                                            // variant={e.hasOwnProperty('pdf') ? "filled" : "outlined"}
                                            onClick={() => this.setState({ Lang: index })}
                                            label={e.language}
                                            onDelete={() => {
                                                this.handleDeleteL(Qid, Vid, index);
                                                this.UnCheckedLanguage(admin.all_language, e.language);
                                            }}
                                        />
                                    </div>
                                )
                            })}

                            <AddIcon onClick={() => this.setState({ languagemodal: true })} />
                        </Grid>
                    </Box>

                    <Grid style={{ display: "flex", marginTop: "22px", }}>

                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", marginLeft: "10px" }}>
                            Question Added:-
                        </Typography>


                        <div style={{ "display": "flex", "flexDirection": "row", "gap": "11px", "width": "47%", "flexWrap": "wrap", }}>

                            {
                                // Array(this.state.questionLength).fill("err").map((i, index) => (<>
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", }}>

                                    {/* {index + 1} */}
                                    {section?.[Qid]?.subject?.[Vid]?.language?.[Lang]?.questions?.length || 0}
                                </Typography>
                                // </>
                                // ))
                            }


                        </div>
                    </Grid>

                    <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Question Marks:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Question Marks '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "20%", }}
                            value={this.state.question_marks}
                            onChange={(e) => { this.setState({ question_marks: e.target.value }) }}
                        />

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Cut Marks:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Cut Marks '
                            type='number'
                            value={this.state.cut_marks}
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "20%", }}
                            onChange={(e) => { this.setState({ cut_marks: e.target.value }) }}
                        />


                    </div>

                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Add Question Text
                    </Typography>
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>
                        <div>
                            <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                value={this.state.qustion_title}
                                onChange={this.handleEditorChange0}
                                modules={addquiz.modules}
                                formats={addquiz.formats}
                            />
                        </div>
                    </div>
                    {/* <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Add Description of question(if any)
                    </Typography> */}
                    {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Description of question'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            value={this.state.quastion_desc}
                            onChange={(e) => { this.setState({ quastion_desc: e.target.value }) }}
                        />
                    </div> */}
                    {this.state.question_url !== "" && this.props.admin.question_img !== "" && <img src={this.props.admin.question_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                    {this.state.question_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                        startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                        Upload
                        {/* <input hidden accept="image/*" multiple type="file" /> */}
                        <input hidden type="file" multiple
                            accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                            // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                            // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                            onChange={(e) => {
                                this.setState({ question_url: e.target.files[0] });


                            }}

                        />
                    </Button>}
                    <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                        {this.state.question_url !== "" && <Button variant="outlined" component="label" className='upload'
                            //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                            style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Successfully uploaded
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            {/* <input hidden type="file" multiple
                                        accept="video/mp4,video/x-m4v,video/*"
                                        // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                        onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

                                    /> */}
                        </Button>}
                        {this.state.question_url !== "" && <Button variant="outlined" component="label"
                            // onChange={(e)=> { this.setState({ question_url:"" }) }}
                            onClick={() => {
                                this.setState({ question_url: "", });
                                this.props.resetValue()
                            }}

                            // className='upload' 
                            //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                        // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                        >
                            Clear
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            {/* <input hidden type="file" multiple
                                        accept="video/mp4,video/x-m4v,video/*"
                                        // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                        
                                    /> */}
                            {/* onChange={(e) => { this.setState({ question_url: "" }) }} */}
                        </Button>}
                        {this.state.question_url !== "" && <Button variant="outlined" component="label"
                            onClick={() => this.props.UploadTQuestion(this.state.question_url)}

                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                        >
                            Upload

                        </Button>}
                    </Grid>




                </Grid>
                <Grid style={{ border: "1px solid #c4c4c4", width: "70%", borderRadius: 10, marginLeft: "4%", marginBottom: "5%" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Add Answers/Options
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Add Options
                    </Typography>



                    <Grid container spacing={2} >
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                // required
                                // // id="outlined-required"
                                // // label="Category Name"
                                // size="small"
                                // placeholder='Option 1'
                                // value={this.state.opation1}
                                // InputLabelProps={{ shrink: true, required: true }}
                                // style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                                // onChange={(e) => { this.setState({ opation1: e.target.value }) }}
                                // className={classes.root}
                                >Option 1
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation1_img !== "" && <img src={this.props.admin.opation1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option1_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option1_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option1_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option1_url !== "" && this.props.admin.opation1_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation1();
                                        this.setState({ option1_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option1_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin1(this.state.option1_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option1_desc}
                                    onChange={this.handleEditorChange1}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option1_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option1_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                // required
                                // // id="outlined-required"
                                // // label="Category Name"
                                // size="small"
                                // placeholder='Option 1'
                                // value={this.state.opation1}
                                // InputLabelProps={{ shrink: true, required: true }}
                                // style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                                // onChange={(e) => { this.setState({ opation1: e.target.value }) }}
                                // className={classes.root}
                                >Option 2
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation2_img !== "" && <img src={this.props.admin.opation2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option2_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option2_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option2_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option2_url !== "" && this.props.admin.opation2_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation2();
                                        this.setState({ option2_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option2_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin2(this.state.option2_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option2_desc}
                                    onChange={this.handleEditorChange2}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option2_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option2_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                // required
                                // // id="outlined-required"
                                // // label="Category Name"
                                // size="small"
                                // placeholder='Option 1'
                                // value={this.state.opation1}
                                // InputLabelProps={{ shrink: true, required: true }}
                                // style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                                // onChange={(e) => { this.setState({ opation1: e.target.value }) }}
                                // className={classes.root}
                                >Option 3
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation3_img !== "" && <img src={this.props.admin.opation3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option3_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option3_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option3_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option3_url !== "" && this.props.admin.opation3_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation3();
                                        this.setState({ option3_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option3_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin3(this.state.option3_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option3_desc}
                                    onChange={this.handleEditorChange3}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option1_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option1_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                // required
                                // // id="outlined-required"
                                // // label="Category Name"
                                // size="small"
                                // placeholder='Option 1'
                                // value={this.state.opation1}
                                // InputLabelProps={{ shrink: true, required: true }}
                                // style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                                // onChange={(e) => { this.setState({ opation1: e.target.value }) }}
                                // className={classes.root}
                                >Option 4
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation4_img !== "" && <img src={this.props.admin.opation4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option4_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option4_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option4_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option4_url !== "" && this.props.admin.opation4_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation4();
                                        this.setState({ option4_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option4_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin4(this.state.option4_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option4_desc}
                                    onChange={this.handleEditorChange4}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option1_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option1_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>

                    </Grid>
                    {/* //-----------------------------------------option 5---------------------------------------------// */}
                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                // required
                                // // id="outlined-required"
                                // // label="Category Name"
                                // size="small"
                                // placeholder='Option 1'
                                // value={this.state.opation1}
                                // InputLabelProps={{ shrink: true, required: true }}
                                // style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                                // onChange={(e) => { this.setState({ opation1: e.target.value }) }}
                                // className={classes.root}
                                >Option 5
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation5_img !== "" && this.props.admin.opation5_img !== undefined && this.props.admin.opation5_img !== null && this.props.admin.opation5_img !== " " && <img src={this.props.admin.opation5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option5_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option5_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option5_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option5_url !== "" && this.props.admin.opation5_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation5();
                                        this.setState({ option5_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option5_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin5(this.state.option5_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option5_desc}
                                    onChange={this.handleEditorChange5}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>

                        </Grid>

                    </Grid>

                </Grid>
                <Grid className='boxdesign' style={{ border: "1px solid #888", height: "auto", width: "70%", borderRadius: 10, borderColor: "#c4c4c4", marginBottom: "5%" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Enter Correct Answer
                    </Typography>


                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <select
                            value={this.state.answer}
                            style={{
                                background: "white",
                                height: '55px',
                                width: "100%",
                                border: "1px solid #888",
                                fontSize: 16,
                                borderRadius: 5,
                                padding: 7,
                                color: 'gray'
                            }}
                            select

                            onChange={(e) => {
                                this.setState({ answer: e.target.value });

                            }}
                        >
                            <option value="" >Select Right Answer</option>
                            {this.state.rightAnswers.map((e) => (
                                <option value={e.option}>{e.option}</option>
                            ))}
                        </select>

                    </div>

                    {this.props.admin.answer_img !== "" && <img src={this.props.admin.answer_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                    {this.state.answer_url === "" &&
                        <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                onChange={(e) => { this.setState({ answer_url: e.target.files[0] }) }}

                            />
                        </Button>
                    }
                    <Grid style={{ display: "flex", flexDirection: "row" }}>

                        {this.state.answer_url !== "" && <Button variant="outlined" component="label" className='upload'
                            //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                            style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Successfully uploaded

                        </Button>}
                        {this.state.answer_url !== "" && <Button variant="outlined" component="label"
                            // onChange={(e)=> { this.setState({ question_url:"" }) }}
                            onClick={() => {
                                this.props.resetValueanswer();
                                this.setState({ answer_url: "" });
                            }}


                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                        // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                        >
                            Clear

                            {/* onChange={(e) => { this.setState({ question_url: "" }) }} */}
                        </Button>}
                        {/* {this.state.answer_url !== "" &&  */}
                        {/* <Button variant="outlined" component="label"
                            onClick={() => this.props.UploadAnswer(this.state.answer_url)}

                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                        >
                            Upload

                        </Button> */}
                        {/* } */}
                    </Grid>

                    {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                           
                            className={classes.root}
                            size="small"
                            placeholder='Description'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            onChange={(e) => { this.setState({ answer_desc: e.target.value }) }}
                            value={this.state.answer_desc}
                        />
                    </div> */}

                    {/* <ReactQuill style={{ paddingBottom:"20px",width: "93%",borderRadius: 10,  borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }} theme="snow" value={this.state.value} onChange={this.handleChange} /> */}
                    <div>
                        <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                            value={this.state.editorHtml}
                            onChange={this.handleEditorChange}
                            modules={addquiz.modules}
                            formats={addquiz.formats}
                        />
                    </div>

                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "70%", marginLeft: "4%" }}>
                    {/* {this.props.admin.all_question.length < 10 && */}
                    {/* <Link to='/quizes' style={{ textDecoration: "none"}}> */}
                    {/* {(this.state.qustion_title !=="",
                        //    this.state. quastion_desc !== "",
                        //    this.state.question_img !== "",
                           this.state.opation1 !== "",
                        //    this.state.opation1_img !== "",
                           this.state.option1_desc !== "",
                           this.state.opation2 !== "",
                        //    this.state.opation2_img !== "",
                           this.state.option2_desc !== "",
                           this.state.opation3 !== "",
                        //    this.state.opation3_img!== "",
                           this.state.option3_desc !== "",
                           this.state.opation4 !== "",
                        //    this.state.opation4_img !== "",
                           this.state.option4_desc !== "",
                           this.state.answer !== "",
                        //    this.state.answer_img !== "",
                           this.state.answer_desc !== "",
                           this.state.editorHtml !=="" ) && */}


                    <Button
                        style={{
                            color: "white",
                            textAlign: 'center',
                            textTransform: "capitalize",
                            backgroundColor: "#6257CD",
                            marginBottom: "2%"
                        }}
                        // disabled={(this.state.qustion_title === "" || '1', this.state.option1_desc === "" || '2', this.state.option2_desc === "" || '3', this.state.option3_desc === "" || '4', this.state.option4_desc === "" ||  this.props.admin.subjects_id === "")}
                        onClick={() => {
                            const uniqueId = uuidv4();
                            const questionData = {
                                uniqueId: uniqueId,
                                qustion_title: this.state.qustion_title,
                                question_img: this.props.admin.question_img,
                                option1: "1",
                                opation1_img: this.props.admin.opation1_img,
                                option1_desc: this.state.option1_desc,
                                option2: "2",
                                opation2_img: this.props.admin.opation2_img,
                                option2_desc: this.state.option2_desc,
                                option3: "3",
                                opation3_img: this.props.admin.opation3_img,
                                option3_desc: this.state.option3_desc,
                                option4: "4",
                                opation4_img: this.props.admin.opation4_img,
                                option4_desc: this.state.option4_desc,
                                option5: "5",
                                opation5_img: this.props.admin.opation5_img,
                                option5_desc: this.state.option5_desc,
                                answer: this.state.answer,
                                answer_img: this.props.admin.answer_img,
                                editorHtml: this.state.editorHtml,
                                quiz_id: this.props.admin.uuid,
                                subject_id: this.props.admin.subjects_id,
                                section_id: this.props.admin.set_section_id,
                                question_marks: parseFloat(this.state.question_marks),
                                cut_marks: parseFloat(this.state.cut_marks),
                                question_time: this.state.question_time,
                            };

                            this.createTQuestion(questionData, Qid, Vid, Lang);

                            // Reset values to null or empty strings
                            this.props.admin.opation2_img = "";
                            this.props.admin.opation1_img = "";
                            this.props.admin.opation3_img = "";
                            this.props.admin.opation4_img = "";
                            this.props.admin.opation5_img = "";
                            this.props.admin.answer_img = "";
                            this.props.admin.question_img = "";

                            this.setState({
                                addsubmit: false,
                                qustion_title: "",
                                quastion_desc: "",
                                question_img: "",
                                opation1: "",
                                opation1_img: "",
                                option1_desc: "",
                                opation2: "",
                                opation2_img: "",
                                option2_desc: "",
                                opation3: "",
                                opation3_img: "",
                                option3_desc: "",
                                opation4: "",
                                opation5: "",
                                opation4_img: "",
                                opation5_img: "",
                                option4_desc: "",
                                option5_desc: "",
                                answer: "",
                                answer_img: "",
                                answer_desc: "",
                                editorHtml: "",
                                question_url: "",
                                answer_url: "",
                                option1_url: "",
                                option2_url: "",
                                option3_url: "",
                                option4_url: "",
                                option5_url: "",

                            });

                            this.handleButtonClick();
                            this.handleButtonClickLenght();
                        }}
                    >
                        Save & Next
                    </Button>

                    <Box className='saveandcnt'>
                        {/* <center> */}

                        {/* // style={{ textDecoration: "none", pointerEvents: ((this.state.quiz_title === "") || (this.state.quiz_desc === "")) ? 'none' : '' }}
                        > */}
                        {/* <Link to="/reviewTquiz"> */}
                        <Button style={{ color: "white ", textTransform: "capitalize", width: "100%" }}
                            onClick={() => {
                                if (
                                    this.state.qustion_title !== "<p><br></p>" ||
                                    this.state.option1_desc !== "<p><br></p>" ||
                                    this.state.option2_desc !== "<p><br></p>" ||
                                    this.state.option3_desc !== "<p><br></p>" ||
                                    this.state.option4_desc !== "<p><br></p>" ||
                                    this.state.option5_desc !== "<p><br></p>" ||
                                    this.state.editorHtml !== "<p><br></p>"
                                ) {
                                    console.log("new AAAAAAAAA", this.state.qustion_title,
                                        this.state.option1_desc,
                                        this.state.option2_desc,
                                        this.state.option3_desc,
                                        this.state.option4_desc,
                                        this.state.option5_desc,
                                        this.state.editorHtml,);

                                    this.handleCreateQuestionAndQuiz()
                                } else {

                                    this.props.createTquiz(
                                        this.state.quiz_title,
                                        this.state.quiz_desc,
                                        this.props.admin.uuid,
                                        this.state.time,
                                        parseFloat(this.state.marks),
                                        this.state.section,
                                        this.state.setSection,
                                        parseInt(this.state.total_question),
                                        this.state.access,

                                    );
                                    this.setState({
                                        quiz_title: "",
                                        quiz_desc: "",
                                        time: "",
                                        marks: "",
                                        section: [],
                                        setSection: "",
                                        total_question: "",
                                        // access: "",
                                        question_marks: "",
                                        cut_marks: ""
                                        // submitTest: true
                                    });
                                    this.props.navigate('/reviewTquiz')
                                }

                            }}
                            disabled={((this.state.quiz_title === "") || (this.state.quiz_desc === ""))}

                        >Save and Review Quiz</Button>
                        {/* </Link> */}


                        {/* </center> */}
                    </Box>

                </Grid>

                <Dialog
                    open={this.state.showWarning}
                    style={{ height: "600px" }}>
                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                    <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ showWarning: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                <Button
                                    // className='btns'

                                    // disabled={this.state.category_name === ""}
                                    onClick={() => { this.props.DeleteAllQuestionrefersh(); this.setState({ showWarning: false }) }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                            </center>
                        </Grid>
                    </Grid>
                </Dialog>
                {/* Test Subject */}
                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Subject"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(event) => this.props.searchsubject(admin.all_subject, event.target.value)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(this.props.admin.subject_filtered) && this.props.admin.subject_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        <Checkbox
                                            value={e.subject_name}
                                            checked={(this.state.subjectChecked.includes(e.subject_name)) && (section?.[Qid]?.subject?.some(item2 => e?._id === item2?._id))}
                                            onClick={(p) => {
                                                // this.props.viewAllChapter();
                                                const currentLesson = section?.[Qid];

                                                if (!section?.[Qid].hasOwnProperty('subject')) {
                                                    section[Qid].subject = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.subject && currentLesson.subject.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.subject = [...(currentLesson.subject || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            subjectChecked: [...prevState.subjectChecked, e.subject_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        currentLesson.subject = currentLesson.subject.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            subjectChecked: prevState.subjectChecked.filter(desc => desc !== e.subject_name),
                                                        }));
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                    this.props.viewAllTSubject();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                    this.props.viewAllTSubject();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                <Dialog

                    open={this.state.chaptermodal}
                    style={{ height: "100%" }}
                // fullScreen={"100px"}

                // fullWidth={true}
                >
                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#EEECFE",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Section "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(event) => this.props.searchSection(admin.all_section, event.target.value)}
                                />
                            </Search>
                        </Grid>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.section_filter) && admin.section_filter?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.section_name}</Typography>


                                        <Checkbox
                                            value={e.section_name}
                                            checked={this.state.chapterChecked.includes(e.section_name)}
                                            onClick={() => {
                                                // Ensure chapterChecked is initialized as an array
                                                if (!Array.isArray(this.state.chapterChecked)) {
                                                    this.setState({ chapterChecked: [] });
                                                }

                                                if (!this.state.chapterChecked.includes(e.section_name)) {
                                                    // Add section_name to the chapterChecked array
                                                    this.setState(prevState => ({
                                                        chapterChecked: [...prevState.chapterChecked, e.section_name],
                                                        section: [...prevState.section, e],
                                                    }));
                                                } else {
                                                    // Remove section_name from the chapterChecked array
                                                    this.setState(prevState => ({
                                                        chapterChecked: prevState.chapterChecked?.filter(name => name !== e.section_name),
                                                        section: prevState.section.filter(chapter => chapter._id !== e._id),
                                                    }));
                                                }

                                                // Additional code/logic can go here
                                            }}
                                        />


                                    </div>
                                );
                            })}
                        </Grid>


                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ chaptermodal: false });
                                    this.props.viewAllSection();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                onClick={() => {
                                    // this.setState({ chap: "1" })
                                    // this.props.setChapter(this.state.userinfo);
                                    this.setState({ chaptermodal: false });
                                    this.props.viewAllSection();
                                }}
                                // style={{ marginLeft: "450px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Submit</Button>
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>

                        </center>
                    </div>

                </Dialog>

                <Dialog
                    open={this.state.timemodal}
                    style={{ height: "100%" }}
                >
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid style={{ justifyContent: "space-around", marginLeft: "3%", marginTop: "1%" }}>
                            <Grid>
                                <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                                    Enter Section Time:
                                </Typography>
                            </Grid>
                            <Grid>
                                <FormControl style={{ width: '30vw', marginLeft: "3%" }}>
                                    <TextField
                                        placeholder="Enter Section Time In Minute"
                                        onChange={this.handleDescriptionChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ timemodal: false, section_time: '' });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}
                            >
                                Close
                            </Button>

                            <Button
                                className='btns'
                                onClick={() => {
                                    this.handleSave();
                                    this.setState({ timemodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}
                            >
                                Save
                            </Button>
                        </center>
                    </div>
                </Dialog>

                <Dialog

                    open={this.state.languagemodal}
                    style={{ height: "100%" }}>

                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchlanguage(admin.all_language, e)}
                                />
                            </Search>
                        </Grid>


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.filter_language) && admin.filter_language?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.language}</Typography>

                                        <Checkbox
                                            value={e.language}
                                            checked={(this.state.languageChecked.includes(e.language)) && (section?.[Qid]?.subject?.[Vid]?.language?.some(item2 => e?._id === item2?._id))}
                                            onClick={(p) => {
                                                // this.props.viewAllChapter();
                                                const currentLesson = section[Qid].subject[Vid];

                                                if (!section[Qid].subject[Vid].hasOwnProperty('language')) {
                                                    section[Qid].subject[Vid].language = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.language && currentLesson.language.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.language = [...(currentLesson.language || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            languageChecked: [...prevState.languageChecked, e.language],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        currentLesson.language = currentLesson.language.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            languageChecked: prevState.languageChecked.filter(desc => desc !== e.language),
                                                        }));
                                                    }
                                                }
                                            }}
                                        />

                                    </div>
                                );
                            })}
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                <Dialog

                    open={this.state.submitTest}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "300px", overflowX: "hidden" }}>

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Added SuccesFully
                        </Typography>

                        <center>
                            <Link to='/reviewTquiz'>
                                <Button
                                    className='btns'
                                    onClick={() => {
                                        this.setState({ submitTest: false });

                                    }}
                                    style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>OK</Button>
                            </Link>
                        </center>
                    </div>

                </Dialog>
                <Loader />
            </Grid>
        )
    }
}
addquiz.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
    ],
};

addquiz.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
];
const StyledAddQuiz = withStyles(styles)(addquiz);
const RoutedAddQuiz = withRouter(StyledAddQuiz);
export default RoutedAddQuiz;

