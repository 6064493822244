import React, { Component } from "react";
import "../../loader/components/cus_loader.css"
import { Grid } from "rsuite";
export default class Customize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        isVisible: !prevState.isVisible,
      }));
    }, 300);
  }
  render() {
    return (
      <Grid className="cusloader">
    </Grid>
    );
  }
}