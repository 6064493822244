import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography,
    FormControl, InputLabel,
    MenuItem, Select,

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Pdf from '../images/pdf.svg';
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            pdf_name: "",
            english_url: '',
            hindi_url: "",
            old_english_url: '',
            old_hindi_url: "",
            access: "Paid",
            pdf_hint: "",
            eng_pdf_name: "",
            hnd_pdf_name: "",
            details: [
                {
                    access_name: "Paid"
                },
                {
                    access_name: "Free"
                },
            ],
            page: 0,
            setPage: 0,
            rowsPerPage: 30,
            searchTerm: ''

        }

        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
    }

    debouncedSearch(value) {
        this.props.viewAllPdf(this.props.page, this.props.rowsPerPage, value);
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };

    componentDidMount() {
        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");

    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        const language = [
            { label: 'Hindi' },
            { label: 'English' },
        ]

        const handleChangePage = (event, newPage) => {
            this.props.viewAllPdf(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>PDF</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                    access: "Paid"
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Pdf
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft: "4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                        <SearchIcon style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className="search-text"
                        placeholder="Search"
                        inputProps={{ "aria-label": "Search" }}
                        value={this.state.searchTerm}
                        onChange={this.handleSearchChange}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 180 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell >Pdf Hint</TableCell>
                                            <TableCell > Pdf Name</TableCell>
                                            {/* <TableCell > Hindi Pdf Name</TableCell> */}
                                            <TableCell >Action</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {/* {Array.isArray(admin.search_pdfs) && admin.search_pdfs.sort((a, b) => a?.pdf_hint?.localeCompare(b?.pdf_hint)).map((c) => { */}
                                        {Array.isArray(admin.all_pdf) && admin.all_pdf.sort((a, b) => a?.pdf_hint?.localeCompare(b?.pdf_hint)).map((c) => {
                                            // 
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell  >

                                                        {c.pdf_hint}
                                                    </TableCell>
                                                    <TableCell  >
                                                        <IconButton

                                                            style={{ marginLeft: "-10px" }}
                                                        >
                                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                                            <img src={Pdf} height={24} width={24} />
                                                        </IconButton>{c.eng_pdf_name}</TableCell>

                                                    {/* <TableCell  >
                                                        <IconButton

                                                            style={{ marginLeft: "-10px" }}
                                                        >
                                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                                    {/* <img src={Pdf} height={24} width={24} />
                                                        </IconButton>{c.hnd_pdf_name}</TableCell> */}



                                                    <TableCell style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit: true,
                                                                        pdf_id: c._id,
                                                                        eng_pdf_name: c.eng_pdf_name,
                                                                        // hnd_pdf_name: c.hnd_pdf_name,
                                                                        // old_hindi_url: c.hindi_url,
                                                                        old_english_url: c.english_url,
                                                                        access: c.access,
                                                                        pdf_hint: c.pdf_hint
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                <img src={Edit} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        pdf_id: c._id,
                                                                        pdf_name: c.pdf_name,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>

                                </Table>
                                <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                                    <Pagination
                                        count={parseInt(admin.pdf_length / this.state.rowsPerPage) + 1}
                                        siblingCount={0}
                                        size="small"
                                        onChange={handleChangePage}
                                    />
                                </div>
                            </TableContainer>
                        </CardContent>
                    </Box>



                    <Dialog
                        open={this.state.add}
                    // style={{ height: "600px" }}
                    >
                        <Typography style={{ color: "#000", padding: "20px 0px 0px 20px" }}>Add Pdf</Typography>
                        <Grid style={{ width: "500px" }}>
                            <Grid>
                                <div style={{ margin: "20px" }}>


                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter Pdf name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        // style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ pdf_name: e.target.value }) }}
                                    />
                                    {/* <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter Hindi Pdf name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "15px" }}
                                        onChange={(e) => { this.setState({ hindi_pdf_name: e.target.value }) }}
                                    /> */}
                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter Pdf Hint'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "10px" }}
                                        onChange={(e) => { this.setState({ pdf_hint: e.target.value }) }}
                                    />
                                    <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Pdf</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {this.state.english_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Upload
                                                <input hidden type="file" multiple
                                                    accept="application/pdf"
                                                    onChange={(e) => { this.setState({ english_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.english_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Pdf (Hin)</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {this.state.hindi_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Upload
                                                <input hidden type="file" multiple
                                                    accept="pdf/mp4,pdf/x-m4v,pdf/*"
                                                    onChange={(e) => { this.setState({ hindi_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.hindi_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid> */}

                                    <Grid container style={{ marginTop: "20px", alignItems: "center" }}>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000" }}>Access</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {/* <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "-10px" }}>
                                                Access
                                            </Typography> */}
                                            {/* <Grid style={{ marginTop: "2%", width: 600 }}> */}
                                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Access'
                                                    select
                                                    // className={classes.root}
                                                    rows={1}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "8vh", width: "95%", }}
                                                    value={this.state.access}
                                                    onChange={(e) => {
                                                        this.setState({ access: e.target.value })
                                                    }}
                                                >
                                                    <MenuItem value={"Paid"}>{"Paid"}</MenuItem>
                                                    <MenuItem value={"Free"}>{"Free"}</MenuItem>

                                                </TextField>

                                            </div>
                                        </Grid>
                                    </Grid>

                                </div>


                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false, english_url: '', hindi_url: "", pdf_name: "", pdf_hint: "" });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "35px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        disabled={this.state.pdf_name === "" && this.state.pdf_hint === "" && this.state.english_url === ""}
                                        onClick={() => {
                                            this.props.add_eng_upload_pdf(this.state.pdf_name, this.state.pdf_hint, this.state.english_url, this.state.access, this.state.page, this.state.rowsPerPage);
                                            this.setState({ add: false, hindi_url: "", english_url: '', pdf_name: "", pdf_hint: "" });
                                            // 


                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "35px", marginTop: "20px", color: "white" }}
                                    >
                                        Add
                                    </Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>

                    <Dialog
                        open={this.state.edit}
                    >
                        <Typography style={{ color: "#000", padding: "20px 0px 0px 20px" }}>Edit Pdf</Typography>
                        <Grid style={{ width: "500px" }}>
                            <Grid>
                                <div style={{ margin: "20px" }}>


                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Edit Pdf name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        // style={{ height: "50px", width: "450px" }}
                                        value={this.state.eng_pdf_name}
                                        onChange={(e) => { this.setState({ eng_pdf_name: e.target.value }) }}
                                    />
                                    {/* <TextField
                                        required
                                        fullWidth
                                        placeholder='Edit Hindi Pdf name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "15px" }}
                                        value={this.state.hnd_pdf_name}
                                        onChange={(e) => { this.setState({ hnd_pdf_name: e.target.value })}}
                                    /> */}

                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter Pdf Hint'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "10px" }}
                                        value={this.state.pdf_hint !== undefined && this.state.pdf_hint}
                                        onChange={(e) => { this.setState({ pdf_hint: e.target.value }) }}
                                    />


                                    <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Pdf </Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {(this.state.old_english_url !== "" && this.state.english_url === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                {this.state.old_english_url !== "" ? "Change" : "Upload"}
                                                <input hidden type="file" multiple
                                                    accept="pdf/mp4,pdf/x-m4v,pdf/*"
                                                    onChange={(e) => { this.setState({ english_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.english_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Pdf (Hin)</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {(this.state.old_hindi_url !== "" && this.state.hindi_url === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                {this.state.old_hindi_url !== "" ? "Change" : "Upload"}
                                                <input hidden type="file" multiple
                                                    accept="pdf/mp4,pdf/x-m4v,pdf/*"
                                                    onChange={(e) => { this.setState({ hindi_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.hindi_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid> */}

                                    <Grid container style={{ marginTop: "20px", alignItems: "center" }}>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000" }}>Access</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="select category"
                                                    select
                                                    value={this.state.access}
                                                    onChange={(e) => {
                                                        this.setState({ access: e.target.value });

                                                    }}
                                                >
                                                    <MenuItem value={"Access"}>{"Select Access"}</MenuItem>
                                                    {/* {this.state.details.map((e) => ( */}
                                                    <MenuItem value={"Paid"}>{"Paid"}</MenuItem>
                                                    <MenuItem value={"Free"}>{"Free"}</MenuItem>
                                                    {/* ))} */}

                                                </Select>

                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                </div>


                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false, english_url: '', });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "35px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        // disabled={this.state.pdf_name === "" && (this.state.hindi_url === "" || this.state.english_url === "")}
                                        onClick={() => {
                                            this.props.edit_eng_upload_pdf(this.state.pdf_id, this.state.eng_pdf_name, this.state.pdf_hint, this.state.old_english_url, this.state.english_url, this.state.access, this.state.page, this.state.rowsPerPage);
                                            this.setState({ edit: false, english_url: '', });
                                            // 


                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "35px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>

                        </Grid>
                    </Dialog>

                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the pdf <b>"{this.state.pdf_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => { this.props.deletePdf(this.state.pdf_id, this.state.page, this.state.rowsPerPage); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}