import React, { Component } from "react";
import { connect } from "react-redux";
import Exams from "../component/exams";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createExam,
  updateExam,
  deleteExam,
  viewAllExams,
  viewAllCategory
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Exams {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createExam: (exam_name, category_id) => {
      dispatch(createExam(exam_name, category_id))
    },
    updateExam: (exam_id, exam_name, category_id) => {
      dispatch(updateExam(exam_id, exam_name, category_id))
    },
    deleteExam: (exam_id) => {
      dispatch(deleteExam(exam_id))
    },
    viewAllExams: () => {
      dispatch(viewAllExams())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);