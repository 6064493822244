import React, { Component } from "react";
import { connect } from "react-redux";
import Video from "../component/video";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  add_eng_upload_Video,
  updateVideo,
  edit_eng_upload_Video,
  Delete_Firebase_Video,
  deleteVideo,
  viewAllVideo,
  searchvideos
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Video {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_eng_upload_Video: (video_name,video_hint, english_url, access) => {
      dispatch(add_eng_upload_Video(video_name,video_hint, english_url, access))
    },
    edit_eng_upload_Video: (video_id, video_name,video_hint, old_english_url, english_url, access) => {
      dispatch(edit_eng_upload_Video(video_id, video_name,video_hint, old_english_url, english_url, access))
    },
    updateVideo: (video_id, video_name) => {
      dispatch(updateVideo(video_id, video_name))
    },
    deleteVideo: (video_id) => {
      dispatch(deleteVideo(video_id))
    },
    viewAllVideo: () => {
      dispatch(viewAllVideo())
    },
    searchvideos: (all_video, e) => {
      dispatch(searchvideos(all_video, e))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);