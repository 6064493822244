import { Grid, Box, Button, Typography, IconButton, TextField, Dialog, MenuItem } from '@mui/material'
import React, { Component } from 'react'
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import Edit from '../images/pencil.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Loader from '../../../common/loader/containers/loader_cont';
// import { Fullscreen } from '@material-ui/icons';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import SearchIcon from "@mui/icons-material/Search";
// import CheckIcon from '@mui/icons-material/Check';
import { styled, alpha } from "@mui/material/styles";
import Checkbox from '@mui/material/Checkbox';
import InputBase from "@mui/material/InputBase"
import { useParams } from "react-router-dom";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00f" // Set the hover border color
        }
    }
});

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));
class addquiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delete: false,
            quiz_name: "",
            quiz_title: "",
            quiz_desc: "",
            add_qustion_url: "",
            question_url: "",
            new_option1_url: "",
            new_option2_url: "",
            new_option3_url: "",
            new_option4_url: "",
            new_option5_url: "",
            new_ans_url: "",
            question_text: "",
            quastion_desc: "",
            opation1: "",
            option1_url: "",
            option1_desc: "",
            opation2: "",
            option2_url: "",
            option2_desc: "",
            opation3: "",
            option3_url: "",
            option3_desc: "",
            opation4: "",

            option4_url: "",
            option4_desc: "",
            opation5: "",
            option5_url: "",
            option5_desc: "",
            answer: "",
            answer_url: "",
            answer_desc: "",
            showWarning: false,
            quiz: false,
            questions: false,
            subjectmodal: false,
            question_id: "",
            question_desc: "",
            question_img: "",
            option4_img: "",
            option5_img: "",
            option3_img: "",
            option2_img: "",
            option1_img: "",
            correct_ans_img: "",
            option1: "1",
            option2: "2",
            option3: "3",
            option4: "4",
            option5: "5",
            image: false,
            option1img: false,
            option2img: false,
            option3img: false,
            option4img: false,
            option5img: false,
            ansimg: false,
            quiz_marks: "",
            quiz_cut_marks: this.props.admin.all_quiz_by_id.cut_marks,
            quiz_time: "",
            Vid: 0,
            Qid: 0,
            a: "",
            b: "",
            c: "",
            d: "",
            e: "",
            f: "",
            addQuizQuestion: false,
            Deleteid: null,
            subject: "",
            subject_id: "",
            // subjectChecked: [],
            // subject_filtered: [],
            subjectid: '',
            bgColor: "",
            rightAnswers: [
                {
                    option: "1"
                },
                {
                    option: "2"
                },
                {
                    option: "3"
                },
                {
                    option: "4"
                },
                {
                    option: "5"
                },
            ],


        }
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorChange0 = this.handleEditorChange0.bind(this);
        this.handleEditorChange1 = this.handleEditorChange1.bind(this);
        this.handleEditorChange2 = this.handleEditorChange2.bind(this);
        this.handleEditorChange3 = this.handleEditorChange3.bind(this);
        this.handleEditorChange4 = this.handleEditorChange4.bind(this);
        this.handleEditorChange5 = this.handleEditorChange5.bind(this);
    }



    componentDidMount() {
        this.props.viewalldatabyQuizId(this.props.params.quiz_id);
        this.props.viewAllSubject()
        console.log(this.props.admin.all_quastion_by_id);
        // console.log("this.props.params.quiz_id", this.props.params.quiz_id);
        // console.log("this.props.params.quiz_id", this.props.admin.all_quiz_by_id.subject);



        //     console.log("ffff", this.state.subject[0]._id);
        //    this.setState({ subjectid: this.state.subject[0]._id });
        //     this.props.subject_id(this.state.subject[0]._id);

        this.props.resetEditedquestionimage();
        this.props.resetEditedValueopation1();
        this.props.resetEditedValueopation2();
        this.props.resetEditedValueopation3();
        this.props.resetEditedValueopation4();
        this.props.resetEditedValueopation5();
        this.props.resetEditedValueanswer();
        // }

    }

    // handleDeleteSub = (id, index) => {
    //     
    //     this.setState(prevState => {
    //         const newSubject = [...prevState.subject];
    //         newSubject.splice(index, 1);

    //         // Assuming you want to update the state with the new subject array
    //         return { subject: newSubject };
    //     });
    // };

    // handleDeleteSub = (id) => {

    //     let updated = this.props.admin.all_quiz_by_id.subject.filter(i => i._id !== id)

    //     this.setState(() => this.props.admin.all_quiz_by_id.subject = updated)

    // }

    // UnCheckedSub = (all_section, itemToUncheck) => {
    //     this.setState((prevState) => ({
    //         subjectChecked: prevState.subjectChecked.filter((item) => item !== itemToUncheck),
    //         subject_filtered: all_section,
    //         // ... other state updates
    //     }));
    // };
    handleEditorChange4(html) {
        this.setState({ option4_desc: html });
    }
    handleEditorChange5(html) {
        this.setState({ option5_desc: html });
    }
    handleEditorChange3(html) {
        this.setState({ option3_desc: html });
    }
    handleEditorChange2(html) {
        this.setState({ option2_desc: html });
    }
    handleEditorChange1(html) {
        this.setState({ option1_desc: html });
    }
    handleEditorChange0(html) {
        this.setState({ question_text: html });

    }
    handleEditorChange(html) {
        this.setState({ correct_ans_desc: html });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid>
                <>
                    <Box className='categoryname'>
                        <center style={{ display: "flex" }}>
                            <Link to="/quizes" style={{ textDecoration: "none" }}>
                                <IconButton
                                    // style={{ marginLeft: "18px" }}
                                    onClick={() => {
                                        // this.props.setID(c._id)
                                        this.props.admin.all_quastion_by_id.push([])
                                    }}
                                >
                                    <ArrowBackIcon style={{ color: "white" }} />
                                </IconButton>
                            </Link>
                            <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px" }}>Edit Quiz</Typography>


                            <Button style={{ color: "white ", textAlign: 'center', }}></Button>
                        </center>
                    </Box>
                    <Grid className='boxdesign' style={{ border: "1px solid #888", height: 500, width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                Add Quiz title & Hint
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        quiz: true,
                                        quiz_name: this.props.admin.all_quiz_by_id.quiz_name,
                                        quiz_desc: this.props.admin.all_quiz_by_id.quiz_desc,
                                        quiz_time: this.props.admin.all_quiz_by_id.time,
                                        quiz_marks: this.props.admin.all_quiz_by_id.total_marks,
                                        quiz_cut_marks: this.props.admin.all_quiz_by_id.cut_marks,
                                        subject: this.props.admin.all_quiz_by_id.subject

                                    });
                                }}
                            >
                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                <img alt='' src={Edit} height={24} width={24} />
                            </IconButton>
                        </Grid>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Title
                            {/* {this.props.admin.all_quiz_by_id.quiz_name} */}
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {this.props.admin.all_quiz_by_id?.quiz_name}
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Hint
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Title */}
                            {this.props.admin.all_quiz_by_id?.quiz_desc}
                        </Typography>
                        <div style={{ direction: "row", display: "flex" }}>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                Quiz Time:
                            </Typography>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                                {/* Quiz Time */}
                                {this.props.admin.all_quiz_by_id?.time}
                            </Typography>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                Quiz Marks:
                            </Typography>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                                {/* Quiz Marks */}
                                {this.props.admin.all_quiz_by_id?.total_marks}
                            </Typography>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                Total question:
                            </Typography>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                                {/* Quiz Cut Marks */}
                                {this.props.admin.all_quiz_by_id?.cut_marks}
                            </Typography>

                        </div>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Subject:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Cut Marks */}
                            {this.props.admin.all_quiz_by_id?.subject_name}
                        </Typography>



                    </Grid>
                    <Grid style={{ display: "flex", alignSelf: 'center', marginTop: '20px' }} className='boxdesign'>
                        <Typography onClick={() => {
                            this.setState({
                                // questions: true,
                                addQuizQuestion: true,
                            })
                        }} style={{ cursor: "pointer", borderRadius: "23px", backgroundColor: "#6257CD", color: "white", display: "inline-flex", padding: ".3em", fontFamily: 'Poppins' }}>
                            Add Question
                        </Typography>
                        <div style={{ border: '1px solid #6257CD', height: '25px', width: '25px', borderRadius: '50%', marginTop: '5px', marginLeft: '20px' }}>
                            <center>
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", }}>

                                    {this.props.admin.all_quiz_by_id?.questions?.length}
                                    {/* jsnddnnd */}
                                </Typography>
                            </center>
                        </div>
                    </Grid>



                    {/* </Grid> */}
                    <Grid style={{ display: "flex", flexDirection: "column" }}>
                        {this.props.admin.all_quastion_by_id?.map((c, index) => {

                            return (
                                <Grid>
                                    {/* {this.props.admin.subject_id === c.subject_id && */}
                                    <Grid className='boxdesign' style={{ border: "1px solid #888", height: "100%", width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                                        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                                {index + 1}
                                            </Typography>

                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            questions: true,
                                                            question_id: c._id,
                                                            question_text: c.question_text,
                                                            question_img: c.question_img,
                                                            question_desc: c.question_desc,
                                                            option4_img: c.option4_img,
                                                            option4_desc: c.option4_desc,
                                                            option4: c.option4,
                                                            option5_img: c.option5_img,
                                                            option5_desc: c.option5_desc,
                                                            option5: c.option5,
                                                            option3_img: c.option3_img,
                                                            option3_desc: c.option3_desc,
                                                            option3: c.option3,
                                                            option2_img: c.option2_img,
                                                            option2_desc: c.option2_desc,
                                                            option2: c.option2,
                                                            option1_img: c.option1_img,
                                                            option1_desc: c.option1_desc,
                                                            option1: c.option1,
                                                            correct_ans_img: c.correct_ans_img,
                                                            correct_ans_desc: c.correct_ans_desc,
                                                            correct_ans: c.correct_ans,
                                                        });
                                                        this.props.setQuestionID(c._id);
                                                    }}
                                                >
                                                    <img alt='' src={Edit} height={24} width={24} />
                                                </IconButton>
                                                < DeleteForeverOutlinedIcon onClick={() => this.props.delete_question_update_quiz(c._id, this.props.admin.quiz_id)} style={{ fontSize: 25, marginRight: 10, cursor: "pointer" }} />
                                            </div>

                                        </Grid>



                                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                            Question
                                        </Typography>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>

                                            {/* {c.question_text} */}
                                            <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.question_text }}></div>
                                        </Typography>

                                        {/* <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        questions: true,
                                                        question_id: c._id,
                                                        question_text: c.question_text,
                                                        question_img: c.question_img,
                                                        question_desc: c.question_desc,
                                                        option4_img: c.option4_img,
                                                        option4_desc: c.option4_desc,
                                                        option4: c.option4,
                                                        option3_img: c.option3_img,
                                                        option3_desc: c.option3_desc,
                                                        option3: c.option3,
                                                        option2_img: c.option2_img,
                                                        option2_desc: c.option2_desc,
                                                        option2: c.option2,
                                                        option1_img: c.option1_img,
                                                        option1_desc: c.option1_desc,
                                                        option1: c.option1,
                                                        correct_ans_img: c.correct_ans_img,
                                                        correct_ans_desc: c.correct_ans_desc,
                                                        correct_ans: c.correct_ans,
                                                    });
                                                    this.props.setQuestionID(c._id);
                                                }}
                                            >
                                                <img src={Edit} height={24} width={24} />
                                            </IconButton>
                                            < DeleteForeverOutlinedIcon onClick={() =>this.setState({  delete: true,Deleteid:c._id}) } style={{ fontSize: 25, marginRight: 10, cursor: "pointer" }} />
                                       </div> */}
                                        {c.question_img !== "" && <img alt='' src={c.question_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold " }}>
                                            Options

                                        </Typography>
                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option1}.
                                            </Typography>
                                            <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option1_desc }}></div>

                                        </div>
                                        {c.option1_img !== "" && c.option1_img !== null && <img alt='' src={c.option1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option2}.
                                            </Typography>
                                            <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option2_desc }}></div>
                                        </div>
                                        {c.option2_img !== "" && c.option2_img !== null && <img alt='' src={c.option2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option3}.
                                            </Typography>
                                            <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option3_desc }}></div>

                                        </div>
                                        {c.option3_img !== "" && c.option3_img !== null && <img alt='' src={c.option3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option4}.
                                            </Typography>
                                            <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option4_desc }}></div>
                                        </div>
                                        {c.option4_img !== "" && c.option4_img !== null && <img alt='' src={c.option4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                        <div style={{ display: "flex" }}>
                                            {c.option5_desc !== "" &&
                                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                    {c.option5}.
                                                </Typography>}
                                            <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option5_desc }}></div>
                                        </div>
                                        {c.option5_img !== "" && c.option5_img !== null && <img alt='' src={c.option5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                            Correct Option
                                        </Typography>

                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                            {c.correct_ans}
                                        </Typography>
                                        <Typography dangerouslySetInnerHTML={{ __html: c.correct_ans_desc }} style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                            {/* {c.correct_ans} */}
                                        </Typography>
                                        {/* <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.correct_ans_desc}}></div> */}

                                        {/* {c.correct_ans_img !== "" && c.correct_ans_img !== null && <img src={c.correct_ans_img} height={150} width={440} style={{ marginLeft: "20px" }} />} */}



                                    </Grid>
                                    {/* } */}
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Dialog
                        open={this.state.quiz}
                        style={{}}
                    >

                        <Grid className='boxnewdesign' style={{ minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                Add Quiz title & Description
                            </Typography>


                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", width: "90%", fontWeight: "500" }}>
                                Quiz Title
                            </Typography>
                            <TextField
                                required
                                placeholder='Enter quiz name'
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "450px", marginLeft: "11px" }}
                                value={this.state.quiz_name}
                                onChange={(e) => { this.setState({ quiz_name: e.target.value }) }}
                            />




                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", width: "90%", fontWeight: "500" }}>
                                Quiz Description
                            </Typography>

                            <TextField
                                required
                                placeholder='Enter quiz description'
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "450px", marginLeft: "11px" }}
                                value={this.state.quiz_desc}
                                onChange={(e) => { this.setState({ quiz_desc: e.target.value }) }}
                            />

                            <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                    Time In Minutes:
                                </Typography>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    className={classes.root}
                                    size="small"
                                    placeholder='Enter Time '
                                    type='number'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "15%", }}
                                    value={this.state.quiz_time}
                                    onChange={(e) => { this.setState({ quiz_time: e.target.value }) }}
                                />

                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                    Marks:
                                </Typography>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    className={classes.root}
                                    size="small"
                                    placeholder='Enter Marks '
                                    type='number'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "15%", }}
                                    value={this.state.quiz_marks}
                                    onChange={(e) => { this.setState({ quiz_marks: e.target.value }) }}
                                />
                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                    Total Questions:
                                </Typography>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    className={classes.root}
                                    size="small"
                                    placeholder='Enter - Marks '
                                    type='number'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "5vh", width: "15%", }}
                                    value={this.state.quiz_cut_marks}
                                    onChange={(e) => { this.setState({ quiz_cut_marks: e.target.value }) }}
                                />


                                {/* </div> */}
                            </div>
                            <div class="input-group mb-3" style={{ marginRight: "18%", }}>
                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                    Select Subject:
                                </Typography>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Select Subject'
                                    select
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.subject}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%", marginLeft: "30px" }}
                                    onChange={(e) => { this.setState({ subject: e.target.value }) }}
                                >
                                    {Array.isArray(this.props.admin.all_subject) && this.props.admin.all_subject.map((e) => {
                                        return (
                                            <MenuItem value={e._id}>{e.subject_name}</MenuItem>
                                        )
                                    })
                                    }
                                </TextField>

                            </div>


                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ quiz: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc, this.state.quiz_time, this.state.quiz_marks, this.state.quiz_cut_marks, this.state.subject)
                                        this.setState({ quiz: false, quiz_id: "", quiz_name: "", quiz_desc: "", quiz_cut_marks: "", quiz_marks: "", quiz_time: "" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>


                    <Dialog
                        fullScreen={true}
                        open={this.state.questions}
                        onClose={!this.state.questions}


                        style={{ height: "100%" }}
                    >

                        <Grid style={{ backgroundColor: "#fff", height: "860px", minWidth: "580px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", flexDirection: "row", display: "flex", marginBottom: "40px" }}>

                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "15px" }}>
                                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold" }}>
                                        Question
                                    </Typography>
                                    <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                        value={this.state.question_text}
                                        onChange={this.handleEditorChange0}
                                        modules={addquiz.modules}
                                        formats={addquiz.formats}
                                    />
                                </div>
                                {this.state.question_url !== "" && this.props.admin.new_question_image !== "" && this.state.a !== "" && <img alt='' src={this.props.admin.new_question_image} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                {this.state.question_img !== "" && this.state.a === "" &&
                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <img src={this.state.question_img} alt='' height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />


                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    image: true,

                                                });
                                            }}
                                            style={{ marginTop: "24%", height: "0%" }}
                                        >
                                            <img alt='' src={Edit} height={24} width={24} />
                                        </IconButton>
                                    </Grid>}

                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "20px" }}>
                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 25, }}>
                                        Options..
                                    </Typography>
                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "10px" }}>

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                        Option:{this.state.option1}
                                    </Typography>

                                    <Grid item xs={10}>
                                        {this.state.option1_desc !== '' &&
                                            <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                                value={this.state.option1_desc}
                                                onChange={this.handleEditorChange1}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />
                                        }
                                        {this.state.new_option1_url !== "" && this.props.new_opation1_img !== "" && <img alt='' src={this.props.admin.new_opation1_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option1_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option1_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option1img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>

                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "15px" }}>

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                        Option:{this.state.option2}
                                    </Typography>

                                    <Grid item xs={10}>
                                        {this.state.option2_desc !== '' &&
                                            <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                                value={this.state.option2_desc}
                                                onChange={this.handleEditorChange2}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />
                                        }
                                        {this.state.new_option2_url !== "" && this.props.new_option2_url !== "" && <img alt='' src={this.props.admin.new_option2_url} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option2_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option2_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option1img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>

                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "15px" }}>

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                        Option:{this.state.option3}
                                    </Typography>

                                    <Grid item xs={10}>
                                        {this.state.option3_desc !== '' &&
                                            <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                                value={this.state.option3_desc}
                                                onChange={this.handleEditorChange3}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />
                                        }
                                        {this.state.new_option3_url !== "" && this.props.new_opation3_img !== "" && <img alt='' src={this.props.admin.new_opation3_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option3_img !== "" && this.state.d === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option3_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option3img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>


                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "10px" }}>

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                        Option:{this.state.option4}
                                    </Typography>

                                    <Grid item xs={10}>
                                        {this.state.option4_desc !== '' &&
                                            <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                                value={this.state.option4_desc}
                                                onChange={this.handleEditorChange4}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />}
                                        {this.state.new_option4_url !== "" && this.props.new_opation4_img !== "" && <img src={this.props.admin.new_opation4_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option4_img !== "" && this.state.e === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option4_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option4img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>

                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "5px", marginTop: "10px" }}>
                                    {this.state.option5_desc !== '' &&
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option5}
                                        </Typography>
                                    }
                                    <Grid item xs={10}>
                                        {this.state.option5_desc !== '' &&
                                            <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                                value={this.state.option5_desc}
                                                onChange={this.handleEditorChange5}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />}
                                        {this.state.new_option5_url !== "" && this.props.new_opation5_img !== "" && <img src={this.props.admin.new_opation5_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option5_img !== "" && this.state.e === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option5_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option5img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>

                                </div>






                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                        Correct Option
                                    </Typography>

                                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                        <select
                                            value={this.state.correct_ans}
                                            style={{
                                                background: "white",
                                                height: '50px',
                                                width: "85%",
                                                border: "1px solid #888",
                                                fontSize: 16,
                                                borderRadius: 5,
                                                padding: 7,
                                                color: 'gray'
                                            }}
                                            select

                                            onChange={(e) => {
                                                this.setState({ correct_ans: e.target.value });

                                            }}
                                        >
                                            <option value="" >Select Right Answer</option>
                                            {this.state.rightAnswers.map((e) => (
                                                <option value={e.option}>{e.option}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                {this.state.new_ans_url !== "" && this.props.new_answer_img !== "" && <img src={this.props.admin.new_answer_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                {this.state.correct_ans_img !== "" && this.state.f === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.correct_ans_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                    <IconButton
                                        onClick={() => {
                                            this.setState({
                                                ansimg: true,

                                            });
                                        }}
                                        style={{ marginTop: "24%", height: "0%" }}
                                    >
                                        <img src={Edit} height={24} width={24} />
                                    </IconButton></Grid>}
                                {this.state.correct_ans_desc !== '' &&
                                    <ReactQuill style={{ width: "650px", marginTop: 10 }}
                                        value={this.state.correct_ans_desc}
                                        onChange={this.handleEditorChange}
                                        modules={addquiz.modules}
                                        formats={addquiz.formats}
                                    />}



                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            // this.setState({ questions: false });
                                            this.setState({ questions: false, question_id: "", question_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option4: "4", option4: "5", option3_desc: "", option3: "3", option2_desc: "", option2: "2", option1: "1", option1_desc: "" })

                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "10px", marginTop: "90px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button

                                        onClick={() => {
                                            //  this.props.updateQuestion
                                            this.props.updateQuestion(
                                                this.state.question_id,
                                                this.state.question_text,
                                                this.state.question_desc,
                                                this.props.admin.new_question_image === "" ?
                                                    this.state.question_img :
                                                    this.props.admin.new_question_image,
                                                this.state.option1,
                                                this.props.admin.new_opation1_img === "" ?
                                                    this.state.option1_img :
                                                    this.props.admin.new_opation1_img,
                                                this.state.option1_desc,
                                                this.state.option2,
                                                this.props.admin.new_opation2_img === "" ?
                                                    this.state.option2_img :
                                                    this.props.admin.new_opation2_img,
                                                this.state.option2_desc,
                                                this.state.option3,
                                                this.props.admin.new_opation3_img === "" ?
                                                    this.state.option3_img :
                                                    this.props.admin.new_opation3_img,
                                                this.state.option3_desc,
                                                this.state.option4,
                                                this.props.admin.new_opation4_img === "" ?
                                                    this.state.option4_img :
                                                    this.props.admin.new_opation4_img,
                                                this.state.option4_desc,
                                                this.state.option5,
                                                this.props.admin.new_opation5_img === "" ?
                                                    this.state.option5_img :
                                                    this.props.admin.new_opation5_img,
                                                this.state.option5_desc,
                                                this.state.correct_ans,
                                                this.props.admin.new_answer_img === "" ?
                                                    this.state.correct_ans_img :
                                                    this.props.admin.new_answer_img,
                                                this.state.correct_ans_desc,
                                                this.props.admin.quiz_id,

                                            )
                                            this.setState({ questions: false, question_id: "", question_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "" })

                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "10px", marginTop: "90px", color: "white", backgroundColor: "#C0392B" }}>Edit</Button>
                                </center>

                            </Grid>

                        </Grid >

                    </Dialog >
                    {/* ----------------------------------------------------------addQuizQuestion---------------------------------- */}

                    < Dialog
                        open={this.state.addQuizQuestion}
                        fullScreen={true}
                    >


                        <Grid style={{ backgroundColor: "#fff", height: "860px", minWidth: "580px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", flexDirection: "row", display: "flex", marginBottom: "40px" }}>

                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>
                                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Question
                                    </Typography>
                                    <ReactQuill style={{ width: "550px", marginTop: 10 }}
                                        value={this.state.question_text}
                                        onChange={this.handleEditorChange0}
                                        modules={addquiz.modules}
                                        formats={addquiz.formats}
                                    />
                                </div>
                                {this.state.question_url !== "" && this.props.admin.new_question_image !== "" && this.state.a !== "" && <img src={this.props.admin.new_question_image} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                {this.state.question_img !== "" && this.state.a === "" &&
                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <img src={this.state.question_img} alt='' height={150} width={200} style={{ marginLeft: "20px", marginTop: "25%" }} />


                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    image: true,

                                                });
                                            }}
                                            style={{ marginTop: "24%", height: "0%" }}
                                        >
                                            <img src={Edit} height={24} width={24} />
                                        </IconButton>
                                    </Grid>}

                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 25, }}>
                                        Options..
                                    </Typography>
                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "10px" }}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                                Option:{this.state.option1}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>

                                            <ReactQuill style={{ width: "450px", margin: 15 }}
                                                value={this.state.option1_desc}
                                                onChange={this.handleEditorChange1}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />

                                            {this.state.new_option1_url !== "" && this.props.new_opation1_img !== "" && <img src={this.props.admin.new_opation1_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                            {this.state.option1_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option1_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            option1img: true,

                                                        });
                                                    }}
                                                    style={{ marginTop: "24%", height: "0%" }}
                                                >
                                                    <img src={Edit} height={24} width={24} />
                                                </IconButton></Grid>}
                                        </Grid>
                                    </Grid>
                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                                Option:{this.state.option2}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>

                                            <ReactQuill style={{ width: "450px", margin: 15 }}
                                                value={this.state.option2_desc}

                                                onChange={this.handleEditorChange2}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />

                                            {this.state.new_option2_url !== "" && this.props.new_option2_url !== "" && <img src={this.props.admin.new_option2_url} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                            {this.state.option2_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option2_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            option1img: true,

                                                        });
                                                    }}
                                                    style={{ marginTop: "24%", height: "0%" }}
                                                >
                                                    <img src={Edit} height={24} width={24} />
                                                </IconButton></Grid>}
                                        </Grid>
                                    </Grid>
                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                                Option:{this.state.option3}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>

                                            <ReactQuill style={{ width: "450px", margin: 15 }}
                                                value={this.state.option3_desc}
                                                onChange={this.handleEditorChange3}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />

                                            {this.state.new_option3_url !== "" && this.props.new_opation3_img !== "" && <img src={this.props.admin.new_opation3_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                            {this.state.option3_img !== "" && this.state.d === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option3_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            option3img: true,

                                                        });
                                                    }}
                                                    style={{ marginTop: "24%", height: "0%" }}
                                                >
                                                    <img src={Edit} height={24} width={24} />
                                                </IconButton></Grid>}
                                        </Grid>
                                    </Grid>

                                </div>

                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                                Option:{this.state.option4}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>

                                            <ReactQuill style={{ width: "450px", margin: 15 }}
                                                value={this.state.option4_desc}
                                                onChange={this.handleEditorChange4}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />
                                            {this.state.new_option4_url !== "" && this.props.new_opation4_img !== "" && <img src={this.props.admin.new_opation4_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                            {this.state.option4_img !== "" && this.state.e === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option4_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            option4img: true,

                                                        });
                                                    }}
                                                    style={{ marginTop: "24%", height: "0%" }}
                                                >
                                                    <img src={Edit} height={24} width={24} />
                                                </IconButton></Grid>}
                                        </Grid>
                                        <Grid item xs={2}>

                                            <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                                Option:{this.state.option5}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>

                                            <ReactQuill style={{ width: "450px", margin: 15 }}
                                                value={this.state.option5_desc}
                                                onChange={this.handleEditorChange5}
                                                modules={addquiz.modules}
                                                formats={addquiz.formats}
                                            />
                                            {this.state.new_option5_url !== "" && this.props.new_opation5_img !== "" && <img src={this.props.admin.new_opation5_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                            {this.state.option5_img !== "" && this.state.e === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option5_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            option5img: true,

                                                        });
                                                    }}
                                                    style={{ marginTop: "24%", height: "0%" }}
                                                >
                                                    <img src={Edit} height={24} width={24} />
                                                </IconButton></Grid>}
                                        </Grid>
                                    </Grid>
                                </div>






                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                        Correct Option
                                    </Typography>

                                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                        <select
                                            value={this.state.correct_ans}
                                            style={{
                                                background: "white",
                                                height: '50px',
                                                width: "50%",
                                                border: "1px solid #888",
                                                fontSize: 16,
                                                borderRadius: 5,
                                                padding: 7,
                                                color: 'gray'
                                            }}
                                            select

                                            onChange={(e) => {
                                                this.setState({ correct_ans: e.target.value });

                                            }}
                                        >
                                            <option value="" >Select Right Answer</option>
                                            {this.state.rightAnswers.map((e) => (
                                                <option value={e.option}>{e.option}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                {/* {this.state.new_ans_url !== "" && this.props.new_answer_img !== "" && <img src={this.props.admin.new_answer_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                            {this.state.correct_ans_img !== "" && this.state.f === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.correct_ans_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} /> */}

                                {/* <IconButton
                                    onClick={() => {
                                        this.setState({
                                            ansimg: true,

                                        });
                                    }}
                                    style={{ marginTop: "24%", height: "0%" }}
                                >
                                    <img src={Edit} height={24} width={24} />
                                </IconButton></Grid>} */}

                                <ReactQuill style={{ width: "550px", margin: 15 }}
                                    value={this.state.correct_ans_desc}
                                    onChange={this.handleEditorChange}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />



                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {

                                            this.setState({ addQuizQuestion: false, question_id: "", question_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "" })

                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "10px", marginTop: "90px", borderColor: "#6257CD" }}>Close</Button>

                                    <Button

                                        onClick={() => {
                                            // 

                                            this.props.addUpdateQuizQuestion(
                                                // // console.log(
                                                // this.state.question_id,
                                                this.state.question_text,
                                                this.state.question_desc,
                                                this.props.admin.new_question_image === "" ?
                                                    this.state.question_img :
                                                    this.props.admin.new_question_image,
                                                this.state.option1,
                                                this.props.admin.new_opation1_img === "" ?
                                                    this.state.option1_img :
                                                    this.props.admin.new_opation1_img,
                                                this.state.option1_desc,
                                                this.state.option2,
                                                this.props.admin.new_opation2_img === "" ?
                                                    this.state.option2_img :
                                                    this.props.admin.new_opation2_img,
                                                this.state.option2_desc,
                                                this.state.option3,
                                                this.props.admin.new_opation3_img === "" ?
                                                    this.state.option3_img :
                                                    this.props.admin.new_opation3_img,
                                                this.state.option3_desc,
                                                this.state.option4,
                                                this.props.admin.new_opation4_img === "" ?
                                                    this.state.option4_img :
                                                    this.props.admin.new_opation4_img,
                                                this.state.option4_desc,
                                                this.state.option5,
                                                this.props.admin.new_opation5_img === "" ?
                                                    this.state.option5_img :
                                                    this.props.admin.new_opation5_img,
                                                this.state.option5_desc,
                                                this.state.correct_ans,
                                                this.props.admin.new_answer_img === "" ?
                                                    this.state.correct_ans_img :
                                                    this.props.admin.new_answer_img,
                                                this.state.correct_ans_desc,
                                                this.props.admin.quiz_id,
                                                this.props.admin.subject_id,

                                                // );
                                            )
                                            this.setState({ question_id: "", question_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "", option5_img: "", new_opation5_img: "", option5_desc: "" })

                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "10px", marginTop: "90px", color: "white", backgroundColor: "blue" }}>Save And Next</Button>

                                    <Button

                                        onClick={() => {
                                            // 
                                            if (this.state.question_text !== "<p><br></p>"
                                            ) {
                                                this.props.addUpdateQuizQuestion(

                                                    this.state.question_text,
                                                    this.state.question_desc,
                                                    this.props.admin.new_question_image === "" ?
                                                        this.state.question_img :
                                                        this.props.admin.new_question_image,
                                                    this.state.option1,
                                                    this.props.admin.new_opation1_img === "" ?
                                                        this.state.option1_img :
                                                        this.props.admin.new_opation1_img,
                                                    this.state.option1_desc,
                                                    this.state.option2,
                                                    this.props.admin.new_opation2_img === "" ?
                                                        this.state.option2_img :
                                                        this.props.admin.new_opation2_img,
                                                    this.state.option2_desc,
                                                    this.state.option3,
                                                    this.props.admin.new_opation3_img === "" ?
                                                        this.state.option3_img :
                                                        this.props.admin.new_opation3_img,
                                                    this.state.option3_desc,
                                                    this.state.option4,
                                                    this.props.admin.new_opation4_img === "" ?
                                                        this.state.option4_img :
                                                        this.props.admin.new_opation4_img,
                                                    this.state.option4_desc,
                                                    this.state.option5,
                                                    this.props.admin.new_opation5_img === "" ?
                                                        this.state.option5_img :
                                                        this.props.admin.new_opation5_img,
                                                    this.state.option5_desc,
                                                    this.state.correct_ans,
                                                    this.props.admin.new_answer_img === "" ?
                                                        this.state.correct_ans_img :
                                                        this.props.admin.new_answer_img,
                                                    this.state.correct_ans_desc,
                                                    this.props.admin.quiz_id,
                                                    this.props.admin.subject_id

                                                )
                                            }
                                            this.setState({ addQuizQuestion: false, question_id: "", question_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "", option4_img: "", new_opation4_img: "", option5_desc:"" })

                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "10px", marginTop: "90px", color: "white", backgroundColor: "#C0392B" }}>Save & Review</Button>
                                </center>

                            </Grid>

                        </Grid>

                    </Dialog >

                    {/* ----------------------------------------------------------------------------------------------------------- */}









                    < Dialog
                        open={this.state.image}
                        style={{ height: "500px", }
                        }
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.question_url !== "" && this.props.admin.new_question_image !== "" && <img src={this.props.admin.new_question_image} height={150} width={240} style={{ marginLeft: "20px" }} />}
                            {this.state.question_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"

                                    onChange={(e) => {
                                        this.setState({ question_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.question_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.question_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ question_url: "", a: "" });
                                        this.props.resetEditedquestionimage()
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear

                                </Button>}
                                <Button variant="outlined" component="label"
                                    onClick={() => {
                                        // this.setState({ ques: "1" });
                                        this.props.EditUploadQuestion(this.state.question_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>
                            </Grid>
                            <center>

                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ image: false, ques: "" });
                                        this.props.resetEditedquestionimage();

                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        this.setState({ image: false, a: "1", question_img: "" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog >

                    <Dialog
                        open={this.state.option1img}
                        style={{ height: "500px", }}
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.new_option1_url !== "" && this.props.admin.new_opation1_img !== "" && <img src={this.props.admin.new_opation1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.new_option1_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    onChange={(e) => {
                                        this.setState({ new_option1_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.new_option1_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.new_option1_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ new_option1_url: "", });
                                        this.props.resetEditedValueopation1()
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear

                                </Button>}
                                <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ opt1: "1", });
                                        this.props.EditUploadoption1(this.state.new_option1_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>
                            </Grid>
                            <center>

                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ option1img: false, opt1: "" });
                                        this.props.resetEditedValueopation1();

                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                        this.setState({ option1img: false, b: "1" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>
                    <Dialog
                        open={this.state.option2img}
                        style={{ height: "500px", }}
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.new_option2_url !== "" && this.props.admin.new_opation2_img !== "" && <img src={this.props.admin.new_opation2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.new_option2_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => {
                                        this.setState({ new_option2_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.new_option2_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                                </Button>}
                                {this.state.new_option2_url !== "" && <Button variant="outlined" component="label"
                                    // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                    onClick={() => {
                                        this.setState({ new_option2_url: "", });
                                        this.props.resetEditedValueopation2()
                                    }}

                                    // className='upload' 
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                                >
                                    Clear
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                    {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                                </Button>}
                                <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ opt2: "1", });
                                        this.props.EditUploadoption2(this.state.new_option2_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>
                            </Grid>
                            <center>

                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ option2img: false, opt2: "" });

                                        this.props.resetEditedValueopation2();

                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                        this.setState({ option2img: false, c: "1" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>
                    <Dialog
                        open={this.state.option3img}
                        style={{ height: "500px", }}
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.new_option3_url !== "" && this.props.admin.new_opation3_img !== "" && <img src={this.props.admin.new_opation3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.new_option3_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => {
                                        this.setState({ new_option3_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.new_option3_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                                </Button>}
                                {this.state.new_option3_url !== "" && <Button variant="outlined" component="label"
                                    // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                    onClick={() => {
                                        this.setState({ new_option3_url: "", });
                                        this.props.resetEditedValueopation3()
                                    }}

                                    // className='upload' 
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                                >
                                    Clear
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                    {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                                </Button>}
                                <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ opt3: "1", });
                                        this.props.EditUploadoption3(this.state.new_option3_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>
                            </Grid>
                            <center>

                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ option3img: false, opt3: "" });

                                        this.props.resetEditedValueopation3();

                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                        this.setState({ option3img: false, d: "1" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>
                    <Dialog
                        open={this.state.option4img}
                        style={{ height: "500px", }}
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.new_option4_url !== "" && this.props.admin.new_opation4_img !== "" && <img src={this.props.admin.new_opation4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.new_option4_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => {
                                        this.setState({ new_option4_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.new_option4_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                                </Button>}
                                {this.state.new_option4_url !== "" && <Button variant="outlined" component="label"
                                    // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                    onClick={() => {
                                        this.setState({ new_option4_url: "", });
                                        this.props.resetEditedValueopation4()
                                    }}

                                    // className='upload' 
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                                >
                                    Clear
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                    {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                                </Button>}
                                <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ opt4: "1", });
                                        this.props.EditUploadoption4(this.state.new_option4_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>
                            </Grid>
                            <center>

                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ option4img: false, opt4: "" });

                                        this.props.resetEditedValueopation4();
                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                        this.setState({ option4img: false, e: "1" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>

                    <Dialog
                        open={this.state.option5img}
                        style={{ height: "500px", }}
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.new_option5_url !== "" && this.props.admin.new_opation5_img !== "" && <img src={this.props.admin.new_opation5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.new_option5_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => {
                                        this.setState({ new_option5_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.new_option5_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                                </Button>}
                                {this.state.new_option5_url !== "" && <Button variant="outlined" component="label"
                                    // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                    onClick={() => {
                                        this.setState({ new_option4_url: "", });
                                        this.props.resetEditedValueopation5()
                                    }}

                                    // className='upload' 
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                                >
                                    Clear
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                    {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                                </Button>}
                                <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ opt4: "1", });
                                        this.props.EditUploadoption5(this.state.new_option4_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>
                            </Grid>
                            <center>

                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ option5img: false, opt5: "" });

                                        this.props.resetEditedValueopation5();
                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                        this.setState({ option5img: false, e: "1" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>



                    <Dialog
                        open={this.state.delete}
                    // style={{ width: "600px" }}
                    >
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold", color: "#C0392B" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", padding: " 1em 6em", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ fontWeight: "bold", color: "#141226", marginTop: "30px", marginLeft: "56px", textAlign: "justify", marginRight: "24px", }}>Are You sure ? </Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => {
                                            this.props.delete_question_update_quiz(this.state.Deleteid, this.props.admin.quiz_id);
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>



                    <Dialog
                        open={this.state.ansimg}
                        style={{ height: "500px", }}
                    >

                        <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                            {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                            {this.state.new_ans_url !== "" && this.props.admin.new_answer_img !== "" && <img src={this.props.admin.new_answer_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.new_ans_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => {
                                        this.setState({ new_ans_url: e.target.files[0] });


                                    }}

                                />
                            </Button>}

                            <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                                {this.state.new_ans_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.new_ans_url !== "" && <Button variant="outlined" component="label"
                                    // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                    onClick={() => {
                                        this.setState({ new_ans_url: "", });
                                        this.props.resetEditedValueanswer()
                                    }}

                                    // className='upload' 
                                    //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                                >
                                    Clear

                                    {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                                </Button>}
                                {this.state.new_ans_url !== "" && this.state.new_ans === "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.setState({ new_ans: "1", });
                                        this.props.EditUploadANSimg(this.state.new_ans_url)
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <center>

                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ ansimg: false, new_ans: "" });
                                        this.props.resetEditedValueanswer();
                                    }}
                                    style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                        this.setState({ ansimg: false, f: "1" })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                            </center>
                        </Grid>


                    </Dialog>


                    <Box className='saveandcnts'>

                        <Link to='/quizes'>
                            <Button style={{ backgroundColor: "#6257CD", color: "white ", textTransform: "capitalize", width: "100%" }}
                                onClick={() => {
                                    // this.props.updateQuizSubject(this.props.admin.quiz_id, this.state.subject)
                                    // this.setState({ subject: "" })
                                }}>Done</Button>
                        </Link>

                    </Box>




                    <Loader />
                </>
            </Grid >



        )
    }
}
addquiz.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
        [{ script: "sub" }, { script: "super" }],
    ],
};

addquiz.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
];

export default withParams(withStyles(styles)(addquiz));