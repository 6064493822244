import React, { Component } from 'react';
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography

} from "@mui/material";
import "../../admin/style/category.css";
import Paper from "@mui/material/Paper";

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import notificationdelete from "../../admin/images/trash.svg"
import Checkbox from '@mui/material/Checkbox';
// import graduate from "../../pages/admin/images/graduated (1).png";
import Edit from "../../admin/images/pencil.svg"
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import Delete from '../images/trash.svg';
import Loader from '../../../common/loader/containers/loader_cont';
export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notiedit: false,
            notificationdelete: false,
            notiadd: false,
            view: false,
            all_notification: [],

        }
    }

    componentDidMount() {
        this.props.viewexamNotification();
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        return (
            <>
                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "5%", marginRight: "15%", marginTop: "3%" }}>

                    <Button className='categorynames' style={{ color: "white ", textAlign: 'center', }}>Notifications</Button>
                    <Box style={{ marginRight: "1%" }}>
                        <center style={{ borderRadius: 0 }}>
                            <Link to="/Addnotifications" style={{ textDecoration: "none" }}>
                                <Button className='categorynames' style={{ color: "white ", textAlign: 'center', }}>
                                    <IconButton>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    Add Notifications
                                </Button>
                            </Link>
                        </center>
                    </Box>
                </Grid>

                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >

                                            <TableCell>Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(admin.all_notification) && admin.all_notification.sort((a, b) => a.not_name?.localeCompare(b.not_name)).map((item) => {
                                            return (
                                                <TableRow>
                                                    <TableCell> {item.not_name}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                    <a href="/EditNotifications">
                                                        <Tooltip title="Edit">
                                                            <Link to="/EditNotifications" style={{ textDecoration: "none" }}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        this.props.setNotificationId(item._id);
                                                                        this.props.setExamId(item.exam_id);
                                                                        this.props.setnot_name(item.not_name);
                                                                        this.props.setnot_desc(item.not_desc);
                                                                        this.props.setnot_issued_on(item.not_issued_on);
                                                                        this.props.setapplication_start_dt(item.application_start_dt);
                                                                        this.props.setlast_dt_to_apply(item.last_dt_to_apply);
                                                                        this.props.setld_online_application_time(item.ld_online_application_time);
                                                                        this.props.setld_offline_chalan(item.ld_offline_chalan);
                                                                        this.props.setld_pay_through_chalan(item.ld_pay_through_chalan);
                                                                        this.props.setld_offline_chalan_time(item.ld_offline_chalan_time);
                                                                        this.props.setapplication_correction_dt(item.application_correction_dt);
                                                                        this.props.setapplication_correction_time(item.application_correction_time);
                                                                        this.props.setcomputer_exam_dt(item.computer_exam_dt);
                                                                        this.props.setconducting_body(item.conducting_body);
                                                                        this.props.setexam_name(item.exam_name);
                                                                        this.props.setconducting_for(item.conducting_for);
                                                                        this.props.setpost_name(item.post_name);
                                                                        this.props.setappliction_date(item.application_date);
                                                                        this.props.setappliction_mode(item.appliction_mode);
                                                                        this.props.setvacency(item.vacency);
                                                                        this.props.setsalary(item.salary);
                                                                        this.props.setofficial_not_link(item.official_not_link);
                                                                        this.props.setofficial_link(item.official_link);
                                                                        this.props.setform_link(item.form_link);
                                                                    }}
                                                                    style={{ marginLeft: "-10px" }}
                                                                >
                                                                    {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                    <img src={Edit} alt='' height={24} width={24} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        </a>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        notificationdelete: true,
                                                                        not_id: item._id,
                                                                        not_name: item.not_name
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} alt='' height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Box>
                </Box>
{/* delete */}
                <Dialog
                    open={this.state.notificationdelete}
                    style={{ height: "600px" }}>
                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                    <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the notification <b>"{this.state.not_name}"</b> has been successfully notificationdeleted from the admin panel as per your request.</Typography>
                            <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                            <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                            <center>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ notificationdelete: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                <Button
                                    onClick={() => {
                                        this.props.deleteNotification(this.state.not_id);
                                        this.setState({ notificationdelete: false })
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes,Delete</Button>
                            </center>
                        </Grid>
                    </Grid>
                </Dialog><Loader/>

                <Loader/>
            </>
        )
    }
}
