import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography,
    DialogContent,
    Checkbox, FormControlLabel, FormGroup

} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default class Coupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // edit: false,
            delete: false,
            add: false,
            edit: false,
            banner_name: "",
            banner_url: "",
            age: "",
            Coupon_name: "",
            save_amount: "",
            coupon_code: "",
            course_duration: '',
            coupon_on: '',
            examysFirst: false,
        }
    }
    handleChange = (event) => {
        this.setState({ age: event.target.value });
    };
    componentDidMount() {
        this.props.viewAllCoupon();
    }
    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>
                            <Button style={{ color: "white ", textAlign: 'center', }}>Coupon</Button>
                        </center>
                    </Box>
                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>
                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Coupon
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Coupon Code</TableCell>
                                            <TableCell style={{ paddingLeft: "19%" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(this.props.admin.all_coupon) && this.props.admin.all_coupon.map((item, index) => {
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell  >
                                                        <IconButton
                                                            style={{ marginLeft: "-10px" }}
                                                        >
                                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                                            {/* <img src={Pdf} height={24} width={24} /> */}
                                                        </IconButton>{item.coupon_code}</TableCell>
                                                    <TableCell style={{ paddingLeft: "18%" }} >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit: true,
                                                                        coupon_id: item._id,
                                                                        course_duration: item.course_duration,
                                                                        coupon_code: item.coupon_code,
                                                                        save_amount: item.save_amount,
                                                                        coupon_on: item.coupon_on,
                                                                        examysFirst: item.examysFirst
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        coupon_id: item._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>
                    <Dialog
                        open={this.state.add}
                        style={{ padding: "1em" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Add Coupon</Typography>
                        <Grid style={{ backgroundColor: "#fff", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ margin: "20px", padding: "1em" }}>
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Cousre duration'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "100%" }}
                                        onChange={(e) => { this.setState({ course_duration: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Coupon Code'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "100%" }}
                                        onChange={(e) => { this.setState({ coupon_code: e.target.value }) }}
                                    />
                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Save Amount'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "100%" }}
                                        onChange={(e) => { this.setState({ save_amount: e.target.value }) }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Coupon on</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={(e) => { this.setState({ coupon_on: e.target.value }) }}
                                        >
                                            <MenuItem value={"- - - -"}>Coupon on</MenuItem>
                                            <MenuItem value={"Test Series"}>Test Series</MenuItem>
                                            <MenuItem value={"Package"}>Package</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Typography style={{ justifyContent: 'space-between', display: "flex", alignItems: 'center', marginTop: "10px" }}>
                                        Examys Frist
                                        <FormGroup>
                                            <FormControlLabel required control={<Checkbox onClick={() => { this.setState({ examysFirst: !this.state.examysFirst })}}/>}/>
                                        </FormGroup>
                                    </Typography>
                                </div>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false, banner_url: "" });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'
                                        disabled={(this.state.course_duration === '') || (this.state.coupon_code === '') || (this.state.save_amount === '') || (this.state.coupon_on === "")}
                                        onClick={() => {
                                            this.props.addCoupon(this.state.course_duration, this.state.coupon_code, this.state.save_amount, this.state.coupon_on, this.state.examysFirst);
                                            this.setState({ add: false, course_duration: '', coupon_code: '', save_amount: '', coupon_on: '', examysFirst: '' });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>
                        </Grid>
                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the Banner <b>"{this.state.banner_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        onClick={() => {
                                            this.props.deleteCoupon(this.state.coupon_id);
                                            this.setState({ delete: false ,coupon_id:""})
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>
                        </Grid>
                    </Dialog>
                    <Dialog
                        open={this.state.edit}
                        maxWidth="md"
                        style={{ height: "600px" }}
                        onClose={() => {
                            this.setState({ edit: false })
                        }}
                    >
                        <DialogTitle borderBottom={2}>
                            <Typography fontSize={20} fontWeight={500}>
                                edit
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid style={{ backgroundColor: "#fff", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                                <Grid>
                                    <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px", padding: "1em" }}>
                                        <TextField
                                            required
                                            // id="outlined-required"
                                            // label="Category Name"
                                            placeholder='Cousre duration'
                                            InputLabelProps={{ shrink: true, required: true }}
                                            style={{ height: "50px", width: "100%" }}
                                            onChange={(e) => { this.setState({ course_duration: e.target.value }) }}
                                            value={this.state.course_duration}
                                        />
                                        <TextField
                                            required
                                            // id="outlined-required"
                                            // label="Category Name"
                                            placeholder='Coupon Code'
                                            InputLabelProps={{ shrink: true, required: true }}
                                            style={{ height: "50px", width: "100%" }}
                                            onChange={(e) => { this.setState({ coupon_code: e.target.value }) }}
                                            value={this.state.coupon_code}
                                        />
                                        <TextField
                                            required
                                            // id="outlined-required"
                                            // label="Category Name"
                                            placeholder='Save Amount'
                                            InputLabelProps={{ shrink: true, required: true }}
                                            style={{ height: "50px", width: "100%" }}
                                            onChange={(e) => { this.setState({ save_amount: e.target.value }) }}
                                            value={this.state.save_amount}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Coupon on</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(e) => { this.setState({ coupon_on: e.target.value }) }}
                                                value={this.state.coupon_on}
                                            >
                                                <MenuItem value={"- - -"}>Coupon on</MenuItem>
                                                <MenuItem value={"Test Series"}>Test Series</MenuItem>
                                                <MenuItem value={"Package"}>Package</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* <Typography>Examys Frist <Checkbox {...label} /></Typography> */}
                                        <Typography style={{ justifyContent: 'space-between', display: "flex", alignItems: 'center', marginTop: "10px" }}>
                                            Examys Frist
                                            <FormGroup>
                                                <FormControlLabel required control={<Checkbox onClick={() => { this.setState({ examysFirst: !this.state.examysFirst }) }}
                                                checked={this.state.examysFirst}
                                                />} 
                                                />
                                            </FormGroup>
                                        </Typography>
                                    </div>
                                    <center>
                                        <Button
                                            // className='btns'
                                            variant="outlined"
                                            onClick={() => {
                                                this.setState({ edit: false, banner_url: "" });
                                            }}
                                            style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                        <Button
                                            className='btns'
                                            // disabled={this.state.banner_name === "" && this.state.banner_url === ""}
                                            onClick={() => {
                                                this.props.updateCoupon(this.state.coupon_id, this.state.course_duration, this.state.coupon_code, this.state.save_amount, this.state.coupon_on, this.state.examysFirst);
                                                this.setState({ edit: false,coupon_id:"",course_duration:"",coupon_code:"",save_amount:"",coupon_on:"",examysFirst:""});
                                            }}
                                            style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Update</Button>
                                    </center>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Box>
                <LoaderCon />
                {/* <Snackbar */}
                {/* // open={snackbar.response_received}
                // close_snack_bar={close_snack_bar}
                // message={snackbar.message}
                /> */}
            </Grid>
        )
    }
}