import React, { Component } from "react";
import { connect } from "react-redux";
import TextSeries from "../component/textseries";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createChapter,
    updateChapter,
    deleteChapter,
    viewAllChapter,
    viewAllTestSeries,
    deletepackage,
    updateTestSeries,
    deleteTestSeries,
    setTextseries,
    searchTestSeriess
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <TextSeries {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    setTextseries: (Payload) => {
      dispatch(setTextseries(Payload))
    },
    createChapter: (chapter_name) => {
      dispatch(createChapter(chapter_name))
    },
    updateChapter: (chapter_id,chapter_name) => {
      dispatch(updateChapter(chapter_id,chapter_name))
    },
    deleteChapter: (chapter_id) => {
      dispatch(deleteChapter(chapter_id))
    },
    viewAllChapter: () => {
      dispatch(viewAllChapter())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllTestSeries: () => {
      dispatch(viewAllTestSeries())
    },
    deletepackage: (package_id) => {
      dispatch(deletepackage(package_id))
    },
    updateTestSeries: (testSeries_id,student_special,) => {
      dispatch(updateTestSeries(testSeries_id,student_special))
    },


    deleteTestSeries: (testSeries_id) => {
      dispatch(deleteTestSeries(testSeries_id))
    },
    searchTestSeriess:(all_testseries, e) => {
      dispatch(searchTestSeriess(all_testseries, e))
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);