import React, { Component } from "react";
import { connect } from "react-redux";
import Quiz from "../component/TestQuiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createTquiz,
  updateTquiz,
  deleteTquiz,
  viewAllTquiz,
  setID,
  testsearchquizes,
  setUUID,
  setTquizID
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Quiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    setUUID: (id) => {
      dispatch(setUUID(id))
    },
    createTquiz: (quiz_name,set,questions,id) => {
      dispatch(createTquiz(quiz_name,set,questions,id))
    },
    updateTquiz: (quiz_id,quiz_name,set,questions) => {
      dispatch(updateTquiz(quiz_id,quiz_name,set,questions))
    },
    deleteTquiz: (quiz_id) => {
      dispatch(deleteTquiz(quiz_id))
    },
    viewAllTquiz: () => {
      dispatch(viewAllTquiz())
    },
    setID: (payload) => {
      dispatch(setID(payload))
    },
    setTquizID: (payload) => {
      dispatch(setTquizID(payload))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    testsearchquizes: (test_all_quiz, e) => {
      dispatch(testsearchquizes(test_all_quiz, e))
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);