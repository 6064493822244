import React, { Component } from "react";
import { connect } from "react-redux";
import Category from "../component/category";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createCategory,
  updateCategory,
  deleteCategory,
  viewAllCategory,
  add_upload_category_logo,
  updateUploadCategory
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Category {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createCategory: (category_name, category_logo) => {
      dispatch(createCategory(category_name, category_logo))
    },
    add_upload_category_logo: (category_name, category_logo, page_number, rowPerPage, searchQuery) => {
      dispatch(add_upload_category_logo(category_name, category_logo, page_number, rowPerPage, searchQuery))
    },
    updateUploadCategory: (category_id, category_name, category_logo, category_logo1, page_number, rowPerPage, searchQuery) => {
      dispatch(updateUploadCategory(category_id, category_name, category_logo, category_logo1, page_number, rowPerPage, searchQuery))
    },
    updateCategory: (category_id, category_name) => {
      dispatch(updateCategory(category_id, category_name))
    },
    deleteCategory: (category_id, page_number, rowPerPage, searchQuery) => {
      dispatch(deleteCategory(category_id, page_number, rowPerPage, searchQuery))
    },
    viewAllCategory: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllCategory(page_number, rowPerPage, searchQuery))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);