import React, { Component } from 'react'
import { Grid, Button, MenuItem, Select, InputLabel, FormControl, TableCell, } from '@mui/material'
import Textarea from '@mui/joy/Textarea';
import "./addpdf.css";
import InputBase from "@mui/material/InputBase";
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Pdf from '../images/pdf.svg';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Loader from '../../../common/loader/containers/loader_cont';
import Pagination from '@mui/material/Pagination';
// import Fil from "../../../assets/img/fil.png";
// import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper";
import { debounce } from 'lodash';
import {
    IconButton,
    Typography,
    Box

} from "@mui/material";


const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));


export default class AddPreviousyear extends Component {

    constructor(props) {
        super(props);
        this.state = {

            mini_pack: "",
            pdfmodal: false,
            Trendmodal: false,
            subjectmodal: false,
            languagemodal: false,
            category_name: "",
            pdf_count: 0,
            countpdf: -1,
            lesscount: -1,
            pdf_content: [],
            chap: "",
            count: -1,
            pdf_name: [],
            pdfs: [],
            filteredData: [],
            // pdfmodal: "",
            countcat: 0,
            countex: 0,
            countsta: 0,
            countsub: 0,
            countmin: 0,
            counttest: 0,
            counttesttype: 0,
            counttestsub: 0,
            counttestquiz: 0,
            catcount: -1,
            excount: -1,
            stacount: -1,
            subcount: -1,
            minicount: -1,
            testcount: -1,
            testtypecount: -1,
            testsubcount: -1,
            testquizcount: -1,
            exam_ai_name: this.props.admin.examAiDetails.examAI_name,
            category: this.props.admin.examAiDetails.category,
            exam_id: this.props.admin.examAiDetails.exam_id,
            stage: this.props.admin.examAiDetails.stage,
            language_id: this.props.admin.examAiDetails.language_id,
            all: this.props.admin.examAiDetails.examysai_content,
            Lid: 0,
            Vid: 0,
            Pid: 0,
            Qid: 0,
            Lang: 0,
            ex: "",
            sub: "",
            tes: "",
            subjectChecked: [],
            pdfChecked: [],
            pdf_filtered: [],
            trends_filtered: [],
            trendChecked: [],
            subject_filtered: [],
            languageChecked: [],
            language_filtered: [],
            page: 0,
            setPage: 0,
            rowsPerPage: 30,
            searchTerm: '',
            searchpdf: '',

        }
        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
        this.debouncedSearchpdf = debounce(this.debouncedSearchpdf.bind(this), 500);
    }





    debouncedSearch(value) {
        // this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, value)
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, value)
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };
    debouncedSearchpdf(value) {
        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, value)
        // this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, value)
    }

    handleSearchChangepdf = (event) => {
        const value = event.target.value;
        this.setState({ searchpdf: value });
        this.debouncedSearch(value);
    };

    UnCheckedpdf = (all_pdf, itemToUncheck) => {
        this.setState((prevState) => ({
            pdfChecked: prevState.pdfChecked.filter((item) => item !== itemToUncheck),
            pdf_filtered: all_pdf,
            // ... other state updates
        }));
    };
    UnCheckedlanguage = (all_language, itemToUncheck) => {
        this.setState((prevState) => ({
            languageChecked: prevState.languageChecked.filter((item) => item !== itemToUncheck),
            language_filtered: all_language,
            // ... other state updates
        }));
    };
    UnCheckedtrends = (view_all_trends, itemToUncheck) => {
        this.setState((prevState) => ({
            trendChecked: prevState.trendChecked.filter((item) => item !== itemToUncheck),
            trends_filtered: view_all_trends,
            // ... other state updates
        }));
    };
    UnCheckedSubject = (all_subject, itemToUncheck) => {
        this.setState((prevState) => ({
            subjectChecked: prevState.subjectChecked.filter((item) => item !== itemToUncheck),
            subject_filtered: all_subject,
            // ... other state updates
        }));
    };

    // handleDeleteL = (data, id) => {

    //     let updated = data.filter(i => i._id !== id)

    //     this.setState(() => this.state.all.language = updated)

    // }
    handleDelete = (id) => {

        let updated = this.state.all.trends.filter(i => i._id !== id)

        this.setState(() => this.state.all.trends = updated)

    }

    handleDeleteL = (Lid, Vid, id) => {

        this.setState(prevState => {

            const newState = { ...prevState };


            const newArr = [...newState.all.trends[Lid].subject[Vid].language];

            newArr.splice(id, 1);


            newState.all.trends[Lid].subject[Vid].language = newArr;


            return newState;
        });

    }
    handleDeleteSub = (Lid, id) => {

        this.setState(prevState => {

            const newState = { ...prevState };


            const newArr = [...newState.all.trends[Lid].subject];

            newArr.splice(id, 1);


            newState.all.trends[Lid].subject = newArr;


            return newState;
        });

    }
    handleDeletePdf = (Lid, Vid, Lang, id) => {

        this.setState(prevState => {

            const newState = { ...prevState };


            const newArr = [...newState.all.trends[Lid].subject[Vid].language[Lang].pdf];

            newArr.splice(id, 1);


            newState.all.trends[Lid].subject[Vid].language[Lang].pdf = newArr;


            return newState;
        });

    }


    componentDidMount() {
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllCategory();
        this.props.viewAllTrends();
        this.props.viewAllLanguage();
        this.props.viewAllCategoryExam(this.state.category)



    }
    filterData = () => {
        const { admin } = this.props;
        const filteredArray = admin.all_pdf.filter(el => el.quantity > 0);
        this.setState({ filteredData: filteredArray });
        // 
    }

    handleDescriptionChange = (e) => {
        this.setState({ description: e.target.value });
    };

    handleSave = () => {
        const { all, Lid, Vid, Lang, description } = this.state;
        // 
        try {
            const updatedExamysaiContent = { ...all };

            // Ensure trends[Lid].description is initialized as an array
            if (!updatedExamysaiContent.trends[Lid].subject[Vid].language[Lang].description) {
                updatedExamysaiContent.trends[Lid].subject[Vid].language[Lang].description = "";
            }

            // Add description to trends[Lid].description array
            updatedExamysaiContent.trends[Lid].subject[Vid].language[Lang].description = description;

            // 

            // Set the modal and description state
            this.setState({ examysai_content: updatedExamysaiContent });
            // }
        } catch (error) {
            console.error("Error saving description:", error);
        }
    }

    render() {

        const { filteredData, all, Lid, Vid, Lang } = this.state;
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;


        const language = [
            { label: 'Hindi' },
            { label: 'English' },
        ]
        const handleChangePage = (event, newPage) => {
            this.props.viewAllPdf(newPage - 1, this.state.rowsPerPage, "");
            this.props.viewAllSubject(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <>

                <Grid style={{ marginLeft: "10%" }}  >
                    <Link to="/ExamAi" style={{ textDecoration: "none" }}>
                        <Button style={{ backgroundColor: "#6257CD", height: "45px" }} variant="contained" startIcon={<ArrowBackIcon />}>
                            Exams Ai
                        </Button>
                    </Link>
                    <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px", fontSize: "20px", fontWeight: 700 }}>Trends</Typography>

                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "7%", marginTop: "1%" }} >
                    <Grid>
                        <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                            Exam Ai Name:
                        </Typography>
                    </Grid>

                    <Grid >
                        <FormControl style={{ width: '30vw', marginRight: "10%", }}>

                            <TextField placeholder="Enter Exam Ai Name"
                                value={this.state.exam_ai_name}
                                onChange={(e) => { this.setState({ exam_ai_name: e.target.value }) }}></TextField>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='mainsecation'>
                        <center>
                            <Typography style={{ marginLeft: "45px", fontSize: "20px", fontWeight: 500 }}>Category
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "1%", width: 500, marginRight: "17%" }}>
                        <div class="input-group mb-3" style={{ marginRight: "8%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Select Category'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.category}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => {
                                    this.setState({ category: e.target.value })
                                    this.props.viewAllCategoryExam(e.target.value)
                                }}
                            >{admin.all_category.map((e) => {
                                return (
                                    <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                )
                            })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >
                    <Grid>
                        <Typography style={{ marginLeft: "1px", fontSize: "20px", fontWeight: 500 }}>
                            Select Exam:
                        </Typography>
                    </Grid>


                    <Grid >
                        <FormControl style={{ width: '30vw', marginLeft: "3%" }}>
                            <InputLabel id="demo-simple-select-label">Select Exam</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="select Exam"
                                name=""
                                Select
                                value={this.state.exam_id}
                                onChange={(e) => {
                                    this.setState({ exam_id: e.target.value });

                                }}

                            >
                                {Array.isArray(this.props.admin.all_exams) && this.props.admin.all_exams?.map((e) => (
                                    <MenuItem value={e._id}>{e.exam_name}</MenuItem>
                                ))}
                            </Select>
                            {/* <Textarea sx={{ marginTop: '20px', height: '50px', width: "100%" }} size="md" name="Size" placeholder="Text Here............" /> */}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ marginTop: "-30%", marginLeft: "45px", fontSize: "20px", fontWeight: 500 }}>Stage
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "1%", width: 570, marginRight: "11%" }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%" }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Select Stage'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.stage}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "101%", marginLeft: "1%" }}
                                onChange={(e) => { this.setState({ stage: e.target.value }) }}
                            >
                                {admin.all_stages.map((e) => {
                                    return (
                                        <MenuItem value={e._id}>{e.stage_name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/* <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}
                >
                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ marginTop: "-18%", marginLeft: "45px", fontSize: "20px", fontWeight: 500 }}>Select Language:
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "1%", width: 570, marginRight: "11%" }}>
                        <div class="input-group mb-3" style={{ marginLeft: "20px", }}>
                            <Select
                                style={{
                                    background: "white",
                                    height: "50px", width: "450px",
                                    border: "1px solid #888",
                                    fontSize: 16,
                                    borderRadius: 5,
                                    padding: 7,
                                    color: 'gray'
                                }}
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                label="Select Langauage"
                                select
                                value={this.state.language_id}
                                onChange={(e) => {
                                    this.setState({ language_id: e.target.value });
                                }}
                            >
                                <MenuItem value={"Langauge"}>{"Select Language"}</MenuItem>
                                {Array.isArray(admin.all_language) && admin.all_language?.map((e) => {
                                    // 
                                    return (
                                        <MenuItem value={e._id}>{e.language}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>
                </Grid> */}

                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", marginTop: "4%", marginLeft: "8%" }}>

                    {/* {this.state.exam_ai_name !== "" && this.state.exam_id !== "" && this.state.category !== "" && this.state.stage !== "" && */}
                    <Button
                        // disabled={this.state.exam_ai_name === "" || this.state.exam_id === "" && this.state.category !== "" && this.state.stage !== "" }
                        variant="outlined"
                        style={{ border: "2px solid #6257cd", height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "200px", borderRadius: 5, display: "flex", flexDirection: "row" }}

                    >

                        Add Trends</Button>




                    <Grid container style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center", justifyContent: "flex-start", marginRight: "10%" }}>

                        {Array.isArray(all?.trends) && all?.trends?.map((e, index) => {
                            // 
                            return (

                                <div key={e._id} style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                    {/* {
                                        
                                    } */}
                                    <Chip label={e.trend_type}
                                        style={{
                                            backgroundColor: this.state.Lid == index ? "#6257CD" : '',
                                            color: this.state.Lid == index ? "white" : '',
                                        }}
                                        variant={this.state.all.trends[index].subject == undefined ? "outlined" : "filled"}
                                        onClick={() => {
                                            this.setState({ Lid: index })
                                        }
                                        }
                                        onDelete={() => {
                                            this.handleDelete(e._id)
                                            this.UnCheckedtrends(admin.view_all_trends, e.trend_type)
                                        }} />

                                </div>



                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                Trendmodal: true,
                            })
                        }} />
                    </Grid>


                </Box>

                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%", marginLeft: "8%" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >

                        <Button
                            style={{ border: "2px solid #6257cd", width: "170px" }}

                        >


                            ADD SUBJECT</Button>
                        {/* } */}
                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "5%", border: "2px solid #6257cd", alignItems: "center", marginRight: "10%" }}>
                        {(all?.trends?.[Lid]?.subject == undefined ? [] : all?.trends?.[Lid]?.subject ?? []).filter(el => el.quantity >= 0).map((e, index) => {
                            return (


                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e.subject_name}
                                        style={{
                                            backgroundColor: this.state.Vid == index ? "#6257CD" : '',
                                            color: this.state.Vid == index ? "white" : ''
                                        }}
                                        // variant={this.state.all.trends[index]==undefined?"outlined" : "filled"}
                                        variant={all.trends[Lid]?.subject[index].hasOwnProperty('language') ? "filled" : "outlined"}

                                        onClick={() => this.setState({ Vid: index })}
                                        onDelete={() => {
                                            this.handleDeleteSub(Lid, index)
                                            this.UnCheckedSubject(admin.all_subject, e.subject_name)
                                        }}

                                    />


                                </div>

                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                subjectmodal: true,
                            })
                        }} />
                    </Grid>


                </Box>

                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", marginTop: "4%", marginLeft: "8%" }}>

                    {/* {this.state.exam_ai_name !== "" && this.state.exam_id !== "" && this.state.category !== "" && this.state.stage !== "" && */}
                    <Button
                        // disabled={this.state.exam_ai_name === "" || this.state.exam_id === "" && this.state.category !== "" && this.state.stage !== "" }
                        variant="outlined"
                        style={{ border: "2px solid #6257cd", height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "200px", borderRadius: 5, display: "flex", flexDirection: "row" }}

                    >

                        Add Language</Button>




                    <Grid container style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center", justifyContent: "flex-start", marginRight: "10%" }}>

                        {Array.isArray(all?.trends?.[Lid]?.subject?.[Vid]?.language) && all?.trends?.[Lid]?.subject?.[Vid]?.language?.map((e, index) => {
                            // 
                            return (

                                <div key={e._id} style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>

                                    <Chip label={e.language}
                                        style={{
                                            backgroundColor: this.state.Lang == index ? "#6257CD" : '',
                                            color: this.state.Lang == index ? "white" : '',
                                        }}
                                        // variant={this.state.all.language[index].trends == undefined ? "outlined" : "filled"}
                                        variant={all.trends[Lid]?.subject[Vid].language[index].hasOwnProperty('pdf') ? "filled" : "outlined"}

                                        onClick={() => {
                                            this.setState({ Lang: index })
                                        }
                                        }
                                        onDelete={() => {
                                            this.handleDeleteL(Lid, Vid, index)
                                            this.UnCheckedlanguage(admin.all_language, e.language)
                                        }} />

                                </div>



                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                languagemodal: true,
                            })
                        }} />
                    </Grid>


                </Box>

                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", marginTop: "4%", marginLeft: "8%", width: "100%", }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row", marginLeft: "0.3%" }}>
                        <Button
                            style={{ border: "2px solid #6257cd" }}
                        // onClick={() => {
                        //   
                        //   this.setState({ descriptionmodal: true });
                        //   
                        // }}
                        >
                            ADD DESCRIPTION
                        </Button>
                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginRight: "1%", alignItems: "center" }}>
                        <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: "0px 7px " }}>
                            {all?.trends?.[Lid]?.subject?.[Vid]?.language?.[Lang]?.description}
                        </div>
                        <AddIcon onClick={() => this.setState({ descriptionmodal: true })} />
                    </Grid>
                </Box>

                <Box style={{ flexDirection: "row", justifyContent: "space-between", display: 'flex', marginTop: "4%", marginLeft: "8%" }}>
                    {/* {this.state.exam_ai_name !== "" && this.state.exam_id !== "" && this.state.ex !== "" && this.state.sub !== "" && */}
                    <Button
                        // disabled={this.state.exam_ai_name !== "" || this.state.exam_id !== ""  }

                        style={{ height: "44px", color: "#6257CD", backgroundColor: "#fff", borderRadius: 5, border: "2px solid #6257cd", fontSize: "18px", width: "170px" }}

                    // onClick={() => {
                    //     this.setState({
                    //         pdfmodal: true,
                    //     })
                    // }}
                    >

                        Pdf</Button>

                    {/* } */}
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "9%", border: "2px solid #6257cd", alignItems: "center", marginRight: "10%" }}>
                        {(all?.trends?.[Lid]?.subject?.[Vid]?.language?.[Lang]?.pdf ?? []).filter(el => el.quantity >= 0).map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row" }}>
                                    <IconButton style={{ marginLeft: "-0px" }}>
                                        <img src={Pdf} height={24} width={24} />
                                    </IconButton>
                                    <Chip
                                        label={e?.pdf_hint}
                                        onDelete={() => {
                                            this.handleDeletePdf(Lid, Vid, Lang, index);
                                            this.UnCheckedpdf(admin.all_pdf, e?.pdf_hint);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                pdfmodal: true,
                            });
                        }} />
                    </Grid>

                </Box>






                <center>


                    <Link to="/ExamAi" >
                        <Button
                            className='btns'

                            onClick={() => {
                                // exam_ai_name, category
                                this.props.updateExamAi(this.props.admin.examAiDetails._id, this.state.exam_ai_name, this.state.category, this.state.exam_id, this.state.stage, this.state.all, this.state.language_id)
                                // )
                                this.setState({ exam_ai_name: "", exam_id: "", trend_select: "", category: "", stage: "", all: "" })

                            }}

                            style={{ marginBottom: "30px", marginTop: "8%", color: "white", width: "80px" }}
                        >SUBMIT</Button>
                    </Link>

                    {/* );
        })} */}
                </center>
                {/* //-------------------------------------Language Model----------------------------------------------------------// */}
                <Dialog

                    open={this.state.languagemodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ languagemodal: false })}>

                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchlanguage(admin.all_language, e)}
                                />
                            </Search>
                        </Grid>


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.filter_language) && admin.filter_language?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.language}</Typography>


                                        <Checkbox
                                            value={e.language}
                                            checked={all?.trends?.[Lid]?.subject?.[Vid]?.language?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const currentLesson = all.trends[Lid].subject[Vid];

                                                if (!currentLesson?.hasOwnProperty('language')) {
                                                    currentLesson.language = [];
                                                }

                                                const videoAlreadyExists = currentLesson.language && currentLesson.language.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.language = [...(currentLesson.language || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        languageChecked: [...prevState.languageChecked, e.language],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.subject = currentLesson.subject.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        languageChecked: prevState.languageChecked.filter(desc => desc !== e.language),
                                                    }));
                                                }
                                            }}
                                        />



                                    </div>
                                );
                            })}
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                {/* //-------------------------------------Trends Model----------------------------------------------------------// */}
                <Dialog

                    open={this.state.Trendmodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ Trendmodal: false })}>

                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchTrend(admin.view_all_trends, e)}
                                />
                            </Search>
                        </Grid>


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.trend_filtered) && admin.trend_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.trend_type}</Typography>
                                        {/* 
                                        <Checkbox
                                            value={e.trend_type}
                                            checked={all?.trends?.some(item2 => e?._id === item2?._id)}
                                            onClick={(p) => {
                                                if (all.trends == undefined) {
                                                    
                                                    all.trends = [e];
                                                } else {
                                                    
                                                    // Check if the lesson already exists in the array
                                                    if (!all.trends.includes(e)) {
                                                        
                                                        this.setState(() => all.trends.push(e))
                                                    } else {
                                                        // Handle duplicate value (e.g., display an error, log a message)
                                                        
                                                    }
                                                }
                                            }}
                                        /> */}

                                        <Checkbox
                                            value={e.trend_type}
                                            checked={all?.trends?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const currentLesson = all;

                                                if (!currentLesson?.hasOwnProperty('trends')) {
                                                    currentLesson.trends = [];
                                                }

                                                const videoAlreadyExists = currentLesson.trends && currentLesson.trends.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.trends = [...(currentLesson.trends || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        trendChecked: [...prevState.trendChecked, e.trend_type],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.trends = currentLesson.trends.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        trendChecked: prevState.trendChecked.filter(desc => desc !== e.trend_type),
                                                    }));
                                                }
                                            }}
                                        />



                                    </div>
                                );
                            })}
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ Trendmodal: false });
                                    this.props.viewAllTrends();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ Trendmodal: false });
                                    this.props.viewAllTrends();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                {/* //------------------------------------Subject---------------------------------------------------------// */}
                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ subjectmodal: false, searchTerm: '' })
                        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "")
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Paper
                                component="form"
                                style={{
                                    marginTop: "5%",
                                    display: "flex",
                                    width: "350px",
                                    height: "42px",
                                    marginLeft: "4%",
                                    boxShadow: "none",
                                    border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                }}
                            >
                                <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                    <SearchIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                                <InputBase
                                    className="search-text"
                                    placeholder="Search"
                                    inputProps={{ "aria-label": "Search" }}
                                    value={this.state.searchTerm}
                                    onChange={this.handleSearchChange}
                                />
                            </Paper>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px" }}>
                                <Pagination
                                    count={parseInt(admin.subject_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.all_subject) && admin.all_subject?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        {/* <Checkbox
                                            value={e.subject_name}
                                            checked={all.trends[Lid]?.subject?.some(item2 => e?._id === item2?._id)}
                                            // onClick={(p) => { this.Checkedsubject(p, index, admin.all_subject) }}
                                            onClick={(p) => {
                                                // 

                                                if (all.trends[Lid]?.subject === undefined) {
                                                    this.setState(()=>  all.trends[Lid].subject = [e])
                                                } else {
                                                    // Check if the lesson already exists in the array
                                                    if (!all.trends[Lid].subject.some(subject => subject._id === e._id)) {
                                                        this.setState(()=>   all.trends[Lid].subject.push(e))
                                                    } else {
                                                        // Handle duplicate value (e.g., display an error, log a message)
                                                        
                                                    }
                                                }
                                            }}

                                        /> */}

                                        <Checkbox
                                            value={e.subject_name}
                                            checked={all?.trends?.[Lid]?.subject?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const currentLesson = all?.trends?.[Lid];

                                                if (!currentLesson?.hasOwnProperty('subject')) {
                                                    currentLesson.subject = [];
                                                }

                                                const videoAlreadyExists = currentLesson.subject && currentLesson.subject.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.subject = [...(currentLesson.subject || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        subjectChecked: [...prevState.subjectChecked, e.subject_name],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.subject = currentLesson.subject.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        subjectChecked: prevState.subjectChecked.filter(desc => desc !== e.subject_name),
                                                    }));
                                                }
                                            }}
                                        />

                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false, searchTerm: '' });
                                    this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false, searchTerm: '' });
                                    this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                {/* //----------------------------------------Pdf Model-------------------------------------------------// */}
                <Dialog

                    open={this.state.pdfmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ pdfmodal: false, searchpdf: '' })
                        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid style={{ display: "flex", flexdirection: "row", width: "100%", marginTop: 20 }}>

                            <div>
                                <Paper
                                    component="form"
                                    style={{
                                        marginTop: "5%",
                                        display: "flex",
                                        width: "350px",
                                        height: "42px",
                                        marginLeft: "4%",
                                        boxShadow: "none",
                                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                    }}
                                >
                                    <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                        <SearchIcon style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className="search-text"
                                        placeholder="Search"
                                        inputProps={{ "aria-label": "Search" }}
                                        value={this.state.searchpdf}
                                        onChange={this.handleSearchChangepdf}
                                    />
                                </Paper>
                            </div>
                            {/* <div
                                onChange={(e) => {
                                    this.props.searchLanguage(admin.all_pdf, e.target.value);
                                    // 
                                }}
                            >


                                <select className="dfilter" style={{ width: "100px", height: 32, marginLeft: "50px" }}>
                                    <option value="">Filter</option>
                                    <option value="Hindi">Hindi</option>
                                    <option value="English">English</option>
                                </select>
                            </div> */}
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px" }}>
                                <Pagination
                                    count={parseInt(admin.pdf_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.all_pdf) && admin.all_pdf?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <IconButton

                                            style={{ marginLeft: "-0px" }}
                                        >
                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                            <img src={Pdf} height={24} width={24} />
                                        </IconButton>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.pdf_hint}</Typography>

                                        <Checkbox
                                            value={e.pdf_hint}
                                            checked={Array.isArray(all?.trends?.[Lid]?.subject?.[Vid]?.language?.[Lang]?.pdf) && all?.trends?.[Lid]?.subject?.[Vid]?.language?.[Lang]?.pdf?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const currentLesson = all?.trends?.[Lid]?.subject?.[Vid].language?.[Lang];

                                                if (!currentLesson?.hasOwnProperty('pdf')) {
                                                    currentLesson.pdf = [];
                                                }

                                                const videoAlreadyExists = currentLesson.pdf && currentLesson.pdf.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.pdf = [...(currentLesson.pdf || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        pdfChecked: [...prevState.pdfChecked, e.pdf_hint],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.pdf = currentLesson.pdf.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        pdfChecked: prevState.pdfChecked.filter(desc => desc !== e.pdf_hint),
                                                    }));
                                                }
                                            }}
                                        />

                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ pdfmodal: false, searchpdf: '' });
                                    // this.props.UnCheckedpdf(admin.all_pdf);
                                    this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.setState({ vid:"1" }) 
                                    this.setState({ pdfmodal: false, searchpdf: '' });
                                    this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                {/* //--------------------------------------------Description-----------------------// */}
                <Dialog
                    open={this.state.descriptionmodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ descriptionmodal: false })}
                >
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid style={{ justifyContent: "space-around", marginLeft: "3%", marginTop: "1%" }}>
                            <Grid>
                                <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                                    Description:
                                </Typography>
                            </Grid>
                            <Grid>
                                <FormControl style={{ width: '30vw', marginLeft: "3%" }}>
                                    <TextField
                                        placeholder="Enter Description"
                                        onChange={this.handleDescriptionChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ descriptionmodal: false, description: '' });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}
                            >
                                Close
                            </Button>

                            <Button
                                className='btns'
                                onClick={() => {
                                    this.handleSave();
                                    this.setState({ descriptionmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}
                            >
                                Save
                            </Button>
                        </center>
                    </div>
                </Dialog>
                <Loader />




            </>

        )
    }
}