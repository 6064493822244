import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Pdf from '../images/pdf.svg';
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            view: false,
            pdf_name: "",
            pdf_url: "",
            details: [
                {
                    access_name: "Paid"
                },
                {
                    access_name: "Free"
                },
            ],
        }
    }

    componentDidMount() {
        this.props.viewAllPdf();
        this.props.viewAllPreviousYearQuestion()
        // )
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <center>
                        <Button className='categorynames'>Previous Year Question</Button>
                    </center>
                    <Box style={{ marginRight: "" }}>
                        <center style={{ borderRadius: 0, }}>
                            <Link to='/AddPreviousyear'>
                                <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'cennter', padding: '7px 14px 6px 0px', marginTop: '10px' }}
                                >
                                    <IconButton>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    Previous Year Question
                                </Button>
                            </Link>
                        </center>
                    </Box>
                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop:"5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft:"4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                        <img src={Search} style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className='serach-text'
                        placeholder="Search "
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => {
                            this.props.searchPrevious(admin.all_previous_year, e.target.value)
                        }}
                    />
                </Paper>

                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Privous Year Name</TableCell>
                                            {/* <TableCell style={{ textAlign: 'center' }}>Year</TableCell> */}
                                            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.search_previous) && admin.search_previous.sort((a, b) => a?.name?.localeCompare(b?.name)).map((c) => {
                                            // 
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>
                                                        {c.name}</TableCell>
                                                    {/* <TableCell style={{ textAlign: 'center' }}>
                                                        {c.year}</TableCell> */}
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                        <Tooltip title="Edit">
                                                            <Link to="/EditPreviousYear" style={{ textDecoration: "none" }}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        
                                                                        this.props.setPreviousYearId(c);

                                                                    }}
                                                                    style={{ marginLeft: "-10px" }}
                                                                >
                                                                    {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                    <img src={Edit} alt='' height={24} width={24} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        pdf_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>



                    <Dialog
                        open={this.state.add}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Add Pdf name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "210px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        placeholder='Enter pdf name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ pdf_name: e.target.value }) }}
                                    />
                                </div>
                                {this.state.pdf_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Upload
                                    <input hidden type="file" multiple
                                        accept="application/pdf,application/vnd.ms-excel"
                                        onChange={(e) => { this.setState({ pdf_url: e.target.files[0] }) }}

                                    />
                                </Button>}
                                {this.state.pdf_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded
                                </Button>}
                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false, pdf_url: "" });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        disabled={this.state.pdf_name === ""}
                                        onClick={() => {
                                            this.props.add_upload_pdf(this.state.pdf_url, this.state.pdf_name);
                                            this.setState({ add: false, pdf_url: "" });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                    <Dialog
                        open={this.state.edit}
                        maxWidth="xl"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <h2 style={{ position: "relative", marginLeft: "2%" }}>Add Exam name</h2>
                        <Grid>
                            <Grid style={{ minWidth: "350px", height: "300px" }}>
                                <Grid>
                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >
                                        <FormControl style={{ width: '30vw', marginLeft: "60px", marginTop: "20px" }}>
                                            <InputLabel id="demo-simple-select-label">Select Subject</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="select category"
                                                select
                                                onChange={(e) => {
                                                    this.setState({ subject_id: e.target.value });

                                                }}
                                                value={this.state.subject_id}
                                            >
                                                <MenuItem value={"All Subject"}>{"All Subject"}</MenuItem>
                                                {Array.isArray(this.props.admin.all_subject) && this.props.admin.all_subject.map((e) => (
                                                    <MenuItem value={e._id}>{e.subject_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >

                                        <FormControl style={{ width: '30vw', marginLeft: "60px", marginTop: "20px" }}>
                                            <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="select category"
                                                select
                                                onChange={(e) => {
                                                    this.setState({ access: e.target.value });

                                                }}
                                                value={this.state.access}
                                            >
                                                <MenuItem value={"Select Access"}>{"Select Access"}</MenuItem>
                                                {this.state.details.map((e) => (
                                                    <MenuItem value={e.access_name}>{e.access_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <center>
                                        <Button
                                            // className='btns'
                                            variant="outlined"
                                            onClick={() => {
                                                this.setState({ edit: false });
                                            }}
                                            style={{ color: "#6257CD", marginBottom: "0px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                        <Button
                                            className='btns'

                                            // disabled={this.state.category_name === ""}
                                            onClick={() => {
                                                this.props.updatePreviousYearQuestion(this.state.subject_id, this.state.access);
                                                this.setState({ edit: false });
                                            }}
                                            style={{ marginLeft: "20px", marginBottom: "0px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                    </center>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Dialog>


                    {/* <Dialog
                        open={this.state.edit}
                        style={{ height: "1000px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Subject name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >

                                    <FormControl style={{ width: '30vw', marginLeft: "60px", marginTop: "20px" }}>
                                        <InputLabel id="demo-simple-select-label">Select Subject</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="select category"
                                            select
                                            onChange={(e) => {
                                                this.setState({ subject_id: e.target.value });

                                            }}
                                            value={this.state.subject_id}
                                        >
                                            <MenuItem value={"All Subject"}>{"All Subject"}</MenuItem>
                                            {Array.isArray(this.props.admin.all_subject) && this.props.admin.all_subject.map((e) => (
                                                <MenuItem value={e._id}>{e.subject_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >

                                    <FormControl style={{ width: '30vw', marginLeft: "60px", marginTop: "20px" }}>
                                        <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="select category"
                                            select
                                            onChange={(e) => {
                                                this.setState({ access: e.target.value });

                                            }}
                                            value={this.state.access}
                                        >
                                            <MenuItem value={"Select Access"}>{"Select Access"}</MenuItem>
                                            {this.state.details.map((e) => (
                                                <MenuItem value={e.access_name}>{e.access_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false });
                                        }}
                                        style={{ color: "#6257CD", marginBottom: "0px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => {
                                            this.props.updatePreviousYearQuestion(this.state.subject_id, this.state.access);
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "0px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog> */}
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.pdf_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => { this.props.deletePreviousYearQuestion(this.state.pdf_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>


                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}