import { Grid, Box, Button, MenuItem, Typography, TextField, Dialog, IconButton } from '@mui/material'
import React, { Component } from 'react'
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from "@material-ui/core/styles";
import One from "../images/count.svg"
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import Quill from "../../admin/component/Quiil";
import Checkbox from '@mui/material/Checkbox';

import Loader from '../../../common/loader/containers/loader_cont';

import Chip from '@mui/material/Chip';
import Quiz from '../images/quiz.svg';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase"


const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00f" // Set the hover border color
        }
    }
});


const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));




class addquiz extends Component {
    constructor(props) {
        super(props);
        this.quillRef = React.createRef();
        this.state = {
            editorHtml: '',
            value: '',
            quiz_title: "",
            quiz_desc: "",
            question_url: "",
            qustion_title: "",
            quastion_desc: "",
            opation1: "",
            option1_url: "",
            option1_desc: "",
            opation2: "",
            option2_url: "",
            option2_desc: "",
            opation3: "",
            option3_url: "",
            option3_desc: "",
            opation4: "",
            option4_url: "",
            option4_desc: "",
            option5_url: "",
            option5_desc: "",
            answer: "",
            answer_url: "",
            answer_desc: "",
            queslength: 0,
            showWarning: false,
            subjectmodal: false,
            subject: "",
            subject_id: "",
            Vid: 0,
            Markss: "",
            marks: "",
            time: "",
            rightAnswers: [
                {
                    option: "1"
                },
                {
                    option: "2"
                },
                {
                    option: "3"
                },
                {
                    option: "4"
                },
                {
                    option: "5"
                },
            ],
            // addsubmit: false
        }

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorChange0 = this.handleEditorChange0.bind(this);
        this.handleEditorChange1 = this.handleEditorChange1.bind(this);
        this.handleEditorChange2 = this.handleEditorChange2.bind(this);
        this.handleEditorChange3 = this.handleEditorChange3.bind(this);
        this.handleEditorChange4 = this.handleEditorChange4.bind(this);
        this.handleEditorChange5 = this.handleEditorChange5.bind(this);
    }

    handleInputChange = (event) => {
        // Get the input value
        const inputValue = event.target.value;
        // Update the state with the input value as an array
        this.setState({ QustationoneArray: [inputValue] });
    }
    componentDidMount() {

        this.props.viewAllSubject();
        this.props.viewAllQuestion();
        // this.props.viewAllquiz();
        // this.props.resetValue();
        // );
        window.addEventListener('beforeunload', this.handlePageRefresh);
        // 
        // 
        if (this.props.admin.refres_status === false) {
            this.props.DeleteAllQuestionrefersh();
            this.props.setRes(true);
            this.props.resetValue();
            this.props.resetValueopation1();
            this.props.resetValueopation2();
            this.props.resetValueopation3();
            this.props.resetValueopation4();
            this.props.resetValueopation5();
            this.props.resetValueanswer();
        }
        // if(this.state.showWarning === false){

        //     this.setState({ showWarning: true });
        // }
        // else{
        //     this.setState({ showWarning: false });

        // }




    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handlePageRefresh);
    }
    handlePageRefresh = (event) => {
        event.preventDefault();
        event.returnValue = '';
        this.props.setRes(false);
        // this.state.refres_status = 1;
        // 
        // // if(event.returnValue===''){
        // //     this.props.DeleteAllQuestionrefersh();
        // // }
        // // 
    }

    handleButtonClick = () => {
        // Increment 'queslength' by 1 when the button is clicked
        this.setState(prevState => ({
            queslength: prevState.queslength + 1
        }));
    }

    handleDeleteSub = (id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...newState.subject];
            newArr.splice(id, 1);
            newState.subject = newArr;
            return newState;
        });
    }

    handleChange = (value) => {
        this.setState({ value });
    }

    handleEditorChange4(html) {
        this.setState({ option4_desc: html });
    }
    handleEditorChange5(html) {
        this.setState({ option5_desc: html });
    }
    handleEditorChange3(html) {
        this.setState({ option3_desc: html });
    }
    handleEditorChange2(html) {
        this.setState({ option2_desc: html });
    }
    handleEditorChange1(html) {
        this.setState({ option1_desc: html });
    }

    handleEditorChange0(html) {
        this.setState({ qustion_title: html });

    }
    handleEditorChange(html) {
        this.setState({ editorHtml: html });
        // 
    }




    render() {
        const { classes, admin } = this.props;
        const { Vid, subject } = this.state;
        // 
        // 
        return (
            <Grid>
                <Box className='categoryname'>
                    <center style={{ display: "flex" }}>
                        <Link to="/quizes" style={{ textDecoration: "none" }}>
                            <IconButton
                            >
                                <ArrowBackIcon style={{ color: "white" }} />
                            </IconButton>
                        </Link>
                        <Typography style={{ color: "white ", fontSize: 16, marginTop: "8px" }}>Create Quiz</Typography>


                        <Button style={{ color: "white ", textAlign: 'center', }}></Button>
                    </center>
                </Box>



                <Grid className='boxdesign' style={{ border: "1px solid #888", height: 530, width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Add Quiz title & Description
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Title
                    </Typography>
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            size="small"
                            placeholder='Enter quiz title'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                            onChange={(e) => { this.setState({ quiz_title: e.target.value }) }}
                            className={classes.root}
                        />
                    </div>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Hint
                    </Typography>
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Quiz Description '
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            onChange={(e) => { this.setState({ quiz_desc: e.target.value }) }}
                        />
                    </div>
                    {/* <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Language
                    </Typography>
                    {/* <Grid style={{ marginTop: "2%", width: 600 }}> */}
                    {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                            id="outlined-multiline-static"
                            placeholder='Enter Language'
                            select
                            className={classes.root}
                            rows={1}
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            onChange={(e) => {
                                this.setState({ language: e.target.value })
                            }}
                        >
                            <MenuItem value={""}>{"Select Language"}</MenuItem>
                            <MenuItem value={"Hindi"}>{"Hindi"}</MenuItem>
                            <MenuItem value={"English"}>{"English"}</MenuItem>

                        </TextField>

                    </div> */}

                    {/* </Grid> */}
                    <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Time In Minutes:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Time '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "20%", }}
                            onChange={(e) => { this.setState({ time: e.target.value }) }}
                        />

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Marks:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter Marks '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "20%", }}
                            onChange={(e) => { this.setState({ marks: e.target.value }) }}
                        />
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Total Questions:
                        </Typography>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                        <TextField
                            required
                            // id="outlined-required"
                            // label="Category Name"
                            className={classes.root}
                            size="small"
                            placeholder='Enter - Marks '
                            type='number'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "20%", }}
                            onChange={(e) => { this.setState({ Markss: e.target.value }) }}
                        />
                        {/* </div> */}
                    </div>
                    {/* <Grid style={{ marginTop: "1%", width: 570, marginRight: "11%" }}> */}
                    <div class="input-group mb-3" style={{ marginRight: "18%", }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Select Subject:
                        </Typography>
                        <TextField
                            required
                            id="outlined-multiline-static"
                            placeholder='Select Subject'
                            select
                            rows={1}
                            // defaultValue="Default Value"

                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "40px", width: "100%", marginLeft: "30px" }}
                            onChange={(e) => { this.setState({ subject: e.target.value }) }}
                        >
                            {Array.isArray(this.props.admin.all_subject) && this.props.admin.all_subject?.map((e) => {
                                return (
                                    <MenuItem value={e._id}>{e.subject_name}</MenuItem>
                                )
                            })
                            }
                        </TextField>

                    </div>

                    {/* </Grid> */}
                </Grid>

                <Grid style={{ display: "flex", alignItems: "center", marginTop: "22px", }}>

                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", marginLeft: "57px" }}>
                        Quastion Added:-

                    </Typography>
                    {/* <img src={One} alt='' height={50} width={50} style={{ marginLeft: "10px", marginTop: "10px" }} />
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", marginTop: "22px", marginLeft: "-30px" }}>
                  
                        {this.state.queslength}


                    </Typography> */}
                    <div style={{ "display": "flex", "flexDirection": "row", "gap": "11px", "width": "47%", "flexWrap": "wrap" }}>
                        {
                            Array(this.state.queslength).fill("er").map((i, index) => (<>
                                {/* <img src={One} alt='' height={50} width={50} style={{ marginLeft: "10px", marginTop: "10px" }} /> */}
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold" }}>

                                    {index + 1}
                                </Typography>
                            </>
                            ))
                        }
                    </div>

                </Grid>




                <Grid className='boxdesign' style={{ border: "1px solid #c4c4c4", height: "100%", width: "70%", borderRadius: 10, marginBottom: "5%" }}>
                    {/* <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            <Button
                                variant="outlined"
                                style={{ border: "2px solid #6257cd" }}

                            >

                                Select Subject</Button>



                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {(this.state.subject ?? []).map((e, index) => {
                                return (
                                    <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                        <Chip label={e.subject_name}
                                            style={{
                                                backgroundColor: this.state.Qid == index ? "#6257CD" : '',
                                                color: this.state.Qid == index ? "white" : ''
                                            }}
                                            // variant={this.state.test_content.test[index] == undefined ? "outlined" : "filled"}
                                            onClick={() => {
                                                this.setState({ Qid: index })
                                                this.props.subject_id(e._id);
                                            }}
                                            // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 

                                            onDelete={() => this.handleDeleteSub(Vid, index)}


                                        />


                                    </div>
                                );
                            })}
                            <AddIcon onClick={() => {
                                this.setState({
                                    subjectmodal: true,
                                })
                            }} />
                        </Grid>


                    </Box> */}
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Add Question Text
                    </Typography>
                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>
                        <div>
                            <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                value={this.state.qustion_title}
                                onChange={this.handleEditorChange0}
                                modules={addquiz.modules}
                                formats={addquiz.formats}
                            />
                        </div>
                    </div>

                    {this.state.question_url !== "" &&
                        this.props.admin.question_img !== "" &&
                        <img alt='' src={this.props.admin.question_img} height={150} width={440} style={{ marginLeft: "20px" }}

                        />
                    }
                    {/* {this.state.question_url === "" && */}
                    <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                        startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                        Upload
                        {/* <input hidden accept="image/*" multiple type="file" /> */}
                        <input hidden type="file" multiple
                            accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                            // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                            // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                            onChange={(e) => {
                                this.setState({ question_url: e.target.files[0] });


                            }}

                        />
                    </Button>
                    {/* } */}
                    <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                        {this.state.question_url !== "" &&
                            <Button variant="outlined" component="label" className='upload'
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}
                                onClick={() => {

                                }}
                            >

                                Successfully uploaded

                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                                        accept="video/mp4,video/x-m4v,video/*"
                                        // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                        onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

                                    /> */}
                            </Button>}
                        {this.state.question_url !== "" && <Button variant="outlined" component="label"
                            // onChange={(e)=> { this.setState({ question_url:"" }) }}
                            onClick={() => {
                                this.setState({ question_url: "", });
                                this.props.resetValue()



                            }}

                            // className='upload' 
                            //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                        // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                        >
                            Clear
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            {/* <input hidden type="file" multiple
                                        accept="video/mp4,video/x-m4v,video/*"
                                        // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                        
                                    /> */}
                            {/* onChange={(e) => { this.setState({ question_url: "" }) }} */}
                        </Button>}
                        {this.state.question_url !== "" && <Button variant="outlined" component="label"
                            onClick={() => {
                                this.props.UploadQuestion(this.state.question_url)
                            }}

                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                        >
                            Upload

                        </Button>}
                    </Grid>




                </Grid>
                <Grid style={{ border: "1px solid #c4c4c4", width: "70%", borderRadius: 10, marginLeft: "4%", marginBottom: "5%" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Add Answers/Options
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Add Options
                    </Typography>



                    <Grid container spacing={2} >
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                >Option 1
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation1_img !== "" && <img alt='' src={this.props.admin.opation1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option1_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option1_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option1_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option1_url !== "" && this.props.admin.opation1_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation1();
                                        this.setState({ option1_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option1_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin1(this.state.option1_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option1_desc}
                                    onChange={this.handleEditorChange1}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option1_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option1_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography>
                                    Option 2
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation2_img !== "" && <img alt='' src={this.props.admin.opation2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option2_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option2_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option2_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option2_url !== "" && this.props.admin.opation2_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation2();
                                        this.setState({ option2_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option2_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin2(this.state.option2_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option2_desc}
                                    onChange={this.handleEditorChange2}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option2_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option2_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                >Option 3
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation3_img !== "" && <img alt='' src={this.props.admin.opation3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option3_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option3_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option3_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option3_url !== "" && this.props.admin.opation3_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation3();
                                        this.setState({ option3_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option3_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin3(this.state.option3_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option3_desc}
                                    onChange={this.handleEditorChange3}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option1_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option1_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <Typography
                                >Option 4
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={10} >
                            {this.props.admin.opation4_img !== "" && <img alt='' src={this.props.admin.opation4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                            {this.state.option4_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Upload
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                <input hidden type="file" multiple
                                    accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                    // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                    // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                    onChange={(e) => { this.setState({ option4_url: e.target.files[0] }) }}

                                />
                            </Button>}
                            <Grid style={{ display: "flex", flexDirection: "row" }}>

                                {this.state.option4_url !== "" && <Button variant="outlined" component="label" className='upload'
                                    style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded

                                </Button>}
                                {this.state.option4_url !== "" && this.props.admin.opation4_img !== "" && <Button variant="outlined" component="label"
                                    onClick={() => {
                                        this.props.resetValueopation4();
                                        this.setState({ option4_url: "" })
                                    }}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                >
                                    Clear
                                </Button>}
                                {this.state.option4_url !== "" && <Button variant="outlined" component="label"
                                    onClick={() => this.props.UploadOpatin4(this.state.option4_url)}

                                    style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                >
                                    Upload

                                </Button>}
                            </Grid>
                            <div>
                                <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                    value={this.state.option4_desc}
                                    onChange={this.handleEditorChange4}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    multiline
                                    rows={3}

                                    value={this.state.option1_desc}
                                    className={classes.root}
                                    size="small"
                                    placeholder='Description '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "95%", }}
                                    onChange={(e) => { this.setState({ option1_desc: e.target.value }) }}
                                />
                            </div> */}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} >
                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                    <Typography
                                    // required
                                    // // id="outlined-required"
                                    // // label="Category Name"
                                    // size="small"
                                    // placeholder='Option 1'
                                    // value={this.state.opation1}
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    // style={{ height: "8vh", width: "95%", borderColor: "#c4c4c4" }}
                                    // onChange={(e) => { this.setState({ opation1: e.target.value }) }}
                                    // className={classes.root}
                                    >Option 5
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={10} >
                                {this.props.admin.opation5_img !== "" && this.props.admin.opation5_img !== undefined && this.props.admin.opation5_img !== null && this.props.admin.opation5_img !== " " && <img src={this.props.admin.opation5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                {this.state.option5_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Upload
                                    {/* <input hidden accept="image/*" multiple type="file" /> */}
                                    <input hidden type="file" multiple
                                        accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                        // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                        // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                        onChange={(e) => { this.setState({ option5_url: e.target.files[0] }) }}

                                    />
                                </Button>}
                                <Grid style={{ display: "flex", flexDirection: "row" }}>

                                    {this.state.option5_url !== "" && <Button variant="outlined" component="label" className='upload'
                                        style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                        startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                        Successfully uploaded

                                    </Button>}
                                    {this.state.option5_url !== "" && this.props.admin.opation5_img !== "" && <Button variant="outlined" component="label"
                                        onClick={() => {
                                            this.props.resetValueopation5();
                                            this.setState({ option5_url: "" })
                                        }}

                                        style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                                    >
                                        Clear
                                    </Button>}
                                    {this.state.option5_url !== "" && <Button variant="outlined" component="label"
                                        onClick={() => this.props.UploadOpatin5(this.state.option5_url)}

                                        style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                                    >
                                        Upload

                                    </Button>}
                                </Grid>
                                <div>
                                    <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                                        value={this.state.option5_desc}
                                        onChange={this.handleEditorChange5}
                                        modules={addquiz.modules}
                                        formats={addquiz.formats}
                                    />
                                </div>

                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>

                <Grid className='boxdesign' style={{ border: "1px solid #888", height: "auto", width: "70%", borderRadius: 10, borderColor: "#c4c4c4", marginBottom: "5%" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Enter Correct Answer
                    </Typography>

                    <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <select
                            value={this.state.answer}
                            style={{
                                background: "white",
                                height: '40px',
                                width: "95%",
                                border: "1px solid #888",
                                fontSize: 16,
                                borderRadius: 5,
                                padding: 7,
                                color: 'gray'
                            }}
                            select

                            onChange={(e) => {
                                this.setState({ answer: e.target.value });

                            }}
                        >
                            <option value="" >Select Right Answer</option>
                            {this.state.rightAnswers.map((e) => (
                                <option value={e.option}>{e.option}</option>
                            ))}
                        </select>

                    </div>

                    {this.props.admin.answer_img !== "" && <img alt='' src={this.props.admin.answer_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                    {this.state.answer_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                        startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}
                    >

                        Upload
                        {/* <input hidden accept="image/*" multiple type="file" /> */}
                        <input hidden type="file" multiple
                            accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                            onChange={(e) => { this.setState({ answer_url: e.target.files[0] }) }}

                        />
                    </Button>}
                    <Grid style={{ display: "flex", flexDirection: "row" }}>

                        {this.state.answer_url !== "" && <Button variant="outlined" component="label" className='upload'
                            //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                            style={{ width: "93%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Successfully uploaded

                        </Button>}
                        {this.state.answer_url !== "" && <Button variant="outlined" component="label"
                            // onChange={(e)=> { this.setState({ question_url:"" }) }}
                            onClick={() => {
                                this.props.resetValueanswer();
                                this.setState({ answer_url: "" });
                            }}


                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                        // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                        >
                            Clear

                            {/* onChange={(e) => { this.setState({ question_url: "" }) }} */}
                        </Button>}
                        {this.state.answer_url !== "" && <Button variant="outlined" component="label"
                            onClick={() => this.props.UploadAnswer(this.state.answer_url)}

                            style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                        >
                            Upload

                        </Button>}
                    </Grid>

                    {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                        <TextField
                            required
                           
                            className={classes.root}
                            size="small"
                            placeholder='Description'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "8vh", width: "95%", }}
                            onChange={(e) => { this.setState({ answer_desc: e.target.value }) }}
                            value={this.state.answer_desc}
                        />
                    </div> */}

                    {/* <ReactQuill style={{ paddingBottom:"20px",width: "93%",borderRadius: 10,  borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }} theme="snow" value={this.state.value} onChange={this.handleChange} /> */}
                    <div>
                        <ReactQuill style={{ paddingBottom: "20px", width: "93%", borderRadius: "15px", borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                            value={this.state.editorHtml}
                            onChange={this.handleEditorChange}
                            modules={addquiz.modules}
                            formats={addquiz.formats}
                        />
                    </div>

                </Grid>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "70%", marginLeft: "4%" }}>
                    {/* {this.props.admin.all_question.length < 10 && */}
                    {/* <Link to='/quizes' style={{ textDecoration: "none"}}> */}
                    {/* {(this.state.qustion_title !== "",
                        this.state.quastion_desc !== "",
                        //    this.state.question_img !== "",
                        this.state.opation1 !== "",
                        //    this.state.opation1_img !== "",
                        this.state.option1_desc !== "",
                        this.state.opation2 !== "",
                        //    this.state.opation2_img !== "",
                        this.state.option2_desc !== "",
                        this.state.opation3 !== "",
                        //    this.state.opation3_img!== "",
                        this.state.option3_desc !== "",
                        this.state.opation4 !== "",
                        //    this.state.opation4_img !== "",
                        this.state.option4_desc !== "",
                        this.state.answer !== "",
                        //    this.state.answer_img !== "",
                        this.state.answer_desc !== "",
                        this.state.editorHtml !== "") && */}
                    <Button style={{
                        color: "white ", textAlign: 'center', textTransform: "capitalize",
                        backgroundColor: "#6257CD", marginBottom: "2%"
                    }}
                        onClick={() => {
                            //  
                            this.props.createQuestion(this.state.qustion_title, this.state.quastion_desc, this.props.admin.question_img, '1', this.props.admin.opation1_img, this.state.option1_desc, '2', this.props.admin.opation2_img, this.state.option2_desc, '3', this.props.admin.opation3_img, this.state.option3_desc, '4', this.props.admin.opation4_img, this.state.option4_desc, '5', this.props.admin.opation5_img, this.state.option5_desc, this.state.answer, this.props.admin.answer_img, this.state.editorHtml, this.props.admin.uuid, this.props.admin.subject_id);
                            //   
                            this.props.admin.opation2_img = "";
                            this.props.admin.opation1_img = "";
                            this.props.admin.opation3_img = "";
                            this.props.admin.opation4_img = "";
                            this.props.admin.opation5_img = "";
                            this.props.admin.answer_img = "";
                            this.props.admin.question_img = "";

                            this.setState({
                                qustion_title: "",
                                quastion_desc: "",
                                question_img: "",
                                // opation1: "",
                                opation1_img: "",
                                option1_url: "",
                                option2_url: "",
                                option3_url: "",
                                option4_url: "",
                                option5_url: "",
                                option1_desc: "",
                                // opation2: "",
                                opation2_img: "",
                                option2_desc: "",
                                // opation3: "",
                                opation3_img: "",
                                option3_desc: "",
                                // opation4: "",
                                opation4_img: "",
                                opation5_img: "",
                                option4_desc: "",
                                option5_desc: "",
                                answer: "",
                                answer_img: "",
                                answer_desc: "",
                                editorHtml: "",
                                question_url: "",
                                answer_url: ""
                            });
                            this.handleButtonClick();

                        }}

                    // disabled={(this.state.qustion_title === "" || this.state.quastion_desc === "" || '1', this.state.option1_desc === "" || '2', this.state.option2_desc === "" || '3', this.state.option3_desc === "" || '4', this.state.option4_desc === "" || this.state.answer === ""  || this.props.admin.subject_id == "")}
                    >Save & Next</Button>
                    {/* } */}


                    <Box className='saveandcnt'>
                        {/* <center> */}
                        <Link to='/reviewquiz' style={{ textDecoration: "none", pointerEvents: (this.state.quiz_desc === "") ? 'none' : '' }}>

                            <Button style={{ color: "white ", textTransform: "capitalize", width: "100%" }}
                                onClick={() => {

                                    if (
                                        this.state.qustion_title !== "<p><br></p>" &&
                                        // this.state.question_img !== "" &&
                                        // this.state.opation1 !== "" &&
                                        // this.state.opation1_img !== "" &&
                                        this.state.option1_desc !== "<p><br></p>" &&
                                        // this.state.opation2 !== "" &&
                                        // this.state.opation2_img !== "",
                                        this.state.option2_desc !== "<p><br></p>" &&
                                        // this.state.opation3 !== "" &&
                                        // this.state.opation3_img !== "",
                                        this.state.option3_desc !== "<p><br></p>" &&
                                        // this.state.opation4 !== "" &&
                                        // this.state.opation4_img !== "",
                                        this.state.option4_desc !== "<p><br></p>" &&
                                        // this.state.answer === "" &&
                                        // this.state.answer_img !== "",
                                        // this.state.answer_desc !== "",
                                        this.state.editorHtml !== "<p><br></p>"

                                    ) {

                                        // 
                                        this.props.createQuestionSubmit(this.state.qustion_title, this.state.quastion_desc, this.props.admin.question_img, '1', this.props.admin.opation1_img, this.state.option1_desc, '2', this.props.admin.opation2_img, this.state.option2_desc, '3', this.props.admin.opation3_img, this.state.option3_desc, '4', this.props.admin.opation4_img, this.state.option4_desc,'5', this.props.admin.opation5_img, this.state.option5_desc, this.state.answer, this.props.admin.answer_img, this.state.editorHtml, this.props.admin.uuid, this.props.admin.subject_id, this.state.quiz_title, this.state.quiz_desc, this.state.language, this.props.admin.uuid, this.state.time, this.state.marks, this.state.Markss, this.state.subject);




                                        // 
                                        this.props.admin.opation2_img = "";
                                        this.props.admin.opation1_img = "";
                                        this.props.admin.opation3_img = "";
                                        this.props.admin.opation4_img = "";
                                        this.props.admin.opation5_img = "";
                                        this.props.admin.answer_img = "";
                                        this.props.admin.question_img = "";

                                        this.setState({
                                            qustion_title: "",
                                            quastion_desc: "",
                                            question_img: "",
                                            opation1: "",
                                            opation1_img: "",
                                            option1_url: "",
                                            option2_url: "",
                                            option3_url: "",
                                            option4_url: "",
                                            option5_url: "",
                                            option1_desc: "",
                                            opation2: "",
                                            opation2_img: "",
                                            option2_desc: "",
                                            opation3: "",
                                            opation3_img: "",
                                            option3_desc: "",
                                            opation4: "",
                                            opation4_img: "",
                                            option4_desc: "",
                                            opation5_img: "",
                                            opation5_img: "",
                                            option5_desc: "",
                                            answer: "",
                                            answer_img: "",
                                            answer_desc: "",
                                            editorHtml: "",
                                            question_url: "",
                                            answer_url: "",
                                            quiz_title: "", quiz_desc: "", language: "", time: "", marks: "", Markss: ""
                                        });
                                        this.handleButtonClick();
                                    } else {
                                        this.props.createquiz(this.state.quiz_title, this.state.quiz_desc, this.state.language, this.props.admin.uuid, this.state.time, this.state.marks, this.state.Markss, this.state.subject)

                                        this.setState({ quiz_title: "", quiz_desc: "", language: "", time: "", marks: "", Markss: "" });
                                    }
                                }}
                            // disabled={((this.state.quiz_title === "") || (this.state.quiz_desc === "") || (this.state.language === "") || (this.state.time === "") || (this.state.marks === ""))}

                            >Save and Review Quiz</Button>

                        </Link>
                        {/* </center> */}
                    </Box>



                </Grid>




                <Dialog
                    open={this.state.showWarning}
                    style={{ height: "600px" }}>
                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                    <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ showWarning: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                <Button
                                    // className='btns'

                                    // disabled={this.state.category_name === ""}
                                    onClick={() => { this.props.DeleteAllQuestionrefersh(); this.setState({ showWarning: false }) }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                            </center>
                        </Grid>
                    </Grid>
                </Dialog>

                {/* Test Subject */}
                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Subject"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchsubject(this.props.admin.all_subject, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(this.props.admin.subject_filtered) && this.props.admin.subject_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        <Checkbox
                                            value={e.subject_name}
                                            // onClick={(p) => { this.Checkedsubject(p, index, admin.all_subject) }}
                                            onClick={() => {
                                                // Ensure subject is initialized as an array
                                                // if (!this.state.subject || !Array.isArray(this.state.subject)) {
                                                //     this.setState({ subject: [] });
                                                // }

                                                // Check for duplicate _id in the subject array
                                                if (!this.state.subject.some(chapter => chapter._id === e._id)) {
                                                    // Update state to include the new element
                                                    this.setState(prevState => ({
                                                        subject: [...prevState.subject, e]
                                                    }));
                                                } else {
                                                    // Handle duplicate value (e.g., display an error, log a message)

                                                }
                                                // Additional code/logic can go here
                                            }}



                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                <Loader />
            </Grid>
        )
    }
}
addquiz.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'], [{ script: "sub" }, { script: "super" }]
    ],
};

addquiz.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
];
export default withStyles(styles)(addquiz);

