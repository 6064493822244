import { Grid, Box, Button, Typography, TextField, Dialog, Chip } from '@mui/material'
import React, { Component } from 'react'
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from "@material-ui/core/styles";
import One from "../images/count.svg"
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00f" // Set the hover border color
        }
    }
});

class addquiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quiz_title: "",
            quiz_desc: "",
            add_qustion_url: "",
            qustion_title: "",
            quastion_desc: "",
            opation1: "",
            option1_url: "",
            option1_desc: "",
            opation2: "",
            option2_url: "",
            option2_desc: "",
            opation3: "",
            option3_url: "",
            option3_desc: "",
            opation4: "",
            option4_url: "",
            option4_desc: "",
            answer: "",
            answer_url: "",
            answer_desc: "",
            showWarning: false,
            Qid: 0,
            Vid: 0,
            Lang: 0,


        }
    }

    componentDidMount() {
        // this.props.viewVarient();
        // this.props.viewAllCategory();
        // this.props.viewModel();
        // componentDidMount(){
        // if (this.props.admin.quiz_id !== "") {
        //     this.props.viewalldatabyQuizId(this.props.admin.quiz_id);
        // }
        // 
        if (this.props.admin.quiz_id !== "") {
            // this.props.viewalldatabyQuizId(this.props.admin.quiz_id);
        }

        // 
    }

    render() {
        const { classes } = this.props;
        const { Qid, Vid, Lang } = this.state
        return (
            <Grid>
                <Box className='categoryname'>
                    <center>
                        <Button style={{ color: "white ", textAlign: 'center', }}>Review Quiz</Button>
                    </center>
                </Box>



                <Grid className='boxdesign' style={{ border: "1px solid #888", height: 450, width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Quiz title & Hint
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Title
                        {/* {this.props.admin.all_quiz_by_id.quiz_name} */}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {/* Quiz Title */}
                        {this.props.admin.all_tquiz_by_tid?.quiz_name}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Hint
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {/* Quiz Title */}
                        {this.props.admin.all_tquiz_by_tid?.quiz_desc}
                    </Typography>
                    {/* <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Language
                    </Typography>
                    // <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                    //     {/* Quiz Title */}
                    {/* //     {this.props.admin.all_quiz_by_id.language}
                    // </Typography> */}
                    <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "3%" }}>
                            Time In Minutes:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Title */}
                            {this.props.admin.all_tquiz_by_tid?.time}
                        </Typography>

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "3%" }}>
                            Total Marks:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Title */}
                            {this.props.admin.all_tquiz_by_tid?.marks}
                        </Typography>
                        <div style={{ marginLeft: "50px", marginTop: "2%" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.admin.all_tquiz_by_tid?.setSection}
                                    />
                                }
                                label="Set Section Time"
                            />
                        </div>
                    </div>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "-10px" }}>
                        Access
                        {/* {this.props.admin.all_quiz_by_id.quiz_name} */}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px", height: "45px" }}>
                        {this.props.admin.all_tquiz_by_tid.access}
                        {/* {console.log(this.props.admin.all_tquiz_by_tid)} */}
                    </Typography>


                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column" }}>
                    {/* <Grid style={{ display: "flex", flexDirection: "row" }}> */}
                    <div style={{ "display": "flex", "flexDirection": "row", "gap": "11px", "width": "47%", "flexWrap": "wrap", marginLeft: "4%", marginTop: "10px" }}>
                        {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language?.[Lang]?.questions ?? [])?.map((c, index) => {
                            return (


                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold" }}>

                                    {index + 1}



                                </Typography>

                            );
                        })}
                    </div>
                    {/* </Grid> */}
                    <div style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginTop: "1%", alignItems: "center", width: "70%" }}>

                        {Array.isArray(this.props.admin.all_tquiz_by_tid?.section) && this.props.admin.all_tquiz_by_tid?.section?.map((e, index) => {
                            // 
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e?.section_name}
                                        style={{
                                            backgroundColor: this.state.Qid == index ? "#6257CD" : '',
                                            color: this.state.Qid == index ? "white" : ''
                                        }}
                                        variant={this.props.admin.all_tquiz_by_tid.section[index] == undefined ? "outlined" : "filled"}
                                        onClick={() => {
                                            this.setState({ Qid: index, })
                                            // this.props.subject_id(e._id);
                                        }}
                                    // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 

                                    // onDelete={() => this.handleDeleteSub(Vid, index)}


                                    />


                                </div>


                            );
                        })}


                    </div>

                    <div style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginTop: "1%", alignItems: "center", width: "70%" }}>

                        <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                            <Chip label={this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.section_time}
                            // style={{
                            //     backgroundColor: this.state.Qid == index ? "#6257CD" : '',
                            //     color: this.state.Qid == index ? "white" : ''
                            // }}
                            // variant={this.state.test_content.test[index] == undefined ? "outlined" : "filled"}
                            // onClick={() => {
                            //     this.setState({ Qid: index, })
                            //     // this.props.subject_id(e._id);
                            // }}
                            // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 

                            // onDelete={() => this.handleDeleteSub(Vid, index)}


                            />


                        </div>


                    </div>

                    <div style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginTop: "1%", alignItems: "center", width: "70%" }}>

                        {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject ?? [])?.map((e1, index1) => {
                            // 
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e1?.subject_name}
                                        style={{
                                            backgroundColor: this.state.Vid == index1 ? "#6257CD" : '',
                                            color: this.state.Vid == index1 ? "white" : ''
                                        }}
                                        // variant={this.props.admin.all_quiz_by_id?.section?.[Qid]?.subject[index1] == undefined ? "outlined" : "filled"}
                                        variant={this.props.admin.all_tquiz_by_tid.section[Qid].subject[index1].hasOwnProperty('language') ? "filled" : "outlined"}

                                        onClick={() => {
                                            this.setState({ Vid: index1, })
                                            // this.props.subject_id(e._id);
                                        }}
                                    // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 

                                    // onDelete={() => this.handleDeleteSub(Vid, index)}


                                    />


                                </div>
                            );
                        })}


                    </div>
                    <div style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginTop: "1%", alignItems: "center", width: "70%", paddingBottom: "20px" }}>

                        {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language ?? [])?.map((e2, index2) => {
                            // 
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e2?.language}
                                        style={{
                                            backgroundColor: this.state.Lang == index2 ? "#6257CD" : '',
                                            color: this.state.Lang == index2 ? "white" : ''
                                        }}
                                        // variant={this.props.admin.all_quiz_by_id.section[Qid].subject[Vid].language[index2] == undefined ? "outlined" : "filled"}
                                        variant={this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language[index2].hasOwnProperty('questions') ? "filled" : "outlined"}

                                        onClick={() => {
                                            this.setState({ Lang: index2 })
                                            // this.props.subject_id(e._id);
                                        }}
                                    // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 

                                    // onDelete={() => this.handleDeleteSub(Vid, index)}


                                    />


                                </div>
                            );
                        })}


                    </div>


                    {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language?.[Lang]?.questions ?? [])?.map((c, index) => {
                    

                        return (
                            <Grid>
                                <Grid className='boxdesign' style={{ border: "1px solid #888", height: "100%", width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        {index + 1}
                                    </Typography>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", marginTop: "3%", fontWeight: "bold" }}>
                                            Question Marks:
                                        </Typography>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>

                                            {c.question_marks}
                                            {/* <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.question_text }}></div> */}
                                        </Typography>
                                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", marginTop: "3%", fontWeight: "bold" }}>
                                            Cut Marks:
                                        </Typography>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>

                                            {c.cut_marks}
                                            {/* <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.question_text }}></div> */}
                                        </Typography>

                                    </div>
                                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Question
                                    </Typography>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>

                                        {/* {c.question_text} */}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.qustion_title }}></div>
                                    </Typography>

                                    {c.question_img !== "" && <img src={c.question_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold " }}>
                                        Options

                                    </Typography>
                                    <div style={{ display: "flex" }}>
                                        {c.option1_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option1}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option1_desc }}></div>

                                    </div>
                                    {c.opation1_img !== "" && c.opation1_img !== null && <img src={c.opation1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option2_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option2}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option2_desc }}></div>
                                    </div>
                                    {c.opation2_img !== "" && c.opation2_img !== null && <img src={c.opation2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option3_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option3}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option3_desc }}></div>
                                    </div>
                                    {c.opation3_img !== "" && c.opation3_img !== null && <img src={c.opation3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option4_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option4}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option4_desc }}></div>
                                    </div>
                                    {c.opation4_img !== "" && c.opation4_img !== null && <img src={c.opation4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option5_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option5}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option5_desc }}></div>
                                    </div>
                                    {c.opation5_img !== "" && c.opation5_img !== null && <img src={c.opation5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}



                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Correct Option
                                    </Typography>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                        {c.answer}
                                    </Typography>
                                    <Typography dangerouslySetInnerHTML={{ __html: c.editorHtml }} style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                        {/* {c.correct_ans_desc} */}
                                    </Typography>
                                    {c.answer_img !== "" && c.answer_img !== null && <img src={c.answer_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Box className='saveandcnts'
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}

                >
                    <Link to={`/editTquiz/${this.props.admin.all_tquiz_by_tid?._id}`}>
                        <Button onClick={() => this.props.setRID(this.props.admin.all_tquiz_by_tid?._id)} style={{ backgroundColor: "#6257CD", color: "white ", textTransform: "capitalize", width: "100%" }}>Back</Button>
                    </Link>
                    <Link to='/testquiz'>
                        <Button style={{ backgroundColor: "#6257CD", color: "white ", textTransform: "capitalize", width: "100%" }}>Done</Button>
                    </Link>
                </Box>
            </Grid>

        )
    }
}
export default withStyles(styles)(addquiz);

