/* eslint-disable no-dupe-class-members */
import React, { Component } from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../admin/style/add.css"
import {
    Grid,
    Box,
    IconButton,
    Button,
    Typography,
    MenuItem

} from "@mui/material";
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase"
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import Quiz from '../images/quiz.svg';
import Loader from '../../../common/loader/containers/loader_cont';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorymodal: false,
            Exammodal: false,
            stagemodal: false,
            testmodal: false,
            testtypemodal: false,
            subjectmodal: false,
            quizmodal: false,
            package_hint: this.props.admin.testSeriesIds.hint,
            package_name: this.props.admin.testSeriesIds.name,
            new_exam_pattern: this.props.admin.testSeriesIds.new_exam,
            price: "",
            duration: "",
            ex: "",
            sta: "",
            cat: "",
            sub: "",
            min: "",
            tes: '',
            testyp: '',
            package_category: "",
            packagecategory_id: "",
            userinfo: {
                chapter_array: [],
                response: [],
            },
            countcat: 0,
            countex: 0,
            countsta: 0,
            countsub: 0,
            countmin: 0,
            counttest: 0,
            counttesttype: 0,
            counttestsub: 0,
            counttestquiz: 0,
            catcount: -1,
            excount: -1,
            stacount: -1,
            subcount: -1,
            minicount: -1,
            testcount: -1,
            testtypecount: -1,
            testsubcount: -1,
            testquizcount: -1,
            package_desc: this.props.admin.testSeriesIds.desc,
            student_special: false,
            testtypeindex: -1,
            category: this.props.admin.testSeriesIds.category,
            stage: this.props.admin.testSeriesIds.stage,
            exam: this.props.admin.testSeriesIds.exam,
            hints: this.props.admin.testSeriesIds.hints,
            language: this.props.admin.testSeriesIds.language,
            Lid: 0,
            Vid: 0,
            Pid: 0,
            Qid: 0,
            all: this.props.admin.testSeriesIds.test_content,


            value: "",

            quizzChecked: [],
            quizz_filtered: [],
            subjectChecked: [],
            subject_filtered: [],
            testtypeChecked: [],
            test_type_filtered: [],
            testChecked: [],
            test_filtered: [],

        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(content) {
        this.setState({ package_desc: content });
    }


    UnCheckedtest = (tests, itemToUncheck) => {
        this.setState((prevState) => ({
            testChecked: prevState.testChecked.filter((item) => item !== itemToUncheck),
            test_filtered: tests,
            // ... other state updates
        }));
    };
    UnCheckedtesttype = (test_types, itemToUncheck) => {
        this.setState((prevState) => ({
            testtypeChecked: prevState.testtypeChecked.filter((item) => item !== itemToUncheck),
            test_type_filtered: test_types,
            // ... other state updates
        }));
    };

    UnCheckedsubject = (all_subject, itemToUncheck) => {
        this.setState((prevState) => ({
            subjectChecked: prevState.subjectChecked.filter((item) => item !== itemToUncheck),
            subject_filtered: all_subject,
            // ... other state updates
        }));
    };

    UnCheckedquizz = (all_quiz, itemToUncheck) => {
        this.setState((prevState) => ({
            quizzChecked: prevState.quizzChecked.filter((item) => item !== itemToUncheck),
            quizz_filtered: all_quiz,
            // ... other state updates
        }));
    };




    Checkedexam(p, index, exams) {
        // 
        if (p.target.checked) {
            this.props.Checkedexam(index, true, 1, exams)
        }
        else {
            this.props.Checkedexam(index, false, 0, exams)


        }

    }
    Checkedstage(p, index, stage) {
        // 
        if (p.target.checked) {
            this.props.Checkedstage(index, true, 1, stage)
        }
        else {
            this.props.Checkedstage(index, false, 0, stage)


        }

    }
    Checkedtests(p, index, tests) {
        // 
        if (p.target.checked) {
            this.props.Checkedtests(index, true, 1, tests)
        }
        else {
            this.props.Checkedtests(index, false, 0, tests)


        }
    }

    Checkedtesttype(p, index, testtype) {
        // 
        if (p.target.checked) {
            this.props.Checkedtesttype(index, true, 1, testtype)
        }
        else {
            this.props.Checkedtesttype(index, false, 0, testtype)


        }
    }

    Checkedsubject(p, index, subjects) {
        // 
        if (p.target.checked) {
            this.props.Checkedsubject(index, true, 1, subjects)
        }
        else {
            this.props.Checkedsubject(index, false, 0, subjects)


        }
    }

    Checkedquiz(p, index, testquiz) {
        // 
        if (p.target.checked) {
            this.props.Checkedquiz(index, true, 1, testquiz)
        }
        else {
            this.props.Checkedquiz(index, false, 0, testquiz)


        }
    }

    componentDidMount() {
        // 
        // this.props.viewAllExams();
        this.props.viewAllCategory();
        this.props.viewAllStages();
        this.props.viewAllTests();
        this.props.viewAllTestsType();
        this.props.viewAllTSubject();
        this.props.viewAllTquiz()
        this.props.viewAllCategoryExam(this.state.category)
        console.log("this.props.admin.testSeriesIds", this.props.admin.testSeriesIds);

    }

    handleDelete = (data, id) => {

        let updated = data.filter(i => i._id !== id)

        this.setState(() => this.state.all.test = updated)

    }
    handleDeleteTT = (Lid, id) => {
        const newArrr = [...this.state.all.test]
        newArrr[Lid]?.testtype.splice(id, 1);

        this.setState({ all: { test: newArrr } })

    }
    handleDeleteSub = (Lid, Vid, id) => {

        this.setState(prevState => {

            const newState = { ...prevState };


            const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            newArr.splice(id, 1);


            newState.all.test[Lid].testtype[Vid].subject = newArr;

            return newState;
        });

    }

    handleDeleteQuiz = (Lid, Vid, Qid, id) => {

        this.setState(prevState => {
            const newState = { ...prevState };


            const newArr = [...newState.all.test[Lid].testtype[Vid].subject[Qid].quizz];


            newArr.splice(id, 1);


            newState.all.test[Lid].testtype[Vid].subject[Qid].quizz = newArr;


            return newState;
        });

    }

    render() {

        const modules = {
            toolbar: [
                // [{ header: "1" }, { header: "2" }, { font: [] }],

                // ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "bullet" }],
                // ["link", "image", "video"],
                // ["clean"],
            ],
        };

        const formats = [


            "bold",
            "italic",

            "list",
            "bullet",
            "link",
            "image",
            "video",
        ];
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        const { all, Lid, Vid, Pid, Qid } = this.state;

        return (
            <Grid>

                <Box className='categoryname'>

                    <center style={{ display: "flex", }}>
                        <Link to="/textseries" style={{ textDecoration: "none" }}
                            onClick={() => {
                                this.props.SelectTest("")
                            }}
                        >
                            <IconButton
                            // style={{ marginLeft: "18px" }}

                            >
                                <ArrowBackIcon style={{ color: "white" }} />
                            </IconButton>
                        </Link>
                        <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px", textTransform: "capitalize" }}>Test Series</Typography>


                    </center>
                </Box>

                {/*------------------------------ Name ----------------------------*/}
                <Grid className='mainsecation' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}>

                    <Box className='addmininamess'>
                        <center
                        >


                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Test Series Name



                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Test Series Name'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.package_name}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ package_name: e.target.value }) }}
                            />

                        </div>

                    </Grid>
                </Grid>
                {/*------------------------------ Hints ----------------------------*/}
                <Grid className='mainsecation' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}>

                    <Box className='addmininamess'>
                        <center
                        >


                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Test Series Hints



                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Test Series Hints'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.hints}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ hints: e.target.value }) }}
                            />

                        </div>

                    </Grid>
                </Grid>

                {/*------------------------------ hint ----------------------------*/}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center
                        >


                            <Typography style={{ fontWeight: "bold", width: "100%" }}> No of Test Series



                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Test Series Description'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.package_hint}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ package_hint: e.target.value }) }}
                            />

                        </div>

                    </Grid>

                </Grid>

                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}
                >
                    <Box className='addmininamess' style={{ marginLeft: "8%" }}>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>New Exam Pattern</Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Mark as New Exam Pattern'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.new_exam_pattern}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ new_exam_pattern: e.target.value }) }}
                            />

                        </div>

                    </Grid>
                </Grid>
                {/*------------------------------ Language ----------------------------*/}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Language
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Mark as New Exam Pattern'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.language}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ language: e.target.value }) }}
                            />

                        </div>

                    </Grid>

                </Grid>

                {/*------------------------------ desc ----------------------------*/}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Description
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            {/* <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Hint'
                                    multiline
                                    rows={1}
                                  
                                    value={this.state.package_hint}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ package_hint: e.target.value }) }}
                                /> */}
                            <ReactQuill className="FFFFOOOONNN" style={{ fontFamily: "Poppins" }}
                                theme="snow"
                                value={this.state.package_desc}
                                onChange={(e) => this.handleChange(e)}
                                // onChange={(e) => { this.setState({ package_hint: e.target.value }) }}

                                // onChange={this.handleChange}
                                modules={modules}
                                formats={formats}
                                placeholder="Write here"
                            />

                        </div>

                    </Grid>

                </Grid>

                {/*---------------------------------------------------Category-------------------------*/}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Category
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Hint'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.category}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => {
                                    this.setState({ category: e.target.value })
                                    this.props.viewAllCategoryExam(e.target.value)
                                }}
                            >{Array.isArray(admin.all_category) && admin.all_category?.map((e) => {
                                return (
                                    <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                )
                            })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/*---------------------------------------------------Category-------------------------*/}

                {/*---------------------------------------------------Exam-------------------------*/}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Exam
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Hint'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.exam}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ exam: e.target.value }) }}
                            >
                                {Array.isArray(admin?.all_exams) && admin?.all_exams?.map((e) => {
                                    return (
                                        <MenuItem value={e._id}>{e.exam_name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/*---------------------------------------------------Exam-------------------------*/}
                {/*---------------------------------------------------Exam-------------------------*/}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Stage
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Hint'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.stage}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ stage: e.target.value }) }}
                            >
                                {Array.isArray(admin?.all_stages) && admin?.all_stages?.map((e) => {
                                    return (
                                        <MenuItem value={e._id}>{e.stage_name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/*---------------------------------------------------Exam-------------------------*/}



                <Grid style={{ marginLeft: "8%" }}>
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.package_name !== "" && this.state.sta !== "" && */}
                            {/* {this.state.package_name !== "" && this.state.package_desc !== "" && this.state.package_hint && this.state.category !== "" && this.state.exam !== "" && this.state.stage !== "" && */}
                            <Button
                                variant="outlined"
                                style={{ border: "2px solid #6257cd" }}
                            // onClick={() => {
                            //     this.setState({
                            //         testmodal: true,
                            //     })
                            // }}
                            >

                                {/* <IconButton>
                                        <AddIcon style={{ color: '#6257CD' }} />
                                    </IconButton> */}
                                Add Tests</Button>
                            {/* } */}

                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center" }}>
                            {Array.isArray(all.test !== undefined && all.test) && all.test?.filter(el => el.quantity >= 0)?.map((e, index) => {
                                // 
                                return (
                                    <div style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                        <Chip label={e.test_name}
                                            style={{
                                                backgroundColor: this.state.Lid == index ? "#6257CD" : '',
                                                color: this.state.Lid == index ? "white" : ''
                                            }}
                                            variant={this.state.all.test[index].testtype == undefined ? "outlined" : "filled"}
                                            onClick={() => {
                                                this.setState({ Lid: index })
                                            }
                                            }
                                            onDelete={() => {
                                                this.handleDelete(all.test, e._id)
                                                this.UnCheckedtest(admin.tests, e.test_name)
                                            }}
                                        />

                                    </div>


                                );
                            })}
                            <AddIcon onClick={() => {
                                this.setState({
                                    testmodal: true,
                                })
                            }} />
                        </Grid>
                    </Box>

                    {/*------------------------------ Types ----------------------------*/}

                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.package_name !== "" && this.state.tes !== "" && */}
                            {/* // this.state.package_category !== "" && */}
                            <Button
                                style={{ border: "2px solid #6257cd" }}
                            // onClick={() => {
                            //     this.setState({
                            //         testtypemodal: true,
                            //     })
                            // }}
                            >

                                {/* <IconButton>
                                        <AddIcon style={{ color: '#6257CD' }} />
                                    </IconButton> */}
                                SELECT TEST TYPES</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "5%", marginRight: "12xx%", border: "2px solid #6257cd", alignItems: "center" }}>
                            {(all?.test?.[Lid]?.testtype == undefined ? [] : all?.test?.[Lid]?.testtype ?? [])?.map((e, index) => {
                                return (

                                    < div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                        <Chip label={e.test_type_name}
                                            style={{
                                                backgroundColor: this.state.Vid == index ? "#6257CD" : '',
                                                color: this.state.Vid == index ? "white" : ''
                                            }}
                                            // variant={this.state.all.test[index] == undefined ? "outlined" : "filled"}
                                            variant={all.test[Lid]?.testtype[index].hasOwnProperty('subject') ? "filled" : "outlined"}

                                            onClick={() => this.setState({ Vid: index })}
                                            onDelete={() => {
                                                this.handleDeleteTT(Lid, index)
                                                this.UnCheckedtesttype(admin.test_types, e.test_type_name)
                                            }}

                                        />


                                    </div>

                                );
                            })}
                            <AddIcon onClick={() => {
                                this.setState({
                                    testtypemodal: true,
                                })
                            }} />
                        </Grid>


                    </Box>

                    {/*------------------------------ Subject ----------------------------*/}

                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.package_name !== "" && this.state.testyp !== "" && */}
                            {/* // this.state.package_category !== "" && */}

                            <Button
                                variant="outlined"
                                style={{ border: "2px solid #6257cd" }}
                            // onClick={() => {
                            //     this.setState({
                            //         subjectmodal: true,
                            //     })
                            // }}
                            >
                                {/* 
                                <IconButton>
                                    <AddIcon style={{ color: '#6257CD' }} />
                                </IconButton> */}
                                Add Subject</Button>
                            {/* } */}


                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {(all?.test?.[Lid]?.testtype == undefined ? [] : all?.test?.[Lid]?.testtype?.[Vid]?.subject ?? [])?.map((e, index) => {
                                return (
                                    <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                        <Chip label={e.subject_name}
                                            style={{
                                                backgroundColor: this.state.Qid == index ? "#6257CD" : '',
                                                color: this.state.Qid == index ? "white" : ''
                                            }}
                                            variant={all.test[Lid]?.testtype[Vid]?.subject[index].hasOwnProperty('quizz') ? "filled" : "outlined"}


                                            onClick={() => this.setState({ Qid: index })}
                                            // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 
                                            onDelete={() => {
                                                this.handleDeleteSub(Lid, Vid, index)
                                                this.UnCheckedsubject(admin.all_subject, e.subject_name)
                                            }}


                                        />


                                    </div>
                                );
                            })}
                            <AddIcon onClick={() => {
                                this.setState({
                                    subjectmodal: true,
                                })
                            }} />
                        </Grid>




                    </Box>

                    {/*------------------------------ Quiz ----------------------------*/}

                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.package_name !== "" && this.state.sub !== "" &&
                                // this.state.package_category !== "" && */}

                            <Button
                                style={{ border: "2px solid #6257cd" }}
                            // onClick={() => {
                            //     this.setState({
                            //         quizmodal: true,
                            //     })
                            // }}
                            >


                                SELECT QUIZ</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {(all?.test?.[Lid]?.testtype?.[Vid]?.subject == undefined ? [] : all?.test?.[Lid]?.testtype?.[Vid]?.subject?.[Qid]?.quizz ?? []).filter(el => el.quantity >= 0)?.map((e, index) => {
                                return (
                                    <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", padding: 6 }}>

                                        <Chip

                                            label={e.quiz_desc}
                                            // variant={this.state.all.test[index]==undefined?"outlined" : "filled"}

                                            onDelete={() => {
                                                this.handleDeleteQuiz(Lid, Vid, Qid, index)
                                                this.UnCheckedquizz(admin.all_quiz, e.quiz_desc)
                                            }}

                                        />

                                    </div>
                                );
                            })}
                            <AddIcon onClick={() => {
                                this.setState({
                                    quizmodal: true,
                                })
                            }} />
                        </Grid>


                    </Box>
                </Grid >

                {/*----------------------------------------------------------------------------------*/}
                {/* Exam */}
                {/* <Dialog

                    open={this.state.Exammodal}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchexam(admin.all_exam, e)}
                                />
                            </Search>
                        </Grid>


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {admin.exam_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.exam_name}</Typography>

                                        <Checkbox
                                            value={e.exam_name}

                                            onClick={(p) => { this.Checkedexam(p, index, admin.all_exam) }}

                                        />
                                    </div>
                                );
                            })}
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ Exammodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'
                                onClick={() => {
                                    this.setState({ Exammodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog> */}

                {/* Stage */}
                <Dialog

                    open={this.state.stagemodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ stagemodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Stage "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchstage(admin.all_stages, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin?.stage_filtered) && admin?.stage_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.stage_name}</Typography>

                                        <Checkbox
                                            value={e.stage_name}
                                            checked={false}
                                            onClick={(p) => { this.Checkedstage(p, index, admin.all_stages) }}

                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ stagemodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.props.setlesson(this.state.userinfo);
                                    this.setState({ stagemodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>


                </Dialog>

                {/* Tests */}
                <Dialog
                    open={this.state.testmodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ testmodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Test"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchtests(admin.tests, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.tests_filtered) && admin.tests_filtered?.map((e, index) => {
                                // 
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.test_name}</Typography>

                                        <Checkbox
                                            value={e.test_name}
                                            checked={all?.test?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                // this.props.viewAllTests();
                                                const currentLesson = all;

                                                if (!currentLesson.hasOwnProperty('test')) {
                                                    currentLesson.test = [];
                                                }

                                                const videoAlreadyExists = currentLesson.test && currentLesson.test.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    // Check the item
                                                    currentLesson.test = [...(currentLesson.test || []), e];

                                                    // Update the checked state
                                                    this.setState((prevState) => ({
                                                        testChecked: [...prevState.testChecked, e.test_name],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.test = currentLesson.test.filter(video => video._id !== e._id);

                                                    // Update the checked state
                                                    this.setState((prevState) => ({
                                                        testChecked: prevState.testChecked.filter(type => type !== e.test_name),
                                                    }));
                                                }
                                            }}
                                        />

                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ testmodal: false });
                                    this.props.viewAllTests();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ testmodal: false });
                                    this.props.viewAllTests();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                {/* TestType */}
                <Dialog
                    open={this.state.testtypemodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ testtypemodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Test Type"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchtesttype(admin.testtypes, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.testtype_filtered) && admin.testtype_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.test_type_name}</Typography>

                                        <Checkbox
                                            value={e.test_type_name}
                                            checked={all?.test?.[Lid]?.testtype?.some(item2 => e?._id === item2?._id)}

                                            onClick={() => {
                                                // this.props.viewAllTests();
                                                const currentLesson = all?.test?.[Lid];

                                                if (!currentLesson.hasOwnProperty('testtype')) {
                                                    currentLesson.testtype = [];
                                                }

                                                const videoAlreadyExists = currentLesson.testtype && currentLesson.testtype.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    // Check the item
                                                    currentLesson.testtype = [...(currentLesson.testtype || []), e];

                                                    // Update the checked state
                                                    this.setState((prevState) => ({
                                                        testtypeChecked: [...prevState.testtypeChecked, e.test_type_name],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.testtype = currentLesson.testtype.filter(video => video._id !== e._id);

                                                    // Update the checked state
                                                    this.setState((prevState) => ({
                                                        testtypeChecked: prevState.testtypeChecked.filter(type => type !== e.test_type_name),
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ testtypemodal: false });
                                    this.props.viewAllTestsType();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                onClick={() => {
                                    // this.setState({ sub: "1" })
                                    this.setState({ testtypemodal: false });
                                    this.props.viewAllTestsType();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                {/* Test Subject */}
                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ subjectmodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Subject"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchsubject(admin.all_subject, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.subject_filtered) && admin.subject_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        <Checkbox
                                            value={e.subject_name}
                                            checked={all?.test?.[Lid]?.testtype?.[Vid]?.subject?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                // this.props.viewAllTSubject();
                                                const currentLesson = all?.test?.[Lid]?.testtype?.[Vid];

                                                if (!currentLesson?.hasOwnProperty('subject')) {
                                                    currentLesson.subject = [];
                                                }

                                                const videoAlreadyExists = currentLesson.subject && currentLesson.subject.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.subject = [...(currentLesson.subject || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        subjectChecked: [...prevState.subjectChecked, e.subject_name],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.subject = currentLesson.subject?.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        subjectChecked: prevState.subjectChecked?.filter(desc => desc !== e.subject_name),
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                    this.props.viewAllTSubject();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                    this.props.viewAllTSubject();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                {/* Test Quiz */}
                <Dialog

                    open={this.state.quizmodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ quizmodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Quiz"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchquiz(admin.all_quiz, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.quiz_filtered) && admin.quiz_filtered?.map((e, index) => {
                                return (

                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <IconButton

                                            style={{ marginLeft: "-0px" }}
                                        >
                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                            <img src={Quiz} height={24} width={24} />
                                        </IconButton>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.quiz_desc}</Typography>

                                        <Checkbox
                                            value={e.quiz_desc}
                                            checked={all?.test?.[Lid]?.testtype?.[Vid]?.subject?.[Qid]?.quizz?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                // this.props.viewAllTquiz();
                                                const currentLesson = all?.test?.[Lid]?.testtype?.[Vid]?.subject?.[Qid];

                                                if (!currentLesson?.hasOwnProperty('quizz')) {
                                                    currentLesson.quizz = [];
                                                }

                                                const videoAlreadyExists = currentLesson.quizz && currentLesson.quizz.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.quizz = [...(currentLesson.quizz || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        quizzChecked: [...prevState.quizzChecked, e.quiz_desc],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.quizz = currentLesson.quizz?.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        quizzChecked: prevState.quizzChecked?.filter(desc => desc !== e.quiz_desc),
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ quizmodal: false });
                                    this.props.viewAllTquiz();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ quizmodal: false });
                                    this.props.viewAllTquiz();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                <center>
                    <Link to="/textseries">
                        <Button
                            className='btns'
                            onClick={() => {
                                this.props.UpdatesTestSeries(this.props.admin.testSeriesIds._id, this.state.package_name, this.state.hints, this.state.package_desc, this.state.package_hint, this.state.category, this.state.exam, this.state.stage, this.state.all, this.state.new_exam_pattern, this.state.language)
                                // 
                                this.setState({ package_name: "", package_desc: "", package_hint: "", category: "", exam: "", stage: "", all: "", language: "" })
                            }}
                            style={{ marginBottom: "30px", marginTop: "10px", color: "white", width: "20px" }}

                        >SUBMIT</Button>
                    </Link>
                </center>

                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
                <Loader />
            </Grid >
        )
    }
}