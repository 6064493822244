import React, { Component } from "react";
import { connect } from "react-redux";
import Chapter from "../component/chapter";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createChapter,
    updateChapter,
    deleteChapter,
    viewAllChapter,
    viewAllLanguage
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Chapter {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createChapter: (chapter_name) => {
      dispatch(createChapter(chapter_name))
    },
    updateChapter: (chapter_id,chapter_name) => {
      dispatch(updateChapter(chapter_id,chapter_name))
    },
    deleteChapter: (chapter_id) => {
      dispatch(deleteChapter(chapter_id))
    },
    viewAllChapter: () => {
      dispatch(viewAllChapter())
    },
    viewAllLanguage: () => {
      dispatch(viewAllLanguage())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);